(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name orders.controller:TermDepositCtrlOther
     *
     * @description
     *
     */
    angular
        .module('orders')
        .controller('TermDepositCtrlOther', TermDepositCtrlOther);

    function TermDepositCtrlOther($state, $rootScope, AccountsService, $log, DepositOrderTypesService, CurrencyService, $scope, $timeout, DepositOrdersService, HelperService,
        $translate, $filter, ConfigEndpointService, $window, ValidationService, UserSettingsService, AlertService, GoogleTagManagerHelperService, $location, NotificationService) {
        var vm = this;
        vm.ctrlName = 'TermDepositCtrlOther';
        vm.userSettings = {};
        vm.configData = {};
        vm.allRequests = false;
        vm.contryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
        vm.getConfigData = function () {
            ConfigEndpointService.getDefaultData()
                .then(function (response) {

                    vm.configData = response;

                })
                .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.additionalLinks = {
          link1: $rootScope.APP_GLOBAL_SETTINGS.defaultData.Interests_rates,
          link2: $rootScope.APP_GLOBAL_SETTINGS.defaultData.Fees_info,
          link3: $rootScope.APP_GLOBAL_SETTINGS.defaultData.GeneralTermsAndConditions,
          link4: $rootScope.APP_GLOBAL_SETTINGS.defaultData.Deposit_generalTerms,
          link5: $rootScope.APP_GLOBAL_SETTINGS.defaultData.Deposit_basicInfo,
        };

        vm.openConditionsLink = function (url) {
            GoogleTagManagerHelperService.pushEvent('NewTermDeposit', 'ExternalLink', url);
            $window.open(url, "_blank");
        };

        vm.manageDocumentDownload = function (documentType, term) {
          var minAmount = getMinAmount();
          if (documentType === 'InfoForm') {
            DepositOrdersService.depositOrders_downloadByFilter(term, documentType)
                .then(function (response) { })
                .catch(function (error) {
                NotificationService.showMessage(error, 'error');
                });
          } else if (term) {
            DepositOrdersService.depositOrders_downloadByFilter(term, documentType)
              .then(function (response) { })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          } else {
            if (vm.tempTermDepositObj.amount >= minAmount) {
              DepositOrdersService.depositOrders_downloadByFilter(createInsertObject(), documentType)
                .then(function (response) { })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
            } else {
                $translate('termDeposit.minAmountLabel').then(function (translation) {
                    var error = translation + ' ' + $filter('number')(minAmount, 2) + ' ' + vm.tempTermDepositObj.fromAccountSelect.currencySymbol + "!";
                    NotificationService.showTranslatedMessage('error', error);
                });
            }
          }

        };

        vm.steps = {
            step1: true,
            step2: false,
            step3: false
        };

        // ---------------------------------- start of timeline settings
        vm.recompile = {
            timeline: true
        };
        vm.timelineListGrouped = {};

        vm.loadMoreTimelineItemsGrouped = function () {
            vm.getTimelineItemsGrouped(false);
        };

        var timeRange = HelperService.getDateRange('thisMonth');
        vm.selectedAccountId = '';
        vm.depositsFilter = {
            page: 0,
            orderBy: 'signDate DESC', //signDate
            pageSize: 15,
            signDateGTE: null,
            signDateLTE: null,
            accountId: null,
            userId: null,
        };

        vm.userSettingsFilter = {
            page: 0,
            pageSize: 1000
        };

        getUserSettingsPage();
        function getUserSettingsPage () {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr') {
              vm.userSettingsFilter.includeList = 'UserDetailsList'
            }
            return UserSettingsService.getPage(vm.userSettingsFilter)
                .then(function (response) {
                    vm.userSettings = response.result[0];
                    init();
                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
        };
        vm.tempTermDepositObj = {
            fromAccountSelect: {
                currencySymbol: "EUR"
            },
            interestRate: 0,
            stimulativeInterestRate: 0,
            totalInterestRate: 0,
            amount: '',
            currency: "EUR",


        };


        vm.periodList = [
            {
                item: {
                    key: 'today',
                    description: 'core.period.today'
                }
      }
      , {
                item: {
                    key: 'yesterday',
                    description: 'core.period.yesterday'
                }
      }
      , {
                item: {
                    key: 'thisWeek',
                    description: 'core.period.thisWeek'
                }
      }
      , {
                item: {
                    key: 'thisMonth',
                    description: 'core.period.thisMonth'
                }
      }
      , {
                item: {
                    key: 'thisYear',
                    description: 'core.period.thisYear'
                }
      }
      , {
                item: {
                    key: 'all',
                    description: 'core.period.all'
                }
      }
    ];

        vm.selectedPeriod = vm.periodList[5];

        vm.paymentsPeriodFilter = function (item) {
            vm.selectedPeriod = item;
            var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
            vm.depositsFilter.signDateGTE = timeRange.valueDateGTE;
            vm.depositsFilter.signDateLTE = timeRange.valueDateLTE;
            vm.getTimelineItemsGrouped(true);
            GoogleTagManagerHelperService.pushEvent('TermDeposit', 'TabPeriodFilter', $translate.instant(vm.selectedPeriod.item.description));
        }

        vm.togglePaymentDetails = function (payment) {
            payment.selected = !payment.selected;
        };
        vm.getTimelineItemsGrouped = function (reset) {

            if (reset) {
                vm.depositsFilter.page = 0;
            } else {
                vm.depositsFilter.page += 1;
            }


            if (vm.allRequests) {
                vm.depositsFilter.accountId = null;
            } else {
                if (vm.tempTermDepositObj.fromAccountSelect)
                    vm.depositsFilter.accountId = vm.tempTermDepositObj.fromAccountSelect.accountId;

            }

            //vm.depositsFilter.userId = vm.userSettings.id;

            DepositOrdersService.getPage(vm.depositsFilter)
                .then(function (response) {
                    translatePaymentProperties(response.result);
                    vm.timelineListGrouped = HelperService.mergeRowsForTimeline({
                        existingData: vm.timelineListGrouped,
                        newData: response,
                        groupFunction: function (item) {
                            var date = new Date(item.signDate);
                            // If we want to group only by date component, without hours
                            date.setHours(0, 0, 0, 0);
                            return date;
                        },
                        virtualRowFunction: function (e) {
                            var month = new Date(e).getMonth() + 1;
                            var day = new Date(e).getDate();
                            var virtualRow = {
                                isVirtual: true,
                                valueDate: new Date(e),
                                month: month,
                                day: day
                            };
                            $log.debug("virtualRow", e, virtualRow);
                            return virtualRow;
                        },
                        reset: reset,
                        reverse: false
                    });
                    
                    $log.debug("groupedEDespositOrdersTimeline", vm.timelineListGrouped);
                    //alert(2);
                    vm.recompile.timeline = true;
                }).catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                });
        };
        // ---------------------------------------------------  end of timeline settings

        vm.goBackToOrders = function () {
            $state.go('orders');
        };

        vm.accountsFrom = {};

        function init() {
            // getUserSettingsPage();
            vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
            CurrencyService.getCurrencyPage({
                pageSize: 5000
            }).then(function (response) {
                vm.currenciesList = response.result;

                vm.getTermDepositAccountsFrom();
                /*vm.getTimelineItemsGrouped(true);*/

            }).catch(function (error) {
              NotificationService.showMessage(error, 'error');
            });
            vm.getConfigData();

        };

        vm.depositOrderTypesFilter = {
            pageSize: 1000,
            includeList: ["depositOrderTypeCalculationList"]
        };

        vm.depositOrderTypesList = [];
        vm.maturityInMonthsList = [];

        vm.currenciesList = [];


        var getInterestRateByAmount = function (amount) {

            _.forEach(vm.tempTermDepositObj.maturityInMonths.depositOrderTypeCalculationList, function (calculationObject) {

                var fromAmount = 0;
                var toAmount = 999999999999999;
                if (vm.tempTermDepositObj.fromAccountSelect.currencyCode === calculationObject.currencyCode) {
                    if (calculationObject.fromAmount) {
                        fromAmount = calculationObject.fromAmount;
                    }
                    if (calculationObject.toAmount) {
                        toAmount = calculationObject.toAmount;
                    }

                    if (amount >= fromAmount && amount <= toAmount) {
                        vm.tempTermDepositObj.interestRate = calculationObject.interestRate;
                        vm.tempTermDepositObj.stimulativeInterestRate = vm.tempTermDepositObj.maturityInMonths.stimulInterestRate;
                      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr' && vm.userSettings.userDetailsList.length) {
                          var userDetailStimulInterestRate = _.filter(vm.userSettings.userDetailsList, function(item) {
                            return item.serviceId.toLowerCase() == "hyponet" && item.deleted.toLowerCase() == "n" && item.depositJobPurpose == vm.tempTermDepositObj.maturityInMonths.id;
                          });
                          if (userDetailStimulInterestRate.length && (userDetailStimulInterestRate[0].stimulInterestRate > vm.tempTermDepositObj.stimulativeInterestRate)) {
                            vm.tempTermDepositObj.stimulativeInterestRate = angular.copy(vm.userSettings.userDetailsList[0].stimulInterestRate);
                          }
                      }
                        vm.tempTermDepositObj.totalInterestRate = vm.tempTermDepositObj.stimulativeInterestRate + vm.tempTermDepositObj.interestRate;
                        vm.tempTermDepositObj.totalInterestRate = vm.tempTermDepositObj.totalInterestRate.toFixed(2);
                    }
                }
            });

        };

        var getTermDepositPayoutTypeList = function (periodObject) {
            var allowedPayoutTypesList = periodObject.payoutType.split(',');
            _.forEach(allowedPayoutTypesList, function (allowedPayoutTypes) {
                if (allowedPayoutTypes !== '3') {
                    var termDepositPayoutTypeCodebookIndex = _.findIndex(vm.termDepositPayoutTypeCodebook, function (o) {
                        return o.payoutType == allowedPayoutTypes;
                    });
                    if (allowedPayoutTypes == '2') {
                        vm.termDepositPayoutTypeList.unshift(vm.termDepositPayoutTypeCodebook[termDepositPayoutTypeCodebookIndex]);
                    } else {
                        vm.termDepositPayoutTypeList.push(vm.termDepositPayoutTypeCodebook[termDepositPayoutTypeCodebookIndex]);
                    }

                }
            });

            $log.debug("termDepositPayoutTypeList", vm.termDepositPayoutTypeList);
            vm.selectedTermDepositPayoutType = vm.termDepositPayoutTypeList[0];

            getInterestRateByAmount(vm.tempTermDepositObj.amount);

        };
        var getCurrencyCodeBySymbol = function (currencySymbol) {
            var currencyIndex = _.findIndex(vm.currenciesList, function (o) {
                return o.symbol == currencySymbol;
            });
            var currencyCode = vm.currenciesList[currencyIndex].code;
            return currencyCode;
        };

        var getPeriodsFromDepositOrderListByAccountCurrency = function (accountCurrencySymbol) {

            var currencyCode = getCurrencyCodeBySymbol(accountCurrencySymbol);

            _.forEach(vm.depositOrderTypesList, function (value) {
                var currenciesArray = value.currencyCode.split(',');
                _.forEach(currenciesArray, function (currency) {
                    if (currency === currencyCode) {
                        vm.maturityInMonthsList.push(value);
                    }

                });
            });

            $log.debug("maturityInMonthsList", vm.maturityInMonthsList);
            vm.tempTermDepositObj.maturityInMonths = vm.maturityInMonthsList[0];
            getTermDepositPayoutTypeList(vm.tempTermDepositObj.maturityInMonths);
        };

        vm.getDepositOrderTypes = function () {
            DepositOrderTypesService.getAll(vm.depositOrderTypesFilter)
                .then(function (response) {

                    _.forEach(response.result, function (value) {
                        if (value.typeCode === 2) {
                            vm.depositOrderTypesList.push(value);
                        }
                    });

                    getPeriodsFromDepositOrderListByAccountCurrency(vm.tempTermDepositObj.fromAccountSelect.currencySymbol);

                }).catch(function (error) {
              NotificationService.showMessage(error, 'error');
                });
        };

        vm.getTermDepositAccountsFrom = function () {
            AccountsService.getDepositOrderAccounts({
                    pageSize: 1000
                })
                .then(function (response) {
                    $log.debug("getTermDepositAccountsFrom", response);
                    if (_.isArray(response.result) && response.result.length > 0) {
                        vm.accountsFrom = response.result;
                        _.forEach(vm.accountsFrom, function (item) {
                            if (item.accountDescription) {
                                item.optionValue = item.accountDescription + ' - ' + item.iban;
                            }
                        });
                        vm.tempTermDepositObj.fromAccountSelect = response.result[0];
                        vm.tempTermDepositObj.currency = vm.tempTermDepositObj.fromAccountSelect.currencySymbol;


                        vm.getDepositOrderTypes();
                        vm.getTimelineItemsGrouped(true);
                    }


                })
                .catch(function (error) {
                  NotificationService.showMessage(error, 'error');
                });
        };

        vm.showOrderDetails = function (term) {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation != "sl") {
                term.selected = !term.selected;
                return;
            }
            if (!term.selected) {
                DepositOrdersService.getById(term.id)
                    .then(function (response) {
                        angular.extend(term, response);
                        term.translatedStatus = "core.termDepositStatus." + term.status.toLowerCase();
                        term.payoutTypeTranslate = 'core.termDepositPayoutType.' + term.payoutType;
                        term.selected = !term.selected;
                    }, function (error) {
                        console.log(error);
                    });
            } else {
                term.selected = false;
            }
        };

        vm.fromAccountDropdown = false;
        vm.toggleFromAccount = function () {
            vm.fromAccountDropdown = !vm.fromAccountDropdown;
        };

        vm.setAccountFrom = function (item) {
            vm.tempTermDepositObj.fromAccountSelect = item;
            vm.depositsFilter.accountId = item.accountId;
            vm.getTimelineItemsGrouped(true);
            vm.toggleFromAccount();
        };
        vm.loadAllAccountsTimeline = function (value) {
          vm.allRequests = !value;
          vm.getTimelineItemsGrouped(true);
        };
        vm.isPayoutTypeSelectionCollapsed = true;

        vm.selectPayoutType = function (item) {
            vm.selectedTermDepositPayoutType = item;
            vm.isPayoutTypeSelectionCollapsed = true;
        };

        vm.termDepositPayoutTypeList = [];
        vm.termDepositPayoutTypeCodebook = [
            {
                payoutType: 2,
                description: 'core.termDepositPayoutType.2',
                explanation: 'core.termDepositPayoutType.2e'

      }, {
                payoutType: 3,
                description: 'core.termDepositPayoutType.3',
                explanation: 'core.termDepositPayoutType.3e'

      }, {
                payoutType: 16,
                description: 'core.termDepositPayoutType.16',
                explanation: 'core.termDepositPayoutType.16e'
      }, {
                payoutType: 17,
                description: 'core.termDepositPayoutType.17',
                explanation: 'core.termDepositPayoutType.17e'

      }, {
                payoutType: 18,
                description: 'core.termDepositPayoutType.18',
                explanation: 'core.termDepositPayoutType.18e'

      }, {
                payoutType: 19,
                description: 'core.termDepositPayoutType.19',
                explanation: 'core.termDepositPayoutType.19e'

      }
    ];


        vm.getToday = function () {
            return new Date();
        };


        /*CHANGE EVENTS*/
        vm.changeCountFromAccountSelect = 0;
        $scope.$watch('termDeposit.tempTermDepositObj.fromAccountSelect', function (newValue) {
            vm.changeCountFromAccountSelect = vm.changeCountFromAccountSelect + 1
            $log.debug("changeCountFromAccountSelect", vm.changeCountFromAccountSelect);
            if (vm.changeCountFromAccountSelect >= 3) {
                vm.termDepositPayoutTypeList = [];
                vm.maturityInMonthsList = [];
                getPeriodsFromDepositOrderListByAccountCurrency(newValue.currencySymbol);
            }
        }, true);


        vm.changeCountmaturityInMonths = 0;
        $scope.$watch('termDeposit.tempTermDepositObj.maturityInMonths', function (newValue) {
          // vm.tempTermDepositObj.maturityInMonths = newValue;
          console.log("NEW VALUE" + newValue);
            vm.changeCountmaturityInMonths = vm.changeCountmaturityInMonths + 1
            $log.debug("changeCountmaturityInMonths", vm.changeCountmaturityInMonths);

            if (vm.changeCountmaturityInMonths >= 3) {
                vm.termDepositPayoutTypeList = [];
                getTermDepositPayoutTypeList(newValue);
            }
        }, true);

      // vm.maturityInMonthsChanged = function (newValue) {
      //   vm.tempTermDepositObj.maturityInMonths = newValue;
      //   vm.changeCountmaturityInMonths = vm.changeCountmaturityInMonths + 1
      //   $log.debug("changeCountmaturityInMonths", vm.changeCountmaturityInMonths);
      //
      //   if (vm.changeCountmaturityInMonths >= 3) {
      //     vm.termDepositPayoutTypeList = [];
      //     getTermDepositPayoutTypeList(newValue);
      //   }
      // };

        /*
        vm.changeCountAmount = 0;
        $scope.$watch('termDeposit.tempTermDepositObj.amount', function (newValue) {
            vm.changeCountAmount = vm.changeCountAmount + 1
            $log.debug("changeCountAmount", vm.changeCountAmount);

            if (newValue && newValue > 0) {
                getInterestRateByAmount(newValue);
            }
        }, true);
        */

        vm.amountChanged = function (newValue) {
            if (newValue && newValue > 0) {
                getInterestRateByAmount(newValue);
            }
        };

        var createInsertObject = function () {
            var insertObject = {
                "accountId": vm.tempTermDepositObj.fromAccountSelect.accountId,
                "currencyCode": getCurrencyCodeBySymbol(vm.tempTermDepositObj.fromAccountSelect.currencySymbol),
                "payoutType": vm.selectedTermDepositPayoutType.payoutType,
                "amount": vm.tempTermDepositObj.amount,
                "jobPurpose": vm.tempTermDepositObj.maturityInMonths.id,
                "termPeriod": vm.tempTermDepositObj.maturityInMonths.periodFrom,
                "isRenewal": "N",
                "depositType": "2",
                "depositNrOfExtensions": 0
            };


            return insertObject;

        };

        var getCurrencyCodeBySymbol = function (currencySymbol) {
            var currencyIndex = _.findIndex(vm.currenciesList, function (o) {
                return o.symbol == currencySymbol;
            });
            var currencyCode = vm.currenciesList[currencyIndex].code;
            return currencyCode;
        };

        var getMinAmount = function (amount) {
            var result = '0';
            var minAmount = vm.tempTermDepositObj.maturityInMonths.minAmount;
            var minAmountArray = minAmount.split(",");
            _.forEach(minAmountArray, function (value) {
                var currAndAmountArray = value.split("=");
                if (currAndAmountArray[0] === vm.tempTermDepositObj.fromAccountSelect.currencySymbol) {
                    if (currAndAmountArray[1]) {
                        result = currAndAmountArray[1];
                    } else {
                        result = '50000';
                    }

                } else {
                    result = '50000';
                }
            });
            result = result / 100;
            return parseFloat(result);
        };

        vm.isCondition1Checked = false;
        vm.isCondition2Checked = false;

        vm.insertDepositOrderResponse = {};
        vm.confirmAction = function () {
            var minAmount = getMinAmount();

            if (vm.tempTermDepositObj.amount >= minAmount) {
                if (vm.isTermDepositEdit) {
                    DepositOrdersService.updateDepositOrderById(vm.insertDepositOrderResponse.id, createInsertObject()).then(function (response) {
                            vm.steps = {
                                step1: false,
                                step2: true,
                                step3: false
                            };
                            vm.insertDepositOrderResponse = response;
                            HelperService.initializeMTokenSignature.call(vm, $scope, vm.signConfirmAction);
                            HelperService.loadChallengeResponse(response, vm);
                            vm.isTermDepositEdit = false;
                        })
                        .catch(function (error) {
                          NotificationService.showMessage(error, 'error');
                        });
                } else {
                    DepositOrdersService.insertDepositOrder(createInsertObject()).then(function (response) {
                            var gtmObject = HelperService.processUrlForGTM('payment');
                            GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
                            HelperService.initializeMTokenSignature.call(vm, $scope, vm.signConfirmAction);
                            HelperService.loadChallengeResponse(response, vm);
                            vm.steps = {
                                step1: false,
                                step2: true,
                                step3: false
                            };
                            vm.insertDepositOrderResponse = response;

                        })
                        .catch(function (error) {

                            NotificationService.showMessage(error, 'error');
                        });
                }
            } else {
                $translate('termDeposit.minAmountLabel').then(function (translation) {
                    var errorMessage = translation + ' ' + $filter('number')(minAmount, 2) + ' ' + vm.tempTermDepositObj.fromAccountSelect.currencySymbol + "!";
                    NotificationService.showTranslatedMessage('error', errorMessage);
                    /*          $.growl.catch({
                                message: translation + ' ' + $filter('number')(minAmount, 2) + ' ' + vm.tempTermDepositObj.fromAccountSelect.currencySymbol + "!"
                              });*/
                });
            }

        };

        vm.isTermDepositEdit = false;

        vm.editAction = function () {
            vm.isCondition1Checked = false;
            vm.isCondition2Checked = false;
            vm.isTermDepositEdit = true;
            vm.steps = {
                step1: true,
                step2: false,
                step3: false
            };
        };

        vm.cancelAction = function () {
            AlertService.confirmationAlert()
                .then(function (result) {
                    DepositOrdersService.deleteDepositOrderById(vm.insertDepositOrderResponse.id)
                        .then(function (response) {
                            AlertService.deletedPaymentAlert({
                                    paymentName: vm.insertDepositOrderResponse.id,
                                    type: 'termDeposit'
                                })
                                .then(function () {
                                    $state.reload();
                                });
                        })
                        .catch(function (error) {
                          NotificationService.showMessage(error, 'error');
                        });
                })
                .catch(function () {
                  NotificationService.showMessage(error, 'error');
                });


        };

        vm.signAction = function () {
            vm.steps = {
                step1: false,
                step2: false,
                step3: true
            };
        };

        vm.signConfirmAction = function () {
            DepositOrdersService.signDepositOrderById(vm.insertDepositOrderResponse.id, vm.challengeResponse, vm.challengeResponse2)
                .then(function (response) {
            vm.signInProgress = false;
                GoogleTagManagerHelperService.pushEvent('NewTermDeposit', 'NewTermDepositRequestSubmited', 'New term deposit request submited successfully');
                    AlertService.infoAlert({
                            text: 'alert.successSign'
                        })
                        .then(function () {
                            $state.reload();
                        });
                })
                .catch(function (error) {
            vm.signInProgress = false;
                    NotificationService.showMessage(error, 'error');
                });
        };

        vm.getContractDraft = function () {
            var minAmount = getMinAmount();
            if (vm.tempTermDepositObj.amount >= minAmount) {
                DepositOrdersService.getContractOverviewLink(createInsertObject());
            } else {
                $translate('termDeposit.minAmountLabel').then(function (translation) {
                    var error = translation + ' ' + $filter('number')(minAmount, 2) + ' ' + vm.tempTermDepositObj.fromAccountSelect.currencySymbol + "!";
                    NotificationService.showTranslatedMessage('error', error);
                    /*          $.growl.catch({
                                message: translation + ' ' + $filter('number')(minAmount, 2) + ' ' + vm.tempTermDepositObj.fromAccountSelect.currencySymbol + "!"
                              });*/
                });
            }
        };

        vm.challengeResponse = '';


        //add translation
        var translatePaymentProperties = function (orders) {
            _.forEach(orders, function (item) {
                item.translatedStatus = "core.termDepositStatus." + item.status.toLowerCase();
                item.payoutTypeTranslate = 'core.termDepositPayoutType.' + item.payoutType;
            });
        };


        // vm.init();
    }
}());

/* eslint-disable comma-dangle */
/* eslint-disable quotes */
/* eslint-disable wrap-iife */

(function () {
  "use strict";

  angular.module("localization").config(config);

  function config($translateProvider) {
    var data = {
      settings: {
        title: "Settings",
        "requiredNewPassword": "Please enter New Password",
        "requiredOldPassword": "You need to enter old password!",
        "againPassError":"Please confirm new password",
        tabAccount: "Account",
        tabAccountHR: "User profile",
        tabNotifications: "Notifications",
        tabSecurity: "Security",
        contactInfo: "Your contact information",
        contactInfoHr: "Contact information",
        contactInfoBtn: "Edit",
        successfulDataUppdate: 'Uspješno ste autorizirali zahtjev za promjenom kontakt podataka',
        contactInfoConfirmBtn: "Confirm",
        contactInfoCancelBtn: "Cancel",
        contactInfoName: "Name and surname",
        contactInfoAddress: "Address",
        contactInfoPhone: "Phone",
        contactInfoPhoneHr: "Telephone No.",
        blocades: "Blocades",
        blockLogin: "Block logins to web banking",
        blockMobile: "Block logins to mobile banking",
        blockPayment: "Block payment card",
        accountSettings: "Accounts settings",
        defaultAccount: "Default account",
        statementsDelivery: "Statements delivery",
        statementsDeliveryPaper: "Home delivery of paper statements",
        statementsDeliveryElectronic: "Electronic statements in web banking",
        messagesSettings: "Message settings",
        messagesSettingsLabel:
          "Select if you want to recieve all messages from bank also to your email account",
        securitySettings: "Security settings",
        forceNewPassword: "Your password has expired, please enter a new password.",
        transactionsConfirmation: "Transactions confirmation",
        transactionsConfirmationSMS:
          "Receive password via SMS on mobile phone number",
        transactionsConfirmationOTP: "Password from OTP password generator",
        smsNotAvailable: "SMS notifications are not available",
        smsNotAvailableInfo: "Push notifications are enabled in your mobile bank. SMS notifications and push notifications cannot be enabled simultaneously. You will be able to switch on SMS notifications after you disable push notifications in your mobile bank.",
        transactionsConfirmationText:
          "More information on transaction confirmation can be found on ",
        transactionsConfirmationLink: "our website.",
        notificationSettings: "Notification settings",
        gsm: "Mobile phone number *",
        gsmNewSL: "Mobile phone number *",
        'passwordWillExpire': 'Your password is about to expire. You need to reset/enter a new password.',
        gsmHr: " Cellphone No.",
        oldPass: "Old password",
        newPass: "New password",
        againPass: "Confirm new password",
        noMatch: "Check if you have entered password correctly.",
        change: "Change",
        street: "Street",
        houseNumber: "House number",
        postalCode: "Postal code",
        city: "City",
        streetRequiredErrMsg: "Street is required!",
        houseNumRequiredErrMsg: "House number is required!",
        cityOrPostalCodeRequiredErrMsg: "City or postal code is required!",
        emailRequiredErroMsg: "e-mail is required!",
        invalidEmailRequiredErroMsg: "e-mail is not valid!",
        orderSoftToken: "Order soft token",
        setBlockade: "Set blockade",
        passwordFromSMS: "Receive password via SMS on mobile phone",
        passwordFromOTP: "Password from OTP password generator",
        additionalTransactionsInfo:
          "Select additional transaction confirmation",
        blockadeOfAddikoEbank:
          "Blockade of Addiko EBank/Addiko Mobile operations",
        blockadeOfAddikoEBankText:
          "You can disable the use of the Addiko EBank/Addiko Mobile for a fixed or indefinite period of time.",
        userBasedNotifications: "User based notifications",
        accountBasedNotifications: "Account based notifications",
        forAccount: "For account",
        ebankLoginNotice: "Addiko EBank login notice",
        maturedTermDeposit:
          "Matured term deposit notice (5 days before maturity)",
        maturedSavings: "Matured saving notice (5 days before maturity)",
        periodicNotice: "Notice of available balance on account",
        accountBalanceUnder:
          "Notice of account balance under the specified amount",
        accountBalanceAbove:
          "Notice of account balance above the specified amount",
        accountInflow: "Account inflow notice",
        accountOutflow: "Account outflow notice",
        ebankSuccessfull: "Addiko EBank successful payment notice",
        ebankUnSuccessfull: "Addiko EBank unsuccessful payment notice",
        rejectedSEPA: "Rejected SEPA Direct Debit notice",
        newInvoiceNotice: "New e-invoice notice",
        enabled: "Enabled",
        disabled: "Disabled",
        mothersLastName: "Mothers last name",
        email: "Email",
        lengthMaxError: " Password can contain at most {{value}} characters!",
        lengthError:
          "Password should contain at least {{value}} characters in the field New password!",
        lengthOldError:
          "Password should contain at least {{value}} characters in the field Old password!",
        enableMail: "Enable Email",
        enableSms: "Enable SMS",
        disableSms: "Disable SMS",
        required: "This field is required.",
        disableMail: "Disable Email",
        successOrder: "Naročilo je bilo uspešno oddano.",
        notificationsNote:
          "You can subscribe to receive the selected notifications and message alerts via the Addiko EBank. You can receive the messages to your mobile phone or your e-mail address. Transmission of SMS messages is a payable service of the telephone provider Telekom Slovenije d.d. Fee for the transmission of SMS messages and alerts is charged accordingly by",
        notificationsNoteLink: "Addiko Bank d.d. pricelist.",
        changeEMail: "Set E-Mail address",
        emailAddress: "E-Mail address",
        noNumber:
          "Addiko Bank d.d. does not have your mobile phone number in database. In order to change your mobile phone number, you have to visit the branch office.",
        smsModal: {
          user: "User",
          forAccount: "For account",
          orderDesc: "I want to order",
          email: "Email address",
          orderNoticeStart:
            "By entering the data, i agree to receive ordered notices.",
          orderNoticeStop:
            "By entering the data, i agree to stop receiving ordered notices.",
          orderSmsStart1:
            "By entering the data, i agree to receive notices. I declare that i have read and fully agree with the ",
          orderSmsStartLink: "General terms and Conditions ",
          orderSmsStart2:
            "for managing transaction account and provision of payment services.",
          next: "Next",
          cancel: "Cancel",
          back: "Back",
          gsm: "Mobile phone number",
          receivingSmsStart: "Receiving of notifications via sms",
          receivingEmailStart: "Receiving of notifications via email",
          receivingSmsStop: "Disable automated sms notices",
          receivingEmailStop: "Disable automated email notices",
          dailyTimePeriod: "Daily time period",
          noticeAmount: "Notice amount (EUR)",
          noticeAmountUnder: "Notice amount under (EUR)",
          noticeAmountAbove: "Notice amount above (EUR)",
          agreedToReceiveNotices: "I agree to receive notices",
          agreedToGeneralTerms: "I agree with the General Terms and Conditions",
          agreedToStopTerms: "I agree to stop receiving notices",
          yes: "Yes",
          no: "No",
          orderDate: "Order date",
          orderType: "Order type",
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
          period: "Period",
          dailyPeriod: "Daily period",
          weekDays: {
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            sunday: "Sunday",
          },
          newOrder: "New order",
          archive: "Archive",
          successMsg: "Order was successfully submitted.",
        },
        phoneAndEmailNotificationModal: {
          notificationHeader: "Notification: Setting up a mobile phone number",
          email: "Email",
          cancelModal: "Cancel",
          confirmModal: "Confirm"
        },
        showNotificationFor: "I want to receive notifications:",
        notificationSmsNumber: "SMS number for delivering notifications:",
        notificationMailAddress: "Email address for delivering notifications:",
        unsubscribeHeader: "Unsubscribing from mobile banking",
        unsubscribeText:
          "Unsubscribing from Addiko Mobile bank shall enter into force on the first day of the following month. To re-subscribe to Addiko Mobile bank just log in to your Addiko Mobile application.",
        unsubscribeButton: "Unsubscribe",
        unsubscribeAlertHeader:
          "Are you sure you want to unsubscribe from using Addiko Mobile bank?",
        notifs: {
          SignForeignPaymentSMS: "Sign foreign payment notification",
          SignPaymentOrderLimitSMS: "Sign payment order limit notification",
          RejectedPaymentOrderSMS: "Rejected payment order notification",
          LoginFromAnotherBrowserSMS: "Login from another browser notification",
          LoginFromAnotherCountrySMS: "Login from another country notification",
          MinimumAmountDebitedPerCard: "Cost of credit card notification",
          MaturityOfTheLoan: "Instalment maturity notification",
          LimitChangesSMS: "Limit change notification",
          CA_ACCOUNT: "Notifikacija za tekući račun",
          GA_ACCOUNT: "Notifikacija za žiro račun",
          PREPAID: "Notifikacija za PrePaid račun",
          DIRECTDEBIT: "Notifikacija za direktna plaćanja",
          HIBIS: "Notifikacija za tečajne razlike",
          PUSH: "PUSH/SMS",
          EMAIL: "e-Mail",
        },
        notificationInfo: "Your request is sent",
      },
      security: {
        buttons: {
          logout: "Logout",
        },
        title: "Security",
        tabAddikoMobile: "Addiko Mobile",
        tabAddikoEbank: "Addiko EBank",
        paymentRestrictionsPageInfoEBank:
          "By defining the following settings you can define certain limitations connected with payments in Addiko EBank.<br>Detailed instructions and explanations of individual rules can be found in Guidance for using Payment restrictions.",
        paymentRestrictionsPageInfoMBank:
          "By defining the following settings you can define certain limitations connected with payments in Addiko Mobile.<br>Detailed instructions and explanations of individual rules can be found in Guidance for using Payment restrictions.",
        company: "Company",
        paymentAmounts: "Payment amounts",
        paymentRestrictions: {
          individualAmount: "Individual amount",
          dailyAmount: "Daily amount",
          individualAmountDesc:
            "Fee for individual payment transaction is not included in the defined daily payment limit.",
          dailyAmountDesc:
            "All amounts in foreign currencies will be converted to EUR according to the informative rate of the bank.",
          saveChanges: "Save changes",
          paymentAmounts: "Payment amounts:",
          locationAndCurrency: "Location and currency:",
          forbidAll: "Forbid all",
          forbidAllOutsideCro: "Forbid all outside Cro",
          forbidAllOutsideEU: "Forbid all outside EU",
          allowOnlyInCro: "Allow only in Cro",
          additionalLimit: "Additional limits",
          whiteList: "White list",
          blackList: "Black list",
          ibanOfBen: "Iban of Beneficiary",
          accountNumber: "Account number",
          country: "Country",
          nameOfBen: "Name of Beneficiary",
          typeOfRule: "Type of rule",
          definitionOfRule: "Definition of rule",
          remove: "Remove",
          settingType: "Setting type",
          settingDefinition: "Setting definition",
          paymentRestrictionHeading: "Dear clients,",

          paymentRestrictionBody:
            "'Payment limitation' enables you to define the rules and limitations " +
            "on your payment orders created in Addiko EBank i Addiko Mobile. " +
            "Detailed instructions and explanations can be found on this " +
            "link ",

          paymentRestrictionCheckbox:
            "I accept usage of 'Payment limitation' interface.",
          warningBlackList:
            "In order to activate the blacklist, the whitelist must be deactivated!",
          warningWhiteList:
            "In order to activate the whitelist, the blacklist must be deactivated!",
        },
      },
      orderSoftToken: {
        title: "Order soft token",
        infoTxt1:
          "You can place an order for a soft token, which can be used instead of a hard token. Soft token is an application on your mobile phone, which enables a one-time password generation for signing in to Addiko EBank and Addiko Mobile and can fully replace the hard token.",
        infoTxt2:
          "After the successful submission of your order you will receive two separate activation codes in your Addiko EBank mailbox and on your mobile phone to activate the soft token, which serves to generate one-time passwords. More information about the soft token can be found",
        linkTxt: "on our website.",
        emailLbl: "Email:",
        telephoneLbl: "Mobile telephone number:",
        okBtn: "Confirm",
        nextBtn: "Next",
        cancelBtn: "Cancel",
        successTxt: "Order submitted successfully!",
        changeEmail: "Change email",
        changeGsm: "Change phone",
        backBtn: "Back",
        nameAndSurmane: "Name and surname",
      },
      mobilePhoneSettings: {
        setMobilePhoneNumber: "Change mobile phone number",
        title: "Change of mobile phone number",
        headerInfoText: 'The bank will use the mobile phone number entered in the field below as your main mobile phone number after the confirmation process is completed. If you want to change your existing mobile phone number to a foreign mobile phone number, please contact one of Addiko branches.',
        mobilePhoneLabel: "Mobile phone number",
        confirmMobilePhone:
          "I confirm that the mobile phone number is correct. I hereby agree that the Bank sends me notifications related to Addiko Ebank, activation passwords and security codes for transaction confirmation, to this mobile phone number. I also agree to the Bank updating my mobile phone number in Flik Pay and using it for Mastercard-related notifications.",
        firstConfirmationTitle:
          "First confirmation of the existing mobile telephone number",
        firstConfirmationText:
          "If Addiko Bank d.d. holds your mobile telephone number, the latter has already been entered in the input field. After the confirmation of the number you will receive a confirmation code via a SMS message which you need to enter in the field for the confirmation code. In this way you will officially confirm the validity of your mobile phone number.",
        changingExistingNumberTitle:
          "Changing the existing number of your mobile phone",
        changingExistingNumberText:
          "If Addiko Bank d.d. holds your mobile phone number, the latter has already been entered in the field. If you would like to change the number of your mobile phone, delete the already entered one and enter the new one. After the confirmation of this number you will receive a SMS message to your OLD mobile phone number with the confirmation code which you need to enter in the field for the confirmation code. In this way you will officially confirm the validity of the NEW mobile phone number. If you no longer have access to your OLD phone number, you need to make arrangements to change it at one of our Addiko branches. After signing the relevant form we will take care of the official confirmation of your new mobile phone number.",
        newPhoneNumberTitle: "New mobile phone number",
        newPhoneNumberText:
          "If Addiko Bank d.d. does not hold your mobile phone number yet, you will need to visit one of our Addiko branches. After signing the relevant form we will take care of the official confirmation of your mobile phone number. After the official confirmation of your mobile phone number you will be able to receive notifications and codes for the confirmation of your transactions via SMS messages.",

        orderDate: "Order date",
        orderType: "Order type",
        orderTypeInfo: "Change of mobile phone number",
        dataConfirmation: "Data confirmation",
        additionalPaymentConfirmationTitle: "Additional confirmation",
        additionalPaymentConfirmationText:
          'Please enter the confirmation code you generate using the password generator in the field below. Process is the sam as to „Generate OTP/mToken".',
        SMSCode: "Enter the verification code you received via SMS message:",

        gsmPhoneError:
          "Entered mobile phone number is not in the correct format.",
        requiredField: "Required field.",
        SMSCodeRequired: "Confirmation code is required.",
        numberChangeSuccess: "A message has been sent to your mobile phone number containing a link with a confirmation code. Your mobile phone number will be changed when you confirm it.",
        notificationSettingUpMobile1: "Spoštovani",
        notificationSettingUpMobile2:
          "zaradi uvajanja novih varnostnih zahtev, ki jih prinaša Direktiva o plačilnih storitvah (PSD2), je potrebno odslej določene postopke potrjevati z enkratno kodo, povezano s točno to aktivnostjo. V primeru Addiko EBank to velja za vsa plačila, ki niso shranjena med predlogami.  Poleg plačil preko Predlog so izjeme tudi interni prenosi ter plačilo e-računov, kjer dodatno potrjevanje ni potrebno. Koda bo ob potrditvi plačila poslana preko sporočila SMS na registrirano številko vašega mobilnega telefona, ki jo bo potrebno vpisati v za to namenjeno polje v spletni banki Addiko EBank. Zaradi navedenega vas prosimo, da v nadaljevanju vnesete oziroma potrdite vaše kontaktne podatke. Z vnosom/potrditvijo kontaktnih podatkov se avtomatsko spremeni tudi način potrjevanja transakcij (preko enkratne kode SMS). V primeru, da podatkov ne vnesete/potrdite, bodo funkcionalnosti, kjer se koda zahteva, po poteku prehodnega obdobja onemogočene.",
        notificationSettingUpMobile3:
          "Za več informacij smo na voljo na e-poštnem naslovu ",
        "againPassError":"Please confirm new password",

      },
      'emailVerification': {
        emailLabel: "E-mail address",
        title: "Change e-mail address",
        headerInfoText: "The e-mail address entered in the field below will be used by the Bank as your main e-mail address after the confirmation process is completed.",
        orderTypeInfo: "Change of e-mail address",
        emailChangeSuccess: "An email has been sent to your email address containing a link with a confirmation code. Your email address will be changed once you have confirmed it.",
        confirmEmail: 'I confirm that the e-mail address is correct. I hereby also agree that the Bank sends me notifications and activation passwords related to Addiko Ebank, various notifications and documentation related to the contracts I have with the Bank (e.g. notifications of interest rate changes, contract documentation, tickets, etc.) to this e-mail address, and I also agree that the Bank updates my e-mail address in Flik Pay.',
      }
    };

    $translateProvider.translations("en", data);
  }
})();

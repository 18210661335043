
(function () {
    'use strict';
  
    /**
     * @ngdoc service
     * @name accounts.factory:ComplaintsService
     *
     * @description
     *
     */
    angular
      .module('complaints')
      .factory('ComplaintsService', ComplaintsService);
  
    function ComplaintsService($http, $q, EndpointsService, ConfigService, HelperService) {
      var ComplaintsServiceBase = {};
      ComplaintsServiceBase.getById = function (id){
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.complaints.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };
      ComplaintsServiceBase.getPage = function (params){
        var deferred = $q.defer();
        if (params.page == 0) {
          params.synchronize = true;
        }
        EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.complaints, {
              params: params
            })
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };
    
      ComplaintsServiceBase.insertComplaint = function (complaintObj) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.post(response.links.complaints, complaintObj)
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      ComplaintsServiceBase.uploadAttachment = function (files, complaintId) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          var formData = new FormData();

          var i = 0;
          for (i; i < files.length; i++) {
            formData.append("file", files[i]);
          }
          formData.append("complaintId", complaintId);
          $http({
            method: 'POST',
            url: response.links.complaints_upload,
            headers: {'Content-Type': undefined},
            data: formData
          })
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      ComplaintsServiceBase.updateComplaint = function (id, complaintObj) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.put(response.links.complaints.replace('{id}', id), complaintObj)
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };
      ComplaintsServiceBase.deleteComplaints = function (id) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
            $http.delete(response.links.complaints.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };
      ComplaintsServiceBase.getFormsConfiguration = function (params) {
        var deferred = $q.defer();
        EndpointsService.getLinks().then(function (response) {
          var url = response.links['complaints_getComplaintForms'];
          if (params) {
            url += '?'
            if (params.objectType) {
              url += 'objectType=' + params.objectType + '&'
            }
            if (params.objectId) {
              url += 'objectId=' + params.objectId + '&'
            }
          }
          
            $http.get(url)
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          },
          function (error) {
            deferred.reject(error.data);
          });
        return deferred.promise;
      };

      ComplaintsServiceBase.getComplaintMessages = function (complaintId) {
        var deferred = $q.defer();
        var url = ConfigService.url + 'complaints/getMessages?complaintId=' + complaintId;
        $http.get(url)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });

        return deferred.promise;
      };

      ComplaintsServiceBase.getComplaintAttachments = function (complaintId) {
        var deferred = $q.defer();
        var url = ConfigService.url + 'complaints/getAttachmentsForComplaintId?complaintId=' + complaintId;
        $http.get(url)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        
        return deferred.promise;
      };

      ComplaintsServiceBase.downloadAttachment = function (attachmentId, fileName, contentType) {
        var deferred = $q.defer();
          var url = ConfigService.url + 'complaints/downloadByFilter?request.attachmentId=' + attachmentId + '&request.fileName=' + fileName + '&request.contentType=' + contentType;
          HelperService.manageDownload(url, fileName, '');
          
        return deferred.promise;
      };

      ComplaintsServiceBase.addMessage = function (data) {
        var deferred = $q.defer();
        var url = ConfigService.url + 'complaints/addMessage';
        $http.post(url, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });

        return deferred.promise;
      };

      ComplaintsServiceBase.setMessagesAsRead = function (complaintId) {
        var deferred = $q.defer();
        var url = ConfigService.url + 'complaints/setMessagesRead?complaintId=' + complaintId;
        $http.put(url, data)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });

        return deferred.promise;
      };
      return ComplaintsServiceBase;
    }


    
    
    
  }());
  
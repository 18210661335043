(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      'complaints': {
        remove: 'Obriši',
        'insertionInfo': 'Potvrda primitka Vašeg prigovora je vidljiva u Porukama.',
        'header': 'Prigovori',
        'title': 'Trenutno nemate nijedan prigovor',
        'subtitle': 'Ako želite možete nam poslati Vaš prigovor',
        'new-complaint': 'Kreirajte novi prigovor',
        'communication': 'Komunikacija',
        'update-complaint': 'Dopuni prigovor',
        'details': 'Detalji',
        'documents': 'Dokumenti',
        'category': 'Kategorija',
        'currency': 'Valuta',
        'categories': 'Kategorije',
        'noDataTitle': 'Nema podataka',
        'noDataDesc': 'Trenutno nema prigovora po zadanom kriteriju',
        'subcategory': 'Potkategorija',
        'description': 'Opis prigovora',
        'confirm': 'Potvrdite',
        'cardNumber': 'Broj kartice',
        'categories': 'Kategorije',
        'cardType': 'Vrsta kartice',
        'transactionDate': 'Datum transakcije',
        'transactionAmount': 'Iznos transakcije',
        'merchantName': 'Prodajno mjesto',
        'atmLocation': 'Lokacija bankomata',
        'transactionNumber': 'Broj transakcije',
        'transactionPayerName': 'Naziv platitelja',
        'transactionDueDate': 'Datum izvršenja',
        'transactionPayeeName': 'Naziv primatelja',
        'mHeader': 'Pregled prigovora',
        'all': 'Svi',
        'sent': 'Poslani',
        'processed': 'Završeni',
        'send': 'Pošaljite',
        'back': 'Natrag',
        'loadMore': 'Učitaj još',
        'statuses': {
          'InProcessing': 'Zaprimljen',
          'Processed': 'Riješen',
          'Rejected': 'Odbijen',
          'Cancelled': 'Odustajanje od prigovora',
        },
        "nodata": "Nema podataka za prikaz",
        'uploadSuccess': 'Dokument je uspješno dodan! Nakon sigurnosne provjere bit će vidljiv u dijelu "Dokumenti".'
      },
      'complaint': {
        'title': 'Kako Vam možemo pomoći?',
        'subtitle': 'Poštujemo Vaše mišljenje, podijelite Vaše prigovore i prijedloge s nama!',
      }
    };

    $translateProvider
      .translations('hr', data);

  }
}());

(function () {
    'use strict';
  
    angular
      .module('localization')
      .config(config);
  
    function config($translateProvider) {
  
      var data = {
        'complaints': {
          remove: 'Delete',
          'insertionInfo': 'Confirmation of receipt for your complaint is visible in Messages.',
          'header': 'Complaints',
          'title': 'There are no complaints at the moment',
          'subtitle': 'Send us your complaint',
          'new-complaint': 'New complaint',
          'communication': 'Communication',
          'update-complaint': 'Add complaint',
          'details': 'Details',
          'documents': 'Documents',
          'category': 'Category',
          'categories': 'Categories',
          'noDataTitle': 'No data',
          'noDataDesc': 'There is no data by current criterea',
          'subcategory': 'Subcategory',
          'description': 'Complaint description',
          'confirm': 'Confirm',
          'cardNumber': 'Card number (first 6 digits + last 4)',
          'cardType': 'Card type',
          'currency': 'Currency',
          'transactionDate': 'Transaction date',
          'transactionAmount': 'Transaction amount',
          'merchantName': 'Selling spot',
          'atmLocation': 'ATM location',
          'transactionNumber': 'Number of transaction',
          'transactionPayerName': 'Name of the payer',
          'transactionDueDate': 'Execution date',
          'transactionPayeeName': 'Recipient\'s name',
          'mHeader': 'Complaints overview',
          'all': 'All',
          'sent': 'Sent',
          'processed': 'Finished',
          'send': 'Send',
          'back': 'Back',
          'loadMore': 'Load more',
          'statuses': {
            'InProcessing': 'Accepted',
            'Processed': 'Solved',
            'Rejected': 'Rejected',
            'Cancelled': 'Cancellation of complaint',
          },
          "nodata": "No availabe data",
          'uploadSuccess': 'The document has been successfully uploaded! After the security check, it will be visible in the "Documents" section.'
        },
        'complaint': {
          'title': 'How can we help you?',
          'subtitle': 'We respect your opinion, share your complaints and proposals with us!',
        }
      };
  
      $translateProvider
        .translations('en', data);
  
    }
  }());
  
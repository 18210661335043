(function () {
  'use strict';

  angular
      .module('localization')
      .config(config);

  function config($translateProvider) {

    var data = {
      alert: {
        information: "Informacije",
        areYouSure: "Da li ste sigurni?",
        blockCardInfo: "Do you really want to block your card ?",
        blockCardCost: "It will cost you",
        blockCard: "Blokiraj",
        payment: "Nalog broj",
        template: "Uzorak broj",
        termDeposit: "Oročeni depozit",
        investment: "Investicija broj",
        subscriptionRequest: "Uspješno ste unijeli zahtjev za pretplatu",
        successSign: "Uspešno ste potpisali nalog",
        closeEBook: "Uspešno ste zatvorili eknjižicu",
        cancelRegistration: "Uspešno ste otkazali registraciju",
        ebookOrder: "eknjižica broj",
        archiveEInvoice: "Uspešno ste arhivirali e-račun",
        saveTemplate: "Uspešno ste sačuvali šablon",
        updateTemplate: "Uspešno ste ažurirali šablon",
        deleteAction: "Brisanje",
        cancel: "Zatvori",
        yes: "Da",
        cancelActivation: "Otkaži aktivaciju",
        no: "Ne",
        qrCode: "Kreirani QRCode",
        cancelation: "Povraćaj broj",
        cardActivationInfo: "Molimo unesite 4 zadnje cifre sa kartice",
        cardActivationPlaceholder: "XXXX",
        confirm: "Potvrdi"
      }
    };

    $translateProvider
        .translations('sr', data);

  }
}());

<div class="p-t-20">
  <i class="icon icon-add pull-right close-icon-x" ng-click="vm.close($close);" style="font-size: 40px;"></i>
  <div class="modal-body complaintMessagesModal">
    <div id="tabs-container" class="d-flex justify-content-space-between align-items-center">
      <addiko-tabs-container tabs="vm.tabs" selected="selectedTab"></addiko-tabs-container>
    </div>
    <ng-container ng-show="selectedTab === '1'">
      <div class="row m-0 complaints-modal-body">
        <div class="col-md-12 complaint-item">
          <div class="row m-0  ">
            <div class="col-md-12 p-0">
              <span class="date-label">{{vm.data.creationDate | date}}</span>
            </div>
          </div>
          <div class="complaint-item-header">
            <div class="icon-wrap">
              <span class="message-icon"></span>
            </div>

            <div class="complaint-desc mt-3 flex-5">
              <h4 class="m-0">{{vm.data.categoryName}} / {{vm.data.subCategoryName}}</h4>
            </div>

          </div>
          <pre class="complaint-details m-0 mt-3" load-more="vm.data.complaintDesc"></pre>

        </div>
        <div class="col-md-12 messages-wrapper complaint-padding-fix">
          <div class="message-item" ng-repeat="message in vm.data.messages">
            <div class="row m-0">
              <div class="col-md-12 p-0">
                <span class="date-label">{{message.creationDate | date: 'd. MMM yyyy HH:MM'}}</span>
              </div>
            </div>
            <div class="message-item-data">
              <div class="icon-wrap">
                <span ng-if="message.messageType != 'Outgoing'" class="agent-icon"></span>
                <span ng-if="message.messageType == 'Outgoing'" class="client-icon"></span>
              </div>

              <div class="mt-3 flex-5">
                <h4 class="m-0">{{message.from}}</h4>
                <p class="m-0 mt-3 ml-3" ng-bind-html="message.message"></p>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 complaints-modal-footer">
        <div ng-if="!vm.forbidReply" class="col-md-12 complaint-padding-fix add-message-wrap mt-3 pr-3">
          <label class="addiko-new-label" translate="complaints.update-complaint"></label>
          <textarea class="addiko-new-textarea mHidden" rows="6" ng-model="vm.message" required></textarea>
          <textarea class="addiko-new-textarea mShow" rows="3" ng-model="vm.message" required></textarea>
          <addiko-file-upload class="w-50 d-block" complaint-id="vm.data.complaintId"></addiko-file-upload>

        </div>
        <div class="col-md-12 complaint-padding-fix pr-3">
          <div class="d-flex justify-content-space-between w-100">
            <button class="btn btn-primary m-5" ng-click="vm.close($close);" translate="complaints.back">
            </button>
            <button class="btn btn-default m-5" ng-if="!vm.forbidReply" ng-disabled="vm.sendingInProgress"
              ng-click="vm.sendMessage()" translate="complaints.send"></button>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container ng-show="selectedTab === '2'">
      <div class="row m-0 complaints-modal-body pt-3">
        <div ng-if="vm.data.attachments.length" class="col-md-12 attachments-wrapper">
            <div class="message-item attachment-item pt-3 pb-3" ng-repeat="attachment in vm.data.attachments">
                <div class="row mlr-0 mb-2">
                    <div class="col-md-12 p-0">
                        <span class="date-label">{{attachment.creationDate | date: 'dd.MM.yyyy. HH:MM'}}</span>
                    </div>
                </div>
                <div class="attachment-item-data pr-3 pl-3">
                    <div class="icon-wrap">
                        <span ng-if="attachment.attachmentType != 'Outgoing'" class="icon-docs-incoming"></span>
                        <span ng-if="attachment.attachmentType == 'Outgoing'" class="icon-docs-outgoing"></span>
                    </div>

                    <div class="ml-2 mt-3 flex-5">
                        <h4 class="m-0 text-underline cursor-pointer" ng-click="vm.downloadAttachment(attachment)">{{attachment.fileName}}</h4>
                    </div>

                </div>
            </div>
        </div>
        <div ng-if="!vm.data.attachments.length" class="t-a-c m-t-10 p-10 no-data-image">
          <i class="zmdi zmdi-cloud-off zmdi-hc-5x"></i>
           <h3 class="no-data-label" translate="complaints.nodata"></h3>
        </div>
    </div>
    <div class="row m-0 complaints-modal-footer">
        <div class="col-12 pr-3" style="margin-top: 10px;">
            <div class="d-flex justify-content-space-between w-100">
                <button class="btn btn-primary m-5"
                        ng-click="vm.close($close)"><span translate="complaints.back"></span>
                </button>
            </div>
        </div>
    </div>
    </ng-container>
  </div>
</div>
(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name core.controller:addikoDropdown
     *
     * @description
     *
     */
    angular
      .module('core')
      .directive('addikoFileUpload', addikoUpload);
  
    function addikoUpload($rootScope) {
        return {
            restrict: 'EA',
            scope: {
                complaintId: '=',
                uploadOnSelect: '@'
            },
            templateUrl: 'core/components/upload/upload.tpl.html',
            replace: false,
            controllerAs: 'vm',
            controller: function ($scope) {
                var vm = this;
                vm.files = [];
                $scope.files = [];
                vm.onFileUpload = function(event) {
                    if (event) {
                        $rootScope.$broadcast('onFiles', {files: event.target.files, id: $scope.complaintId});
                        if ($scope.uploadOnSelect) {
                            vm.tempHide = true;
                            setTimeout(function() {
                                vm.tempHide = false;
                            }, 500)
                        }
                    }
                };
                vm.tempHide = false;
            },
        };
    }
}());
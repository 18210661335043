(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name complaints.controller:ComplaintCtrl
   *
   * @description
   *
   */
  angular
    .module('complaints')
    .controller('ComplaintCtrl', ComplaintCtrl);

  function ComplaintCtrl($q, $rootScope, HelperService,ConfigService, NotificationService, ComplaintsService,
                         $scope, $translate, $state, ValidationService, $sce, CurrencyService, AlertService) {
    var vm = this;
    vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.complaint = [];
    vm.currencies = [];
    vm.formsConfiguration = {};
    vm.complaintCategories = [];
    vm.complaintSubCategories = [];
    vm.cardTypes = [];
    vm.formConfiguration = null;
    vm.isNuN = HelperService.isNuN;
    vm.formsConfigurationParams = null;
    vm.selectedFiles = [];
    $scope.categoryId = '';
    $scope.subCategoryId = '';
    $scope.cardType = '';
    $scope.currency = '';
    vm.uiMaskOptions = ValidationService.getUiMaskOptions().croatia.iban;
    vm.model = {
    };
    vm.calendarMinDate = new Date();
    var COMPLAINTS_MAX_UPLOAD_SIZE = ConfigService.complaintsFileSizeMax;
    vm.calendarMaxDate = new Date();
    vm.calendarMinDate.setFullYear(vm.calendarMinDate.getFullYear() - 2);
    $rootScope.$on('onFiles', onFilesAdded);

    $scope.$watch('categoryId', function (newVal, oldVal) {
      vm.complaintSubCategories = vm.formsConfiguration[newVal];
      vm.formConfiguration = null;
      $scope.subCategoryId = '';
    });
    $scope.$watch('subCategoryId', function (newVal, oldVal) {
      if (newVal) {
        vm.formConfiguration = vm.complaintSubCategories.find(function (x) { return x.subCategoryId === newVal });
        if (vm.formConfiguration.clientNotice) {
          vm.formConfiguration.clientNoticeHTML = $sce.trustAsHtml(vm.formConfiguration.clientNotice);
        }
      }
    });
    $rootScope.$on('appLanguageChangedHeader', function () {
      vm.init(true);
    })
    vm.init = function (checkExistingData) {
      $scope.$on('$destroy', function(event, something ){
        $rootScope.$off('onFiles', onFilesAdded);
      });
      var transactionId = $state.params.transactionId;
      if (transactionId) {
        vm.formsConfigurationParams = { objectType: 'TRANSACTION', objectId: transactionId };
      }
      var promises = [ComplaintsService.getFormsConfiguration(vm.formsConfigurationParams), CurrencyService.getCurrencyPage({
        pageSize: 1000
      })]; //
      $q.all(promises)
        .then(function (responses) {
          vm.formsConfiguration = [];
          vm.complaintCategories = responses[0].complaintCategories;

          if (responses[0].complaint) {
            vm.model = responses[0].complaint;
            $scope.currency = vm.model.transactionAmountCurrency;
          }
          var subCategory;
          for (var i = 0; i < responses[0].complaintForms.length; i++) {
            var temp = responses[0].complaintForms[i];
            subCategory = responses[0].complaintSubCategories.find(function (x) { return x.subCategoryId == temp.subCategoryId });
            temp.subCategoryName = subCategory.subCategoryName;
            if (!vm.formsConfiguration[temp.categoryId]) {
              vm.formsConfiguration[temp.categoryId] = [];
            }
            vm.formsConfiguration[temp.categoryId].push(temp);
            vm.cardTypes = responses[0].complaintCardTypes;



            if (responses[1]) {
              vm.currencies = responses[1].result;
            }

            if (checkExistingData) {
              vm.complaintSubCategories = vm.formsConfiguration[$scope.categoryId];
            }
          }

          for (var prop in vm.model){
            if (vm.model.hasOwnProperty(prop)) {
              if (vm.model[prop]) {
                vm[prop+ 'Disabled'] = true;
              }
            }
          }

        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });


    };

    vm.init();

    function onFilesAdded(event, data) {
      var invalidFile = HelperService.validateFileMaxSize(data.files, COMPLAINTS_MAX_UPLOAD_SIZE);
      if (invalidFile) {
        var translation = $translate.instant('common.fileUploadLimit', { fileName: invalidFile.name, maxSize: Math.round(COMPLAINTS_MAX_UPLOAD_SIZE / (1024 * 1024)) }) + 'MB'
        AlertService.infoAlert({
          text: translation,
        }).then();
        return;
      }
      vm.selectedFiles.push(data.files[0]);
      $scope.$apply();
    }

    vm.createNewComplaint = function () {
      var temp = Object.assign({}, vm.model);

      if (temp.cardNumber) {
        var cardNumber = temp.cardNumber;
        temp.cardNumber = cardNumber.slice(0, 6) + '******' + cardNumber.slice(6);
      }

      convertToISO(temp.transactionDueDate);
      convertToISO(temp.transactionDate);

      temp.categoryId = $scope.categoryId;
      temp.subCategoryId = $scope.subCategoryId;
      temp.cardTypeId = $scope.cardType;
      temp.transactionAmountCurrency = $scope.currency;
      ComplaintsService.insertComplaint(temp).then(function (response) {
        ComplaintsService.uploadAttachment(vm.selectedFiles, response.complaintId).then().catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
        AlertService.infoAlert({
          text: "complaints.insertionInfo"
        }).then(function () {
          $state.go('complaints')
        });
      }).catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });;
    }
    vm.back = function () {
      history.back();
    }


    function convertToISO(date) {
      if (!date) {
        return;
      }

      if (typeof date == 'object') {
        date = date.toISOString();
      }
    }
    vm.deleteFile = function(index) {
      vm.selectedFiles.splice(index, 1);
    }

  }

  
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:TransactionDivisionCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('TransactionDivisionCtrl', TransactionDivisionCtrl);

  function TransactionDivisionCtrl($rootScope, $scope, $filter, $log, $state, $stateParams, TransactionDivisionService, ValidationService,
                                   HelperService, AlertService, TransactionsService, ReservationsService, NotificationService ) {
    var vm = this;
    // Helpers
    vm.apiLocation = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.stateSufix = vm.apiLocation == 'hr' ? 'Id' :  '';
    vm.challengeResponse = '';
    vm.accountId_Final = 0;
    vm.backInitiated = false;
    vm.description = '';
    vm.isSignFirstStep = false;
    vm.countryData = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
    vm.defaultCurrency = $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
    var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.installmentRange = [];
    if(vm.apiLocation == "sr") {
      vm.installmentRange = ['-','3', '6', '9', '12'];
    } else {
      vm.lastIndex = 12; 
      if ($stateParams.accountTypeId == '1051' || vm.apiLocation === 'hr') {
        vm.lastIndex = 24; 
      }
      for (var i = 2; i <= vm.lastIndex; i++) {
        vm.installmentRange.push(i.toString());
      }
    }
    vm.tempTransDivObj = {
      creationDate: $filter('date')(new Date(), 'shortDate'),
      fullName: userSettings.firstName + ' ' + userSettings.lastName,
      jmbg: userSettings.taxId,
      transactionDate: new Date(),
      amountInput: '',
      installmentNumber: vm.installmentRange[0],
      transactionLocation: '',
      cardNumber: $stateParams.card || '',
      id: '',
      currency: $stateParams.currency || ''
    };

    vm.step = '';
    vm.transactionId = null;
    vm.reservationId = null;

    var forUpdate = false;
    var step2 = false;
    var transactionDivisionFilter = {
      page: 0,
      pageSize: 10,
      orderBy: 'creationDate desc'
    };
    vm.datePickerBeginOpened = false;

    var currentTime = new Date();
    vm.dateOptions = {
      // minDate: HelperService.setCustomNegativeMonthPeriod(new Date(), 1),
      minDate: new Date(currentTime.getFullYear(), currentTime.getMonth(), +1),
      maxDate: new Date()
    };

    vm.transactionDivisionGrouped = [];

    vm.periodList = [
      {
        item: {
          key: 'today',
          description: 'core.period.today'
        }
      }
      , {
        item: {
          key: 'yesterday',
          description: 'core.period.yesterday'
        }
      }
      , {
        item: {
          key: 'thisWeek',
          description: 'core.period.thisWeek'
        }
      }
      , {
        item: {
          key: 'thisMonth',
          description: 'core.period.thisMonth'
        }
      }
      , {
        item: {
          key: 'thisYear',
          description: 'core.period.thisYear'
        }
      }
      , {
        item: {
          key: 'all',
          description: 'core.period.all'
        }
      }
    ];

    vm.selectedPeriod = vm.periodList[3];

    //set time filter to current month
    var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
    transactionDivisionFilter.creationDateGTE = timeRange.valueDateGTE;
    transactionDivisionFilter.creationDateLTE = timeRange.valueDateLTE;


    function loadTransactionDivisions (reset) {
      if(reset){
        transactionDivisionFilter.page=0;
      } else {
        transactionDivisionFilter.page++;
      }
      TransactionDivisionService.getPage(transactionDivisionFilter)
        .then(function (response){
          vm.transactionDivisionGrouped = HelperService.mergeRowsForTimeline({
            existingData: vm.transactionDivisionGrouped,
            newData: response,
            groupFunction: function (item) {
              var date = new Date(item.creationDate);
              date.setHours(0,0,0,0);
              return date;
            },
            virtualRowFunction: function (e) {
              var month = new Date(e).getMonth() + 1;
              var day = new Date(e).getDate();
              var virtualRow = {
                isVirtual: true,
                date: new Date(e),
                month: month,
                day: day
              };
              $log.debug("virtualRow", e, virtualRow);
              return virtualRow;
            },
            reset: reset,
            reverse: false
          });
          $log.debug("Transaction division grouped: " + vm.transactionDivisionGrouped);
        })
        .catch(function (){

        });
    };

    vm.timelineTimeFilter = function (period) {
      vm.selectedPeriod = period;
      var timeRange = HelperService.getDateRange(vm.selectedPeriod.item.key);
      transactionDivisionFilter.creationDateGTE = timeRange.valueDateGTE;
      transactionDivisionFilter.creationDateLTE = timeRange.valueDateLTE;
      loadTransactionDivisions(true);
    };

    vm.loadMoreTimelineItems = function (){
      loadTransactionDivisions(false);
    };
    
    function init() {
      $rootScope.$on('$locationChangeStart', function (event, newUrl, oldUrl) {
        if (oldUrl.includes('final') && !vm.backInitiated) {
          event.preventDefault(); // This prevents the navigation from happening
        }
      });
      vm.step = '';
      vm.transactionId = $state.params.transactionId;
      vm.reservationId = $state.params.reservationId;
      vm.accountId_Final = $state.params.accountId;
      if ($stateParams.transactionDivisionId) {
        TransactionDivisionService.getById($stateParams.transactionDivisionId).then(function(response){
          vm.postResponseObj = response;
          HelperService.loadChallengeResponse(response, vm);
          if (!vm.transactionId) {
            vm.transactionId = response.transactionID;
            TransactionsService.getById(vm.transactionId, {includeList: ['TransactionDivision']})
              .then(function (response) {
                populateFields(response);
              }, function (error) {
              NotificationService.showMessage(error, 'error');
            });
          } 
          step2 = true;
          if (vm.apiLocation == 'hr') {
            vm.tempTransDivObj.installmentRateAmount = (vm.postResponseObj.amount / vm.postResponseObj.installmentNumber).toFixed(2);
            vm.tempTransDivObj.transactionDivisionFeeAmount = vm.postResponseObj.transactionDivisionFeeAmount;
          }
        
        });
        
      }
      if((vm.transactionId) && !forUpdate){

        TransactionsService.getById(vm.transactionId || vm.reservationId, {includeList: ['TransactionDivision']})
          .then(function (response) {
            populateFields(response);
          }, function (error) {
            NotificationService.showMessage(error, 'error');
          });
      } else if(vm.reservationId  && !forUpdate){
        ReservationsService.getById(vm.reservationId, {includeList: ['TransactionDivision']})
          .then(function (response) {
            populateFields(response);
          }, function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
      HelperService.initializeMTokenSignature.call(vm, $scope, vm.signTransactionDivision);
      loadTransactionDivisions(true);
    }
    
    vm.changeInstallment = function(factor) {
      if ((vm.tempTransDivObj.installmentNumber == '2' && factor === -1) || (vm.tempTransDivObj.installmentNumber == vm.lastIndex && factor === 1)) {
        return;
      }
      vm.tempTransDivObj.installmentNumber = (+vm.tempTransDivObj.installmentNumber + factor).toString();
    }

    function populateFields(transaction) {
      vm.tempTransDivObj.transactionDate = new Date(transaction.valueDate);
      vm.tempTransDivObj.amountInput = transaction.amount;
      vm.tempTransDivObj.transactionLocation = transaction.detail ? transaction.detail.purpose : '';
      vm.tempTransDivObj.cardNumber =  $state.params.card;
      vm.tempTransDivObj.reservationID = transaction.reservationID;
      vm.accountId_Final = transaction.accountId;
    }

    function createInsertObject () {
      return {
        installmentNumber: vm.tempTransDivObj.installmentNumber,
        amount: vm.tempTransDivObj.amountInput,
        transactionLocation: vm.tempTransDivObj.transactionLocation,
        cardNumber: vm.tempTransDivObj.cardNumber,
        valueDate: vm.tempTransDivObj.transactionDate
      }
    };

    vm.confirm = function () {
      vm.formSubmitted = true;
      if (!vm.tDivForm.$valid || vm.tempTransDivObj.installmentNumber === '-') {
        return;
      }
      var insertObj = createInsertObject();

      if(vm.transactionId){
        insertObj.transactionId = vm.transactionId;
        insertObj.reservationID = vm.tempTransDivObj.reservationID;
      }else if(vm.reservationId){
        insertObj.reservationID = vm.reservationId;
      }

      if (forUpdate) {
        //update transaction division
        insertObj.id = vm.postResponseObj.id;
        TransactionDivisionService.updateTransaction(vm.postResponseObj.id, insertObj)
          .then(function (response) {
            postPaymentAction(response);
          })
          .catch(function (error){
            NotificationService.showMessage(error, 'error');
          });
      } else {
        //insert transaction division
        TransactionDivisionService.insertTransaction(insertObj)
          .then(function (response) {
            postPaymentAction(response);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    function postPaymentAction(response) {
      vm.postResponseObj = response;
      HelperService.loadChallengeResponse(response, vm);
      step2 = true;
      if (vm.apiLocation == 'hr') {
        vm.tempTransDivObj.installmentRateAmount = (vm.postResponseObj.amount / vm.postResponseObj.installmentNumber).toFixed(2);
        vm.tempTransDivObj.transactionDivisionFeeAmount = vm.postResponseObj.transactionDivisionFeeAmount;
      }
      $state.go('transactionDivison.resume' + vm.stateSufix, {
        resume: step2,
        transactionDivisionId: response.id,
        accountId: vm.accountId_Final
      });
      forUpdate = false;
    }

    function clearInput () {
      vm.tempTransDivObj.amountInput = '';
      vm.tempTransDivObj.cardNumber = '';
      vm.tempTransDivObj.transactionDate = new Date();
      vm.tempTransDivObj.installmentNumber = vm.installmentRange[0];
      vm.tempTransDivObj.transactionLocation = '';
    }

    vm.new = function () {
      clearInput();
      vm.formSubmitted = false;
      forUpdate = false;
      step2 = false;
      vm.transactionId = null;
      $state.go('transactionDivison.create' + vm.stateSufix, {
          accountId: vm.accountId_Final
      });
      loadTransactionDivisions(true);
    };

    function mapWithPaymentResponse () {

      vm.tempTransDivObj.amountInput = vm.postResponseObj.amount;
      vm.tempTransDivObj.cardNumber = vm.postResponseObj.cardNumber;
      vm.tempTransDivObj.transactionDate = new Date(vm.postResponseObj.valueDate);
      vm.tempTransDivObj.installmentNumber = _.find(vm.installmentRange, function (item){
        return item == vm.postResponseObj.installmentNumber;
      });
      vm.tempTransDivObj.transactionLocation = vm.postResponseObj.transactionLocation;
      //vm.tempTransDivObj.id = vm.postResponseObj.id;
    };

    vm.edit = function () {
      forUpdate = true;
      step2 = false;
      mapWithPaymentResponse();
      $state.go('transactionDivison.create' + vm.stateSufix, {transactionId: vm.transactionId, accountId: vm.accountId_Final, 
        transactionDivisionId: $stateParams.transactionDivisionId});
      loadTransactionDivisions(true);
    };

    vm.delete = function (id) {
      var objId = id || vm.postResponseObj.id;
      forUpdate = false;
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            TransactionDivisionService.deleteTransaction(objId)
              .then(function (response) {
                AlertService.deletedPaymentAlert({
                    paymentName: objId,
                    type: 'payment'
                  })
                  .then(function () {
                    if (vm.apiLocation === 'hr') {
                      $state.go('accounts', {
                        accountId: vm.accountId_Final
                      });
                    }  else {
                      if(vm.transactionId) {
                        $state.go('accounts', {transactionId: vm.transactionId});
                        init();
                      }
                      else{
                        $state.go('transactionDivison.create' + vm.stateSufix);
                        clearInput();
                        vm.formSubmitted = false;
                        loadTransactionDivisions(true);
                      }
                    }
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    vm.sign = function (id) {
      if (vm.apiLocation === 'hr') {
        vm.isSignFirstStep = true;
      } else {
        var objId = id || vm.postResponseObj.id;
        vm.signTransactionDivision({id: objId});
      }
    };

    vm.signTransactionDivision = function(temp) {
      if (!temp) {
        temp = {id: vm.postResponseObj.id, signData: vm.challengeResponse}
      }

      if (vm.showChallenge) {
        params.additionalSignData = vm.challengeResponse2;
      }

      TransactionDivisionService.signTransaction(temp)
          .then(function (response) {
            vm.signInProgress = false;
            vm.step = 'final';
            $state.go('transactionDivison.final' + vm.stateSufix,
              {
                final: true, 
                transactionDivisionId:  vm.postResponseObj.id,
                accountId: vm.accountId_Final
              });
          })
          .catch(function (error) {
            vm.signInProgress = false;
            NotificationService.showMessage(error, 'error');
          });
    }

    vm.editFromTimeline = function (id){
      TransactionDivisionService.getById(id)
        .then(function(response){
          vm.postResponseObj = response; 
          HelperService.loadChallengeResponse(response, vm);
          mapWithPaymentResponse();
          forUpdate = true;
        })
        .catch(function (error){
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.goToCreatePage = function () {
      vm.step = '';
      clearInput();
      vm.formSubmitted = false;
      $state.go('transactionDivison.create' + vm.stateSufix);
    };

    vm.goToTransactionsPageSRB = function () {
      vm.step = '';
      vm.backInitiated = true; // This is because we prevent navigation anywhere if navigating through the browser controls
      clearInput();
      vm.formSubmitted = false;
      $state.go('accounts', {
        accountId: vm.accountId_Final
      });
    };

    

    init();
  }
}());

(function () {
  'use strict';

  angular
    .module('localization')
    .config(config);

  function config($translateProvider) {

    var data = {
      payments: {
        "quickPaymentTransfer": "Quick payment or transfer",
        "domesticPayments": "Universal payment order",
        "currencyConversion": "Currency conversion",
        "internationalPayment": "International payment",
        "rejectedPayments": "Rejected payments",
        "pendingPayments": "Pending payments",
        "templates": "Templates",
        "searchTemplateName": "Search template by name",
        "topUsed": "Top used",
        "alphabetically": "Alphabetically",
        "to": "to",
        "showMoreTemplates": "Show more templates",
        "eInvoices": "eInvoices",
        "showMoreInvoices": "Show all eInvoices",
        "sepaDirectDebit": "SEPA DD Settings",
        "viewAllTransactions": "View all transactions",
        "due": "due",
        "allPastPayments": "View all past payments",
        "exchangeRatesList": "Exchange rates list",
        "pay": "Pay",
        "internalTransfer": "Internal transfer",
        "noEinvoices": "No eInvoice data",
        "noTemplates": "No template data",
        "forCurrencyConversion": "for currency conversion",
        "midrate": "midrate",
        "forForeignNotes": "for foreign notes, cheques",
        "onDate": "on ",
        "buy": "Buy",
        "goBackToPayments": "Go back to payments",
        "currencyName": "Currency name",
        "code": "Code",
        "unit": "Unit",
        "buyRate": "Buy",
        "middleRate": "Middle",
        "sellRate": "Sell",
        "action": "Action",
        "noRejectedPayments": "No rejected payments for selected period",
        "noPendingPayments": "No pending payments for selected period",
        "donationToCharity": "Donation to charity",
        "subscribe": "Subscribe",
        "settings": "Settings",
        "moreRejected": "More rejected payments",
        "morePending": "More pending payments",
        "currencyExchange": "Currency conversion",
        "exchange": "Exchange",
        "fromAccount": "From account",
        "toAccount": "To account",
        "willSell": "I will sell",
        "willBuy": "I will buy",
        "available": "Available",
        "delete": "Delete",
        "edit": "Edit",
        "loanPayment": "Loan payment",
        "toAcc": "to account",
        "paymentMode": "Payment mode",
        "fromAcc": "from account",
        "bicNumber": "SWIFT Number",
        "bicNumber_SLO": "SWIFT code",
        "bicBankName": "Bank name",
        "bicAddress": "Bank Address / SWIFT Code",
        "bicCity": "Bank City",
        "bicCountry": "Bank Country",
        "bicCountry_SLO": "Recipient's bank country code",
        "bicRecipientStatus": "Recipient (person)",
        "bicRecipientStatusOptionLegal": "Legal",
        "bicRecipientStatusOptionPhysical": "Physical",
        "bicBankCharges": "Charge option",
        "bicNumberRequired": "BIC number is required!",
        "bicNumberMaxLength": "BIC number must have 8 or 11 digits!",
        "receiversPostalCodeAndCityInputRequired": "Recipient city is required!",
        "bicBankNameRequired": "Bank name is required!",
        "bicCityRequired": "Bank city is required",
        "bicCountryRequired": "Country is required!",
        "bankAddressInputRequired": "Bank address is required!",
        "creditRefNumberRequired": "Ref. credit number is required!",
        "creditYearRequired": "Credit year is required!",
        "resend": "Resend",
        "minimalAccountNumber": "Account is invalid!",
        "cancel": "Cancel",
        "templateSavedSuccessfully": "Template saved successfully!",
        "templateUpdatedSuccessfully": "Template updated successfully !",
        "paymentDueDateChangedMsg": "Payment due date has been changed according to Timetable of payment services. Please, confirm.",
        "dueDateChangedMsg1": "Payment due date has been changed",
        "dueDateChangedMsg2": "Please, confirm.",
        "cancelledPaymentMsg": 'The payment was cancelled and will not be executed. The cancelled payment will remain in the List of payments/Realized. You can resend it to the Bank for processing by clicking the "Execute" button.'
      },
      paymentsOverview: {
        "custom": "Custom",
        "notSupportedPaymentMsg": "Payment with the selected template is not possible anymore.",
        "pohReciver": " to ",
        "pohAmount": " in amount of ",
        "pohDate": " on ",
        "povlStatus": "Status",
        "povlNote": "Note",
        "povlNote_SLO": "Rejection reason",
        "povlId": "Payment id",
        "povlReceiver": "Recipient name",
        "povlFrom": "From account",
        "povlTo": "To account",
        "povlAmount": "Amount",
        "povlAmountSlo": "Buying amount",
        "povlDueDate": "Date of execution",
        "povlValueDate": "Value date",
        "povlUrgency": "Urgency",
        "povlUrgencyYes": "Yes",
        "povlUrgencyNo": "No",
        "filterStatus": "Filter by status",
        "loadMoreBtn": "Load more",
        "referenceTo": "Reference to",
        "titleLabel": "List of payments",
        "signatoryName": "Signatory",
        "signDate": "Date of action",
        "recallerName": "Recaller",
        "recepientInfo": "Recipient information",
        "orderPaymentConfirmation": "Order payment confirmation",
        "receiveConfirmation": "I would like to receive the confirmation to the following address",
        "orderComment": "Order comment",
        "orderPaymentConfMsg1": "I allow the Bank to debit my account for the costs of producing and sending the Payment confirmation in accordance with the current",
        "orderPaymentConfMsg2": "price list of Addiko Bank d.d.",
        "payerAccNumber": "Payer account number",
        "recAccNumber": "Receiver account number",
        "uniMandateRef": "Unique mandate reference",
        "dueDate": "Due date",
        "creditorInd": "Creditor identifier",
        "recReference": "Receiver reference",
        "messageAboutError": "Rejection reason",
        "minLength5": "Minimum length is 5!",
        "orderSuccessAlert": "Order was successfully submitted.",
        "orderConfirmationBtn": "Order confirmation",
        "reclamationSuccess": "Payment complaint was succesfully submitted.",
        "paymentsType": {
          "all": "All",
          "opened": "Opened",
          "partiallysigned": "Partially signed",
          "cancelled": "Cancelled",
          "processed": "Processed",
          "processed2": "Processed",
          "rejected": "Rejected",
          "closed": "Closed",
          "signed": "Signed",
          "notSigned": "Opened",
          "cancellations": "Reimbursements",
          "inprocessing": "In processing",
          "forcancelling": "For cancelling",
          "storned": "Storned",
          "rejectedaftercomplaint": "Rejected after complaint",
          "acceptedaftercomplaint": "Accepted after complaint",
          "allTab": "All",
          "realizedTab": "Realized payments",
          "pendingTab": "Pending payments",
          "rejectedTab": "Rejected payments",
          "waitingQueque": "Waiting queue"
        },
        "status": {
          "Opened": "Opened",
          "Rejected": "Rejected",
          "WaitingQueue": "WaitingQueue",
          "Processed": "Processed"
        },
        dateRange: "Filter by date range",
        "dateFrom": "Date from",
        "dateTo": "Date to",
        "filterByPurpose": "Search by purpose",
        "noPaymentData": "No payment data for selected filter.",
        "selectAll": "Select all",
        "selectNone": "Select none",
        "signSelectedPayments": "Sign selected payments",
        "referenceNumber": "Reference number",
        "referenceNumberPayment": "Reference number - Payment",
        "referenceNumberCancelations": "Reference number - Cancelation",
        "cancelled": "Cancelled",
        "processed": "Succesfully processed",
        "rejected": "Rejected",
        "open": "Open",
        "inProcessing": "In process",
        "signed": "Signed",
        "waitingQueque": "Waiting to be processed",
        "Cancelled": "Cancelled",
        "Processed": "Succesfully processed",
        "Rejected": "Rejected",
        "Opened": "Open",
        "InProcessing": "In process",
        "Processed2": "Processed",
        "Signed": "Signed",
        "WaitingQueue": "Waiting to be processed",
        "totalPendingSum": "Total pending:",
        "ipsPopoverText": "Instant payment",
        "MCC": "MCC",
        "POSReference": "Referenca transakcije na prodajnom mjestu",
        "POSIPS": "POS_IPS"
      },
      domesticPayment: {
        "euroCalculator": "Euro calculator",
        "hsvpWarning": "It is not possible to conduct the urgent payment via TARGET2 today. If you continue, your payment order will be excecuted via TARGET2 the next working day and the fee for urgent transaction will be charged. If you want to abort the TARGET2 and place the regular payment order, please go back to the payment order and cancel the option 'Urgent'. Do you want to continue with the urgent payment via TARGET2?",
        "header": "Domestic payment",
        "headerSl": "Universal payment order",
        "fromAccountSelectLabel": "From account",
        "availableAmountLabel": "Available",
        "purposeCodeSelectLabel": "Purpose code",
        "purposeModalInfoText": "Please enter precise information in the field Purpose of payment.",
        "purposeOfPaymentInputLabel": "Purpose of payment",
        "purposeOfPaymentInputLabelCRO": "Purpose of payment",
        "toPayee": "To recipient",
        "amountInputLabel": "Amount",
        "dateInputLabel": "Date",
        "bicCodeInputLabel": "BIC code",
        "toAccountInputLabel": "IBAN of recipient",
        "toAccountInputLabelSLO": "IBAN of recipient",
        "toAccountInputLabelSRB": "To account",
        "toAccountInputBtn": "Fill recipient",
        "referenceNumberLabel": "Reference number (approval)",
        "referenceNumberLabelV2": "Reference number (debt)",
        "referenceNumberLabelCRO": "Reference number (approval)",
        "referenceModelLabel": "Model",
        "receiversNameInputLabel": "Recipient's name",
        "receiversAddressInputLabel": "Recipient's address",
        "receiversPostalCodeAndCityInputLabel": "Recipient's postal code and city",
        "receiversPostalCodeAndCityInputLabelSlo": "Recipient's city",
        "receiversCountrySelectLabel": "Recipient's country",
        "payersNameInputLabel": "Payer's name",
        "payersAddressInputLabel": "Payer's address",
        "payersCityInputLabel": "Payer's city",
        "amountPlaceholder": "eg. 1,000.00",
        "reviewAndSubmitBtn": "Review and submit",
        "selectUrgentLabel": "Check for urgent payment through TARGET2 which is charged extra by the current Bank's Tariff",
        "selectUrgentLabelSLO": "Urgent",
        "selectInstantLabelSLO": "Instant payments",
        "selectInstantLabel": "Instant payment",
        "instantDateError": "If payment is tagged as instant, only today's date is allowed as payment date.",
        "selectUrgentLabelSRB": "Select if this is an urgent payment",
        "urgentPaymentInfo": "Urgent payments are charged by the current Bank's Tarriff.",
        "instantMsg": "Make Payment any day (0-24), in seconds, at the cost of regular payment! Valid for payments up to 100,000 to registered banks in the SIP schemes register. The service is charged according to the Decision of Fees.",
        "urgentMsg": "Urgent payment to any bank din the Croatia according to the bank's timetable. Note: The price of the service is 1,33 EUR! The service is charged according to the Decision on Fees.",
        "processingFeeLabel": "Processing fee",
        "receiverAccountError": "Recipient account is required!",
        "receiverAccountErrorHR": "IBAN No. / Account No. of Recipient is required!",
        "receiverNameError": "Recipient name is required!",
        "receiverCityError": "Recipient city is required!",
        "receiverAddressError": "Recipient address is required!",
        "receiversPostalCodeAndCityInputError": "Recipient city is required!",
        "sepaDebitorLabel": "Real debtor",
        "sepaReceiverLabel": "End beneficiary",
        "confirmBtn": "Confirm",
        "selectFromTemplate": "Select from template",
        "listOfTemplates": "List of templates",
        "bankCharges": "Expected fee",
        "urgentPayment": "This payment is urgent",
        "instantPayment": "This payment is instant",
        "checkBankCharges": "Check the bank charges",
        "referenceModelInputError": "Reference number is required!",
        "referenceModelInputRFError": '"RF" reference is not specified.',
        "referenceModelInputSIError": '"SI" reference should not be left empty.',
        "referenceModelInputInvalid": "Reference number is invalid!",
        "toPayeeInputError": "Recipient is required",
        "toPayee2": "Recipient (company/name and address)",
        "newPayment": "New payment",
        "delete": "Delete",
        "edit": "Edit",
        "sign": "Sign",
        "ibanStructError": " IBAN structure is incorrect.",
        "SEPAcodesLink": "Explanation of SEPA codes",
        "timetableForProvisionLink": "Timetable for the provision of payment services",
        "selectHumanitarianOrganisationLabel": "Select humanitarian organisation (make donation without fee)",
        "listOfOrganisations": "List of organisations",
        "taxPayerNumber": "Taxpayer number",
        "paymentType": "Payment type",
        "revenueCode": "Revenue type",
        "municipality": "Municipality",
        "taxPeriodFrom": "Tax period from",
        "taxPeriodTo": "Tax period to",
        "budgetOrganisation": "Budget organisation",
        "taxPayerError": "Taxpayer is required!",
        "revenueCodeError": "Revenue code is required!",
        "budgetOrganizationError": "Budget organization is required!",
        "municipalityError": "Municipality is required!",
        "municipalityExistanceError": "Municipality doesn't exist!",
        "taxPeriodRangeError": "Tax period range is not correct!",
        "revenueCodeTypeError": "Revenue code type don't match to account type!",
        "revenueCodeExistanceError": "Revenue code type doesn't exist!",
        "receiverAccountLengthError": "Receiver account must have 8 or 16 digits!",
        "domesticReceiverAccountLengthError": "Receiver account must have 16 digits! ",
        "receiverAccountModuleError": "Receiver account invalid!",
        "referenceNumberRequired": "Reference number is required for public revenue!",
        "referenceNumberError": "Reference number is not valid!",
        "referenceNumberMaxLength": "Reference number to long!",
        "bicBankPrejemnika": "Recipient's bank BIC",
        "sepaRegister": "SEPA register",
        "nonWorkingDay": "Choosen date is a non working day! Please select another date.",
        "confirmBtnSlo": "Next",
        "back": "Back",
        "toAccountMinlength19": "IBAN number must have 19 characters !",
        changePaymentModal: {
          "yes": "Yes",
          "no": "No",
          "confirm": "Confirm",
          "cancel": "Cancel",
          "close": "Close",
          "ok": "Ok"
        },
        "accountEqualityError": "Accounts must be different!",
        "paymentPurposeLengthError": "Excessive length",
        "urgentInfo": "You marked the order as urgent. The cost of an urgent payment is higher and priced according to Addiko Bank d.d. pricelist of services. Please check if the order has to be executed as urgent.",
        "secondNotInSEPAMessage": "Would you like to open an international payment?"
      },
      instantPayment: {
        "header": "Povraćaj",
        "purposeCodeSelectLabel": "Cancellation code",
        "purposeOfPaymentInputLabel": "Purpose of cancellation",
        "signTitle": "Sign cancellation",
        "finalStepSuccessfullySigned": "Reimbursement request successfully submitted.",
        "orderSent": "Request was sent to bank for processing.",
        "buttons": {
          "cancellation": "Reimburse",
          "edit": "Edit Reimbursement",
          "delete": "Delete Reimbursement",
          "sign": "Sign Reimbursement"
        },
        "reimbursementHeader": "Reimbursement",
        "reimbursementCode": "Reimbursement code",
        "reimbursementReason": "Reimbursement reason",
        "reimbursementSignHeader": "Sign Reimbursement",
        "finalStepPaymentID": "Request number: ",
        "statuses": {
          "InProcessing": "InProcessing",
          "Signed": "Signed",
          "WaitingQueue": "Waiting",
          "Opened": "Opened",
          "Processed": "Processed"
        },
        "modelAndReference": "Model and reference number"
      },
      internationalPayment: {
        "guide": "Guide for international payments",
        "hasAvailableBalanceWarning": "The payment amount is greater than the balance remaining in your account",
        "totalAmountWithFee": "Total amount with fee",
        "maxlength34": "Maximum 34 characters are allowed.",
        international: "International payment",
        "email": "Email",
        "phone": "Phone number",
        "fromAccount": "From account",
        "chargeAccount": "Charge account",
        "totalAmount": "Total amount and currency",
        "ibanSwiftNotMatchError": "Country of IBAN and SWIFT doesn't match.",
        "swiftCharectersCount": "Field SWIFT/BIC can only have 8 or 11 characters!",
        "paymentDate": "Payment date",
        "paymentPurpose": "Purpose of payment",
        "paymentPurposeReceiverInfo": "Purpose of payment - Info for receiver",
        "purposeCode": "Purpose code",
        "recipient": "Recipient",
        "recipientStatus": "Recipient status:",
        "companyName": "Name and surname of full name of the company",
        "address": "Address",
        "city": "City",
        "country": "Country",
        "recipientAccount": "Recipient's account",
        "recipientBank": "Recipient's bank",
        "bankName": "Bank name",
        "swiftCode": "SWIFT Code",
        "bankCharges": "Expected fee",
        "sharedCharges": "Shared charges",
        "payersPaysCharges": "Payers pays charges",
        "beneficiaryPaysCharges": "Recipient pays charges",
        "naturalPerson": "Private",
        "legalPerson": "Legal",
        "tooltipInfo": "Need help?",
        "fillData": "Fill bank info",
        "reviewandSubmit": "Review and submit",
        "companyNameInputError": "Name and surname are required!",
        "recipientAddressInputError": "Recipient address is required!",
        "recipientCityInputError": "Recipient city is required!",
        "recipientAccountInputError": "Recipient account is required!",
        "bankNameInputError": "Bank name is required!",
        "bankAddressInputError": "Bank address is required!",
        "bankCityInputError": "Bank city is required!",
        "swiftCodeInputError": "SWIFT code is required!",
        "payersNameInputLabel": "Payer's name",
        "payersAddressInputLabel": "Payer's address",
        "payersCityInputLabel": "Payer's city",
        "selectUrgentLabel": "Enable for urgent payment through TARGET2 which is charged extra by the current Bank's Tariff",
        "sepaDebitorLabel": "Real debtor",
        "sepaReceiverLabel": "End beneficiary",
        "paymentMode": "Payment mode",
        "toPayee2": "Recipient (company/name and address)",
        "toAccountInputLabel": "IBAN No. / Account No. of Recipient",
        "toAccountInputLabel_SLO": "Recipient's account (IBAN)",
        "bicBankName": "Foreign Bank",
        "noBicBankInfo": "Note: For payment transactions addressed to recipients in the EU payment to the account that does not have the structure of the " +
        "IBAN and the payment transaction to the account of the unknown BIC code of the recipient bank will be charged " +
        "according to the Tariff of the bank for international and cross-border transactions.",
        "chooseCountry": "Choose a country",
        "paymentChargesAmount": "Payment charges amount",
        "creditRefNumber": "Ref. credit number",
        "creditYear": "Credit year",
        "bicBankCountry": "Beneficiary bank country",

        "SLOconversionChargeAmount": "Estimated total amount in EUR",
        "SLOamountWithCharges": "Currency conversion fee",
        "SLOconversionRate": "Estimated exchange rate",
        "SLOpaymentAmount": "Estimated amount transferred to the payee",
        "SLOpaymentDisclaimer": "The estimated total amount in domestic currency includes fees charged for the transaction and is for information purposes only. The bank shall execute the conversion in accordance with the Terms and conditions for managing transaction accounts and provision of payment services, considering the fund balance on your transaction account and the bank's relevant exchange rate on the payment value date."

      },
      internalTransfer: {
        "transferToAccount": "Transfer to my account",
        "transferToAccountLink": " Internal payment order",
        "fromAccount": "From account (IBAN)",
        "toAccount": "To account (IBAN)",
        "dueDate": "Due date",
        "valueDate": "Value date",
        "amount": "Amount",
        "sign": "Sign",
        "saveAsTemplate": "Save payment as template",
        "templateName": "Template name",
        "newPayment": "Make a new payment",
        "toPaymentOverview": "Go to payment overview",
        "signSuccess": "Payment is successfully signed.",
        "toAccountInputError": "Receiver account is requred!",
        "paymentPurposeInputError": "Purpose of payment is required!",
        "amountInputError": "Amount is required!",
        "dueDateError": "Due date is required!",
        "templateNameError": "Name is required!",
        "when": "Date",
        "transferComplete": "Transfer complete",
        "makeAnotherPayment": "Make another payment",
        "successfullyTransfer": "You have succesfully transfered",
        "amountLengthError": "Max amount is 99,999,999,999.99",
        "amountLengthErrorBh": "Maximum number of digits is 9.",
        "nodata": "No availabe data",
        "infoMne0": "Note for payments on Revolving and Mastercard:",
        "infoMne1": "Payments made before 15:30h will be booked and funds available on account during next working day.",
        "infoMne2": "Payments made after 15:30h will be booked and funds available on account during next two working days.",
        "minAmount10Error": "Minimal allowed amount is ",
        "emailError": "Email is required!",
        "phoneError": "Phone number is required!",
        "purposeError": "Purpose of payment is required!",
        "chargesError": "Charge option is required!",
        "purposeCodeInputError": "Purpose code is required!",
        "toAccountNoData": 'Transfer is not possible with just one account in the EBank. For currency exchange select "Exchange office" in the "Payments" menu.'
      },
      paymentSignModal: {
        title: "Sign payment",
        challengeResponse: "Enter challenge response",
        ok: "Ok"
      },
      paymentDocumentation: {
        title: 'Upload the accompanied documents',
        user: 'Dear customer,',
        info: ' the accompanied documents with an international payment order can be transferred to server and sent to the Bank by this functionality. The Bank will review the documents in order to speed up the allowance of execution of your international payment order.',
        list1: 'Allowed document types are PDF, PNG and IMAGE (jpg, jpeg).',
        list2: 'In order to allow signing the order, it is necessary to upload at least one document.',
        list3: 'If the Bank, while checking the documents, finds out that additional documents are needed, you will be advised by message.',
        uploadText: 'Tap to upload document or drag and drop files here',
        confirmText: 'Confirm',
        cancelText: 'Cancel',
        downloadText: 'Download',
        removeText: 'Remove',
        numberOfRowsPerPage: 'Number of rows per page:',
        pageNumber: 'Page number:',
        numberDocuments: 'Total number of loaded documents with the order is:',
        lastUpdated: 'Last updated:'
      },
      loanPayment: {
        "loanPayment": "Loan payment",
        "recieverNumber": "Reference number",
        "purpose": "Purpose",
        "when": "Date",
        "exchangeRate": "Exchange rate",
        "referenceNumber": "Reference number",
        "defaultPurpose": "Loan Repayment"
      },
      currencyConversion: {
        "exchangeOffice": "Exchange office",
        "exchangeRatesCalculator": "Exchange rates and calculator",
        "sellingAmount": "Selling",
        "buyingAmount": "Buying",
        "availableBalance": "Available balance",
        "fromAccount": "From acount",
        "toAccount": "To account",
        "amount": "Amount",
        "amountTo": "Amount to",
        "newCurrencyWillCreate": "The new currency will be created.",
        "sign": "Sign",
        "confirm": "Confirm",
        "back": "New payment",
        "smsAuthentification": "Payment SMS authentification",
        "sendSms": "Send sms code",
        "smsPassword": "Confirmation code",
        "moneyExchangeComplete": "Money exchange complete",
        "successfullyExchange": "You have succesfully exchange",
        "makeAnotherExchange": "Make another exchange",
        "goToPayments": "Go to payment overview",
        "exchangeRateRatio": "Exchange rate",
        "exchangeOrder": "Your exchange order",
        "signPayment": "Sign payment",
        "deletePayment": "Delete payment",
        "recallPayment": "Recall payment",
        "balance": "Balance",
        "exchangeRate": "Exchange rates",
        "bankCharges": "Expected fee",
        "when": "Date",
        "buying": "Buying amount",
        "selling": "Selling amount",
        "calculatedAmount": "Calculated amount",
        "agreedRate": "Agreed rate",
        "provision": "Provision",
        "bankCurrencyInfoMsg11": "Bank carries out currency conversion according to the exchange rates of Addiko Bank d.d. for natural persons at the time of purchase or sale, regardless of the current exchange rate at the time of the order. Consequently, actual value of converted amount could be different than the value calculated in the order. You can view the applicable Addiko Bank d.d. foreign currency exchange rates at the",
        "bankCurrencyInfoMsg12": " bank's website.",
        "provisionToolTip1":"Informacije vezane uz",
        "provisionToolTip2": "iznos naknade",
        "provisionToolTip3": "tečaj za naknadu",
        "provisionToolTip4": "iznos terećenja računa za naknadu",
        "provisionToolTip5": "Procijenjeni su iznosi koji se mogu razlikovati ovisno o datumu izvršenja transakcije te posebnim pogodnostima odobrenim od strane banke. Točan iznos bit će obračunat na dan izvršenja transakcije."
      },
      paymentsAndTransferOverview: {
        rejectedLabel: "Last rejected payments",
        eInvoicesLabel: "Unpaid eInvoices",
        groupedPaymentsLabel: "Group of payments to sign",
        repeatAction: "Repeat",
        payAction: "Pay",
        signAllAction: "Sign all",
        domPayToSign: "Universal payment orders to sign",
        paymentsToSign: "payments to sign",
        "confirm": "Confirm"

      },
      ebook: {
        "ebookPayIn": "E-Book pay in",
        "ebookPayOut": "E-Book pay out"
      },
      savings: {
        "savingsPayIn": "Foreign currency transfer"
      },
      signPayments: {
        "paymentsInvalidDueDate": "Some of the payments have old due date. Do you want to update payments to current date?",
        "singlePaymentInvalidDueDate": "Selected payment has old due date. Do you want to update payment to current date?",
        "cancelationsInvalidDueDate": "Payment Cancelation has old due date. Do you want to update payment to current date?",
        "updatePaymentsDueDate": "Update payments due date",
        "updatePaymentDueDate": "Update payment due date",
        "dateCreated": "Date created",
        "totalAmount": "Total amount",
        "dueDate": "Due date",
        "confirm": "Confirm",
        "challengeResponse": "Challenge response",
        "challenge": "Challenge",
        "challengeFirstCode": "Password 1 (insert in token)",
        "challengeSecondCodeQR": "Password 2 (insert in token)",
        "challengeSecondCode": "Password 2 (from token)",
        "challengeSecondCodeBh": "Password (from token)",
        "challengeThirdCode": "Password 3 (from token)",

        "otpLabel": "OTP",
        "challengeDescription": "Turn your token on, enter the PIN and after that (CHAL is written at the top of the screen) enter the number which you can read on HYPOnet 'Password 1' screen and click on the 'OK' button. Token will calculate and display the Password 2 which you should copy to the 'Password 2' field. After you have entered both numbers select the 'CONFIRM' button.",
        "challengeDescriptionQR": "'U mBank aplikaciji za fizičke osobe odaberite mToken/Potpiši nalog za plaćanje funkcionalnost. Unesite Zaporku 1 (Iznos) i Zaporku 2 (Račun primatelja) u za to predviđena polja na ekranu mBank aplikacije ili navedene vrijednosti učitajte putem QR Code-a. Kada su obje vrijednosti unesene odaberite gumb 'Izračunaj Zaporku 3'. Zaporku 3 unesite u za to predviđeno polje u EBank aplikaciji te odaberite gumb 'Potvrdi.'",
        "finalStepSuccessfullySigned": "Payment successfully submitted.",
        "finalStepPaymentServices": "The charge for payment services will be calculated in accordance with the official ",
        "finalStepBankTariff": "bank tariff.",
        "finalStepStatus": "Status: ",
        "finalStepPaymentID": "Payment number: ",
        "finalStepNumberOfPayments": "Number of payments: ",
        "finalStepSentForProcessing": "Payment was sent to bank for processing.",
        "finalStepPaymentSloHeader": "Payment has been successfully submitted.",
        "finalStepPaymentRejectedSloHeader": "Payment was rejected.",
        "finalStepPaymentSloSubHeader": "You can check if the payment was processed on the ",
        "finalStepPaymentRejectedSloSubHeader": "",
        "finalStepPaymentSloText": "If execution date of your payment is later than the current date, the payment has been placed under Pending payments. On the day of payment change or cancellation of payment is not possible.",
        "finalStepPaymentRejectedSloText": "You can check rejected payment on the ",
        "cancel": "Cancel",
        "resendSms": "Resend SMS",
        "resendSmsSlo": "Resend SMS (valid for SMS code only)",
        "SmsCode": "Confirmation code",
        "SmsDescription": "Confirmation SMS was sent to your device. Enter the code from SMS into 'SMS code' field. After you have entered number select the 'CONFIRM' button.",
        "SmsDescriptionSLO": "Please enter your confirmation code, which you received with SMS or generated it with your token. Selected way of confirmation of transactions can be checked in Settings.",
        "SmsDescriptionBh": "Confirmation SMS was sent to your device. Enter the code from SMS into 'SMS code' field. After you have entered number select the 'CONFIRM' button. 'SMS code' is valid for 90 seconds.",
        "OtpDescriptionBh": "Confirm sign by entering OTP number from mini token.",
        "SmsSentSuccessfully": "SMS sent successfully!",
        "paymentExecutionDateTitle": "Change currency date",
        "paymentExecutionDateBody": "Todays processing of payment orders is finished. Your payment order will be executed on {{date}} - according to time schedule of bank. Do you want to continue with signing of entered payment order?",
        "infoDomMne0": "Your payment will be processed in accordance with defined time frame ",
        "infoDomMne1": "(Time schedule).",
        "infoDomSrb0": "Your payment will be processed in accordance with defined time frame ",
        "infoDomSrb1": "(Time schedule).",
        "infoDomSrbLink": "",
        "infoDomMneLink": "https://www.addiko.me/sites/addiko.me/files/content/terminski_plan_obrade_naloga.pdf",
        "cancelationSignMessage": "Prescribed legal deadline for reimbursement is ten days and therefore this Request has expired. Do you wish to delete this Request?"
      },
      paymentActions: {
        "repeat": "Repeat payment",
        "copy": "Copy payment",
        "documentation": "Documentation",
        "delete": "Delete payment",
        "printSlo": "Payment Confirmation",
        "print": "Print confirmation",
        "recall": "Recall payment",
        "checkRecallStatus": "Check for Recall status",
        "cancelPayment": "Cancel payment",
        "sign": "Sign payment",
        "signCancelation": "Sign cancelation",
        "cancel": "Cancel",
        "rejectRecall": "Reject recall",
        "approveRecall": "Approve recall",
        "edit": "Edit payment",
        "complaint": "Complaint",
        "confirmation": "Order confirmation",
        "execute": "Execute",
        "changePayment": "Change payment",
        "instant": "Cancelation"
      },
      statementConfirmModalTranslations: {
        title: "Statement",
        p1: "In accordance with the Law on international measures of limiting the execution of orders in the Bank, it is necessary to fill out this Statement. If the Statement is not filled out the order will not be executed.",
        p2: "Under criminal and material liability I am stating that the reason for payment, of the order in amount of ",
        p2_2: " for country",
        p2_0: "is the following one",
        p2_1: "* detailed explanation (specification)",
        p4: "Statement is created in electronic form and it is valid without the signature or stamp.",
        radioMerchandise: "Merchandise",
        radioPresent: "Present",
        radioOther: "Other*",
        confirmButton: "Confirm",
        cancelButton: "Cancel"
      },
      "currencyCalculator": {
        "exchangeCalculator": "Exchange calculator",
        "exchangeRates": "Exchange rates",
        "msg1": "The currency rate list is applied for purchase and selling of foreign currencies to maximum amount of 10,000.00 EUR.",
        "msg2": " For higher amounts please contact the Treasury Sales department in Addiko Bank phone " +
                "nr.: 01 4898 178.",
        "msg3": "All currencies quoted against the euro (base currency).",
        "updated": "Updated"
      },
      paymentsReturn: {
        openedTab: "Opened",
        archivedTab: "Archived",
        titleLabel: "Payments return confirmation",
        modalTitle: "Choose recall reason",
        recallReason: "Recall reason",
        additionalInfo: "Additional info",
        checkAdditionalInfo: "Remove additional info",
        additionalInfoError: "This field is required",
        reasonTech: "Technical problems resulting in erroneous credit transfer",
        reasonFrad: "Fraudulently originated credit transfer",
        reasonDupl: "Duplicate sending",
        reasonAM09: "Wrong amount",
        reasonAC03: "Invalid Creditor IBAN",
        reasonCUST: "Other reasons",
        reasonAC03EN: "Please provide additional information about beneficiary in case of negative response to the recall request",
        reasonAC03HR: "Molimo dodatne podatke o primatelju u slučaju negativnog odgovora na zahtjev povrata"
      },
      paymentsFinal: {
        "InProcessing": "InProcessing",
        "Signed": "Signed",
        "NotSigned": "Opened",
        "WaitingQueue": "Waiting",
        "Opened": "Opened",
        "Processed": "Processed",
        "Rejected": "Rejected",
      },
      paymentsTypeGroup: {
        "CurrencyExchange": "Currency Exchange",
        "LocalCurrencyTransfer": "Local Currency Transfer",
        "DomesticPayment": "Universal Payment Order"
      },
      sepa: {
        "header": "SEPA Direct Debit Settings",
        "addNew": "Add a new setting"
      }

    };

    $translateProvider
      .translations('en', data);
  }
}());


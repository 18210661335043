(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name eInvoices.factory:EInvoiceDocumentsService
   *
   * @description
   *
   */
  angular
    .module('eInvoices')
    .factory('EInvoiceDocumentsService', EInvoiceDocumentsService);

  function EInvoiceDocumentsService(EndpointsService, $q, $http, $log, $uibModal, AlertService, HelperService) {
    var EInvoiceDocumentsServiceBase = {};

    EInvoiceDocumentsServiceBase.instantCategoryByIbanCheck = function (iban) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          $http.get(response.links['banks_instantCategoryByIbanCheck?iban={iban}'].replace('{iban}', iban))
              .then(function (data) {
                  deferred.resolve(data.data);
              }).catch(function (msg, code) {
                  deferred.reject(msg.data);
              });
      }, function (error) {
          deferred.reject(error.data);
      });
      return deferred.promise;
  };

    EInvoiceDocumentsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      //if(HelperService.isEndpointAvailable('eInvoiceDocuments')) {
        EndpointsService.getLinks().then(function (response) {
          if(response.links.eInvoiceDocuments){
            $http.get(response.links.eInvoiceDocuments, {
                params: params
              })
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          } else {
            deferred.resolve(null);
          }
        }, function (error) {
          deferred.reject(error.data);
        });
      //};
      return deferred.promise;
    };

    EInvoiceDocumentsServiceBase.openModal = function (eInvoice) {
      var modalInstance = $uibModal.open({
        templateUrl: 'e-invoices/e-invoices-modal.tpl.html',
        controller: 'EInvoicesModalCtrl',
        controllerAs: 'eInvoicesModal',
        resolve: {
          eInvoice: function () {
            return eInvoice;
          }
        },
        backdrop: 'static'
      });
    };

    EInvoiceDocumentsServiceBase.getById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eInvoiceDocuments_id){
          $http.get(response.links.eInvoiceDocuments_id.replace('{id}', id), {params: params})
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EInvoiceDocumentsServiceBase.getEinvoicesPageByFilter = function (params) {
      return EInvoiceDocumentsServiceBase.getPage(params);
    };

    EInvoiceDocumentsServiceBase.getEinvoiceForPaymentById = function (einvoiceId) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.payments_forEInvoice_id.replace('{id}', einvoiceId))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EInvoiceDocumentsServiceBase.archiveEInvoice = function (id, comment) {
      var deferred = $q.defer();
      var params = {
        archiveEInvoicePayment: true,
        userComment: comment
      };
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eInvoiceDocuments_id){
          $http.put(response.links.eInvoiceDocuments_id.replace('{id}', id), params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
        } else {
          deferred.resolve(null);
        }
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    EInvoiceDocumentsServiceBase.downloadLink = function (id) {
      var nonBlockingWindow = window.open('', '_self');
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if(response.links.eInvoiceDocuments_downloadLink_id){
          $http.get(response.links.eInvoiceDocuments_downloadLink_id.replace('{id}', id))
            .then(function (data) {
              data = data.data;
              if(data && data.url) {
                // flagToMarkDownload
                sessionStorage.setItem('downloadInProgress', 'download');
                nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(data.url);
              }
              deferred.resolve();
            }).catch(function (msg, code) {
              AlertService.infoAlert({
                  text: 'eInvoiceDetail.noAttachments'
                });
            nonBlockingWindow.close();
            deferred.reject();
          });
        } else {
          nonBlockingWindow.close();
          deferred.resolve(null);
        }
      }, function (error) {
        nonBlockingWindow.close();
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    return EInvoiceDocumentsServiceBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sepa.controller:SepaNewCroCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaNewCroCtrl', SepaNewCroCtrl);

  function SepaNewCroCtrl($q, $state, AccountsService, $scope,  AlertService, SepaService, HelperService, NotificationService) {
    var vm = this;
    var canUpdate;
    vm.pageLoaded = false;
    vm.step = 'start';

    var tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));

    vm.model = {
      fromAccountSelect: {},
      sepaSettingType: "",
      dateFrom: tomorrow,  // polje „Datum od“ je obavezno polje, minimalni datum koji je moguće unijeti je tekući datum +1
      dateTo: '', //ostaviti ovo polje prazno po defaultu takodjer je moguce da se u POST-u salje prazno
      receiverId: "", // identifikator primatelja
      receiverName: "", // naziv primatelja
      identifierApproval: "", // identifikator suglasnosti (API agreementId ) - polje „Identifikator suglasnosti“ je opcionalno polje
      singleChargeMaxAmount: "", // maksimalni iznos pojedinačnog terećenja
      dynamicExecution: "" // dinamika izvršenja (API executionPeriod )
    };

    vm.responseModel = {};

    init();
    function init() {
      loadData();
    }

    function getSepaSettingsTypeByAccountId(id) {
      SepaService.getSepaSettingsTypesByAccountId(id)
        .then(function (response) {
          var sepaSettingsTypes = response.result;
          vm.sepaSettingsTypesOptions = sepaSettingsTypes;
          vm.model.sepaSettingType = sepaSettingsTypes[0];
          vm.onSettingTypeChange(sepaSettingsTypes[0]);
        }, function (error) {

        })
    }

    function loadData() {
      var promises = [];
      promises.push(
        AccountsService.getPage({
          accountTypeGroupList: ["CurrentAccount","GiroAccount"],
          pageSize: 10000,
          includeList: "UserAccountList",
          currencySymbol: "EUR"

        }));
      promises.push(SepaService.getSepaDDSettingsExecutionPeriodTypes());
      promises.push(SepaService.getSepaCreditorsList({pageSize: 10000}));
      $q.all(promises)
        .then(function (response) {
          // --
          // Accounts from
          // --
          var accountsFrom = response[0].result;
          vm.accountsFrom = _.filter(accountsFrom, function (account) {
            return account.isOwn && account.accountNumber.substring(0, 2) != '35';
          });
          vm.model.fromAccountSelect = vm.accountsFrom[0];
          //As default account set one that was selected on previous form (Sepa DD Settings)

          getSepaSettingsTypeByAccountId(vm.model.fromAccountSelect.accountId);

          // --
          // Sepa Execution Period types
          // --
          var executionPeriodTypes = response[1].result;
          executionPeriodTypes.unshift({id: "", description: ""});
          vm.executionPeriodTypesOptions = executionPeriodTypes;
          vm.model.dynamicExecution = executionPeriodTypes[0];

          // --
          // Sepa Creditor List
          // --
          vm.sepaCreditorList = response[2].result;

          // --
          // Other
          // --
          vm.pageLoaded = true;
        }, function (error) {
          NotificationService.showMessage(error, 'error');
        })
    }

    vm.findIdInCreditorList = function (creditorId) {
      var creditor =  _.find(vm.sepaCreditorList, {creditorId: creditorId});
      if (creditor) {
        vm.creditorInListExist = true;
        vm.model.receiverName = creditor.name;
      } else {
        vm.creditorInListExist = false;
        vm.model.receiverName = "";
      }
    }

    vm.datesFromDisabled = function (date, mode) {
      return (mode === 'day' && (date.getTime() < tomorrow));
    };

    vm.datesToDisabled = function (date, mode) {
      return (mode === 'day' && (date.getTime() < vm.model.dateFrom));  // 	polje „Datum do“ je opcionalno polje,  ne smije se unijeti datum manji od onog u polju „Datum od“
    };

    vm.onDateFromChange = function () {
      if (vm.model.dateTo < vm.model.dateFrom) {
        vm.model.dateTo = angular.copy(vm.model.dateFrom);
      }
    }

    vm.setAccountFrom = function (account) {
      vm.model.fromAccountSelect = account;
      vm.fromAccountDropdown = false;
      getSepaSettingsTypeByAccountId(account.accountId)
    }

    vm.onSettingTypeChange = function (sepaSettingType) {
      var sepaSettingTypeId = sepaSettingType.id;
      if (sepaSettingType.descriptionAlert == "") {
        vm.settingTypeInvalid = false;
      } else {
        vm.settingTypeInvalid = true;
      }
    }

    function prepareDataForInsert(input) {
      var data = {};
      data.accountId = input.fromAccountSelect.accountId;
      // data.settingType = input.sepaSettingType;
      data.typeId = input.sepaSettingType.id;
      data.dateFrom = input.dateFrom;
      data.dateTo = input.dateTo || '';

      var sepaSettingTypeId = input.sepaSettingType.id;
      if (sepaSettingTypeId === "WHITE_LIST" || sepaSettingTypeId == "AMOUNT") {
        data.receiverId = input.receiverId;
        data.receiverName = input.receiverName;
        data.maxAmount = input.singleChargeMaxAmount;
        data.agreementId = input.identifierApproval;
        data.executionPeriod = input.dynamicExecution.id;
      }
      else if (sepaSettingTypeId === "BLACK_LIST") {
        data.receiverId = input.receiverId;
        data.receiverName = input.receiverName;
      }
      return data;
    }

    vm.submit = function (form) {
      if (vm.sepaBtnDisabled) {
        return;
      }


      if (form.$invalid || vm.settingTypeInvalid) {
        vm.formSubmitted = true;
        return;
      }
      vm.sepaBtnDisabled = true;
      if (!canUpdate) {
        SepaService.addSepaSetting(prepareDataForInsert(vm.model))
          .then(function (response) {
            vm.step = 'resume';
            canUpdate = true;
            vm.responseModel = response;
            HelperService.initializeMTokenSignature.call(vm, $scope, vm.confirmSign);
            HelperService.loadChallengeResponse(response, vm);
            vm.sepaBtnDisabled = false;
          }, function (error) {
            vm.sepaBtnDisabled = false;
            NotificationService.showMessage(error, 'error');
          })
      } else {
        vm.step = 'resume';

      }
    }

    vm.confirmSign = function () {
      if (vm.confirmSignBtnDisabled) return;
      vm.confirmSignBtnDisabled = true;
      SepaService.signSepaSetting({
        "id": vm.responseModel.id,
        "signData": vm.challengeResponse,
        "additionalSignData": vm.challengeResponse2
      }).then(function (response) {
        vm.signInProgress = false;

        vm.confirmSignBtnDisabled = false;
        AlertService.infoAlert({
          text: 'sepa.signSuccessfull'
        }).then(function () {
          $state.go("sepa.settings-cro");
        });
      }, function (error) {
        vm.signInProgress = false;
        NotificationService.showMessage(error, 'error');
        vm.confirmSignBtnDisabled = false;
      })
    }
  }
}());

(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            payments: {
                "quickPaymentTransfer": "Brzo plaćanje ili transfer",
                "domesticPayments": "Opći nalog",
                "currencyConversion": "Konverzija valuta",
                "internationalPayment": "Devizni nalog",
                "rejectedPayments": "Odbijene uplate",
                "pendingPayments": "Uplate na čekanju",
                "templates": "Predlošci",
                "searchTemplateName": "Traži predložak po nazivu",
                "topUsed": "Najčešće upotrebljeni",
                "alphabetically": "Po abecedi",
                "to": "ka",
                "showMoreTemplates": "Prikaži više predložaka",
                "eInvoices": "eRačuni",
                "showMoreInvoices": "Prikaži sve eRačune",
                "sepaDirectDebit": "SEPA izravno dugovanje",
                "viewAllTransactions": "Pregled svih transakcija",
                "due": "dospio",
                "allPastPayments": "Pregled svih izvršenih plaćanja",
                "exchangeRatesList": "Tečajna lista",
                "pay": "Plati",
                "internalTransfer": "Interni prijenos",
                "noEinvoices": "Nema podataka za eRačune",
                "noTemplates": "Nema podataka za predloške",
                "forCurrencyConversion": "pretvorba valuta",
                "midrate": "srednja stopa",
                "forForeignNotes": "strane bilješke, čekovi",
                "onDate": "dana ",
                "buy": "Kupi",
                "goBackToPayments": "Povratak na plaćanja",
                "currencyName": "Naziv valute",
                "code": "Kod",
                "unit": "Jedinica",
                "buyRate": "Kupovni tečaj",
                "middleRate": "Srednji tečaj",
                "sellRate": "Prodajni tečaj",
                "action": "Akcija",
                "noRejectedPayments": "Za odabrani period nema odbijenih uplata",
                "noPendingPayments": "Za odabrani period nema uplata na čekanju",
                "donationToCharity": "Donacija u dobrotvorne svrhe",
                "subscribe": "Pretplata",
                "settings": "Postavke",
                "moreRejected": "Još odbijenih plaćanja",
                "morePending": "Još plaćanja na čekanju",
                "currencyExchange": "Mjenjačnica",
                "exchange": "Razmjeni",
                "fromAccount": "S računa",
                "toAccount": "Na račun",
                "willSell": "Prodaja",
                "willBuy": "Kupnja",
                "available": "Dostupno",
                "delete": "Izbriši",
                "edit": "Promijeni",
                "loanPayment": "Plaćanje kredita",
                "toAcc": "na račun",
                "paymentMode": "Svrha plaćanja",
                "fromAcc": "s računa",
                "bicNumber": "BIC broj",
                "bicBankName": "Banka primatelja",
                "bicAddress": "BIC adresa",
                "bicCity": "BIC grad",
                "bicCountry": "Država primatelja",
                "bicRecipientStatus": "Primatelj (osoba)",
                "bicRecipientStatusOptionLegal": "Pravna",
                "bicRecipientStatusOptionPhysical": "Fizička",
                "bicBankCharges": "Troškovna opcija",
                "bicNumberRequired": "BIC adresa je obavezna!",
                "bicBankNameRequired": "Banka primatelja je obavezna!",
                "bicCityRequired": "BIC grad je obavezan!",
                "bicCountryRequired": "Država primatelja je obavezna!",
                "resend": "Pošalji ponovo",
                "minimalAccountNumber": "Račun nije ispravan!",
                "cancel": "Odustani",
                "templateSavedSuccessfully": "Uspješno ste kreirali predložak !",
                "templateUpdatedSuccessfully": "Promjena predloška je uspješno  izvršena !",
                "bankAddressInputRequired": "Bank address is required!."

            },
            paymentsOverview: {
                "pohReciver": " za ",
                "pohAmount": " u iznosu od ",
                "pohDate": " na dan ",
                "povlStatus": "Status",
                "povlId": "Id naloga",
                "povlReceiver": "Ime primatelja",
                "povlFrom": "S računa",
                "povlTo": "Na račun",
                "povlAmount": "Iznos",
                "povlDueDate": "Datum naplate",
                "povlValueDate": "Datum valutacije",
                "filterStatus": "Filtriraj po statusu",
                "loadMoreBtn": "Prikaži još naloga",
                "referenceTo": "Poziv na broj",
                "titleLabel": "Pregled naloga",
                "signatoryName": "Potpisao",
                "signDate": "Datum akcije",
                "recallerName": "Opozvao",
                "paymentsType": {
                    "all": "Svi",
                    "opened": "Otvoreni",
                    "partiallysigned": "Djelimično potpisani",
                    "cancelled": "Otkazani",
                    "processed": "Obrađeni",
                    "rejected": "Odbijeni",
                    "closed": "Zatvoreni",
                    "signed": "Potpisani",
                    "notSigned": "Nepotpisani",
                    "inprocessing": "U obradi",
                    "forcancelling": "Za otkazivanje",
                    "storned": "Stornirani",
                    "rejectedaftercomplaint": "Odbijeni nakon žalbe",
                    "acceptedaftercomplaint": "Prihvaćeni nakon žalbe",
                    "allTab": "Svi nalozi",
                    "realizedTab": "Realizirani",
                    "pendingTab": "Nalozi na čekanju",
                    "rejectedTab": "Odbijeni nalozi"
                },
                dateRange: "Filtriraj prema razdoblju",
                "dateFrom": "vrijeme od",
                "dateTo": "vrijeme do",
                "filterByPurpose": "Pretraži po namjeni",
                "noPaymentData": "Za odabrani filter nema naloga.",
                "selectAll": "Označi sve",
                "selectNone": "Odznači sve",
                "signSelectedPayments": "Potpiši odabrana plaćanja",
                "povlNote": "Napomena",
                "referenceNumber": "Referentni broj",
                "cancelled": "Opozvan",
                "processed": "Obrađen",
                "rejected": "Odbijen",
                "open": "Kreiran",
                "inProcessing": "U obradi",
                "waitingQueque": "Na čekanju",
                "signed": "Potpisan",
                "Cancelled": "Opozvan",
                "Processed": "Obrađen",
                "Rejected": "Odbijen",
                "Opened": "Kreiran",
                "InProcessing": "U obradi",
                "WaitingQueue": "Na čekanju",
                "Signed": "Potpisan",
                "MCC": "MCC",
                "POSReference": "Referenca transakcije na prodajnom mjestu",
                "POSIPS": "POS_IPS"
            },
            domesticPayment: {
                "euroCalculator": "Euro kalkulator",
                "hsvpWarning": "Mogućnost hitnog plaćanja putem TARGET2 danas više nije moguća. Ako nastavite, Vaš nalog će se putem TARGET2 izvršiti sljedećeg radnog dana te će se naplatiti naknada za hitna plaćanja. Ako želite odustati od TARGET2 i platni nalog provesti redovnim putem, molimo vratite se na nalog i isključite opciju 'Hitno'. Želite li nastaviti s hitnim plaćanjem putem TARGET2?",
                "header": "Domaći platni nalog",
                "fromAccountSelectLabel": "S računa",
                "availableAmountLabel": "Raspoloživo",
                "purposeCodeSelectLabel": "Šifra namjene",
                "purposeOfPaymentInputLabel": "Svrha plaćanja",
                "purposeOfPaymentInputLabelCRO": "Opis plaćanja",
                "selectInstantLabel": "Instant plaćanje",
                "amountInputLabel": "Iznos",
                "dateInputLabel": "Datum",
                "bicCodeInputLabel": "BIC kod",
                "toAccountInputLabel": "IBAN primatelja",
                "toAccountInputLabelSRB": "Račun primatelja",
                "instantPayment": "This payment is instant",
                "toPayee": "Primatelj",
                "toAccountInputBtn": "Dopuni primatelja",
                "referenceNumberLabel": "Poziv na broj",
                "referenceNumberLabelCRO": "Poziv na broj primatelja",
                "referenceModelLabel": "Reference model",
                "receiversNameInputLabel": "Ime primatelja",
                "receiversAddressInputLabel": "Adresa primatelja",
                "receiversPostalCodeAndCityInputLabel": "Poštanski kod i grad primatelja",
                "receiversCountrySelectLabel": "Zemlja primatelja",
                "payersNameInputLabel": "Ime i prezime pošiljaoca",
                "payersAddressInputLabel": "Adresa pošiljaoca",
                "payersCityInputLabel": "Grad pošiljaoca",
                "amountPlaceholder": "eg. 1.000,00",
                "reviewAndSubmitBtn": "Pregledajte i pošaljite",
                "instantMsg": "Plaćanje 0-24, u sekundama, po cijeni redovnog izvršenja! Vrijedi za plaćanja do 100.000 EUR prema bankama u Registru SIP sheme. Usluga se naplaćuje prema Odluci o naknadama.",
	            "urgentMsg": "Hitno plaćanje u bilo koju banku u RH prema terminskom planu banke. Napomena: Cijena usluge je 1,33 EUR! Usluga se naplaćuje prema Odluci o naknadama.",
                "selectUrgentLabel": "Označite za hitnu uplatu putem TARGET2, koja se dodatno naplaćuje po važećoj Tarifi Banke",
                "urgentPaymentInfo": "Hitna plaćanja se naplaćuju po važećoj Tarifi Banke",
                "processingFeeLabel": "Naknada za obradu naloga",
                "receiverAccountError": "Račun primatelja je obavezan!",
                "receiverAccountErrorHR": "IBAN / Broj računa primatelja je obavezan!",
                "receiverNameError": "Naziv primatelja je obavezan!",
                "receiverCityError": "Grad primatelja je obavezan!!",
                "receiverAddressError": "Adresa primatelja je obavezna!",
                "sepaDebitorLabel": " Stvarni dužnik",
                "sepaReceiverLabel": "Krajnji primatelj",
                "confirmBtn": "Potvrdi",
                "selectFromTemplate": "Odaberi iz predloška",
                "listOfTemplates": "Lista predložaka",
                "bankCharges": "Provizija",
                "urgentPayment": "Ovaj nalog je hitan",
                "checkBankCharges": "Provjeri dodatne naknade",
                "referenceModelInputError": "Poziv na broj je obavezan!",
                "referenceModelInputInvalid": "Poziv na broj nije ispravan!",
                "toPayeeInputError": "Naziv primatelja je obavezan!",
                "toPayee2": "Primatelj (naziv/ime i adresa)",
                "newPayment": "Novi nalog",
                "delete": "Izbriši",
                "edit": "Uredi",
                "sign": "Potpiši",
                "ibanStructError": "IBAN nije ispravan",
               "taxPayerNumber": "Broj poreznog obveznika",
               "paymentType": "Vrsta uplate",
               "revenueCode": "Vrsta prihoda",
               "municipality": "Općina/opština",
               "taxPeriodFrom": "Poreski period od",
               "taxPeriodTo": "Poreski period do",
               "budgetOrganisation": "Budžetska organizacija",
               "taxPayerError": "Broj poreznog obveznika je obavezan!",
               "revenueCodeError": "Vrsta prihoda je obavezna!",
               "budgetOrganizationError": "Budžetska organizacija je obavezna!",
               "municipalityError": "Općina/opština je obavezna!",
               "municipalityExistanceError": "Općina/opština ne postoji!",
               "taxPeriodRangeError": "Poreski period nije u ispravnom opsegu!",
               "revenueCodeTypeError": "Tip prihoda ne odgovara tipu računa!",
               "revenueCodeExistanceError": "Vrsta prihoda ne postoji!",
               "receiverAccountLengthError": "Račun primaoca mora imati 16 cifara!",
               "receiverAccountModuleError": "Račun primaoca nije korektan!",
               "referenceNumberRequired": "Poziv na broj je obavezan za javne prihode!",
               "referenceNumberMaxLength": "Poziv na broj je prevelik!",
               "referenceNumberError": "Poziv na broj nije validan!"
            },
            internationalPayment: {
                international: "Devizni nalog",
                "fromAccount": "S računa",
                "chargeAccount": "Račun naknade",
                "totalAmount": "Ukupan iznos i valuta",
                "paymentDate": "Datum plaćanja",
                "paymentPurpose": "Opis plaćanja",
                "recipient": "Primatelj",
                "purposeCode": "Šifra namjene",
                "recipientStatus": "Status primatelja",
                "companyName": "Ime i prezime ili puni naziv tvrtke",
                "address": "Adresa",
                "city": "Grad",
                "country": "Država",
                "recipientAccount": "Račun primatelja",
                "recipientBank": "Banka primatelja",
                "bankName": "Naziv banke",
                "swiftCode": "SWIFT kod",
                "bankCharges": "Bankovna naknada",
                "sharedCharges": "Zajednički troškovi",
                "payersPaysCharges": "Isplatitelj plaća troškove",
                "beneficiaryPaysCharges": "Korisnik plaća troškove",
                "naturalPerson": "Fizičko lice",
                "legalPerson": "Pravno lice",
                "tooltipInfo": "Trebate pomoć?",
                "fillData": "Popunite podatke o banci",
                "reviewandSubmit": "Pregled i potvrda",
                "companyNameInputError": "Ime i prezime je obavezno!",
                "recipientAddressInputError": "Adresa primatelja je obavezna!",
                "recipientCityInputError": "Grad primatelja je obavezan!",
                "recipientAccountInputError": "Račun primatelja je obavezan!",
                "bankNameInputError": "Naziv banke je obavezan!",
                "bankAddressInputError": "Adresa banke je obevezna!",
                "bankCityInputError": "Grad banke je obavezan!",
                "selectUrgentLabel": "Uključite ako želite hitnu uplatu putem TARGET2, koja se dodatno naplaćuje po važećoj Tarifi banke",
                "swiftCodeInputError": "SWIFT kod je obavezan!",
                "payersNameInputLabel": "Ime i prezime pošiljaoca",
                "payersAddressInputLabel": "Adresa pošiljaoca",
                "payersCityInputLabel": "Grad pošiljaoca",
                "sepaDebitorLabel": " Stvarni dužnik",
                "sepaReceiverLabel": "Krajnji primatelj",
                "paymentMode": "Svrha plaćanja",
                "toPayee2": "Primatelj (naziv/ime i adresa)",
                "toAccountInputLabel": "IBAN / Broj računa primatelja",
                "bicBankName": "Banka primatelja",
                "noBicBankInfo": "Napomena : Za platne transakcije upućene primateljima plaćanja u EU u korist računa koji nema konstrukciju IBAN-a, " +
                "kao i za platne transakcije u korist računa s nepoznatim BIC-kodom banke primatelja naplaćuje se naknada sukladno " +
                "Tarifi banke za međunarodne i prekogranične platne transakcije.",
            },
            internalTransfer: {
                "transferToAccount": "Prijenos na moj račun",
                "fromAccount": "Račun platitelja (IBAN)",
                "toAccount": "Račun primatelja (IBAN)",
                "dueDate": "Datum izvršenja",
                "valueDate": "Datum kreiranja",
                "amount": "Iznos",
                "sign": "Potpiši",
                "saveAsTemplate": "Pohrani kao predložak",
                "templateName": "Naziv predloška",
                "newPayment": "Kreiraj novi nalog",
                "toPaymentOverview": "Idi na pregeled svih plaćanja",
                "signSuccess": "Platni nalog je uspješno potpisan.",
                "toAccountInputError": "Račun primatelja je obavezan!",
                "paymentPurposeInputError": "Svrha uplate je obavezna!",
                "amountInputError": "Iznos je obavezan!",
                "dueDateError": "Datum izvršenja je obavezan!",
                "templateNameError": "Naziv je obavezan!",
                "when": "Datum",
                "transferComplete": "Prijenos je završen",
                "makeAnotherPayment": "Kreiraj novi nalog",
                "successfullyTransfer": "Uspješno ste prebacili",
                "amountLengthError": "Maksimalni iznos je 99.999.999.999,99",
                "nodata": "Nema podataka za prikaz"
            },
            paymentSignModal: {
                title: "Potpisivanje plaćanja",
                challengeResponse: "Unesite kod",
                ok: "Uredu"
            },
            loanPayment: {
                "loanPayment": "Plaćanje kredita",
                "recieverNumber": "Broj primatelja",
                "purpose": "Svrha uplate",
                "when": "Datum",
                "exchangeRate": "Tečaj",
                "referenceNumber": "Poziv na broj"
            },
            currencyConversion: {
                "exchangeOffice": "Mjenjačnica",
                "exchangeRatesCalculator": "Pregled valuta i tečajni kalkulator",
                "sellingAmount": "Prodaja",
                "buyingAmount": "Kupnja",
                "availableBalance": "Raspoloživo stanje",
                "fromAccount": "S računa",
                "toAccount": "Na račun",
                "amount": "Iznos",
                "sign": "Potpiši",
                "confirm": "Potvrdi",
                "back": "Novi nalog",
                "newCurrencyWillCreate": "Kreirati će se nova valuta",
                "smsAuthentification": "SMS autentifikacija plaćanja",
                "sendSms": "Pošalji SMS kod",
                "smsPassword": "SMS jednokratna lozinka",
                "moneyExchangeComplete": "Novčana razmjena je završena",
                "successfullyExchange": "Uspješno ste razmjenili",
                "makeAnotherExchange": "Napravi još jednu razmjenu",
                "goToPayments": "Idi na pregled naloga",
                "exchangeRateRatio": "Tečajna lista",
                "exchangeOrder": "Zahtjev za razmjenom valuta",
                "signPayment": "Potpiši nalog",
                "deletePayment": "Izbriši nalog",
                "recallPayment": "Opozovi nalog",
                "balance": "Stanje računa",
                "exchangeRate": "Informativni tečaj",
                "bankCharges": "Cjenik banke",
                "when": "Datum",
                "provision": "Naknada",
                "buying": "Kupovni tečaj",
                "selling": "Prodajni tečaj",
                "calculatedAmount":"Preračunati iznos",
                "agreedRate":"Kurs",
                "provisionToolTip1":"Informacije vezane uz",
                "provisionToolTip2": "iznos naknade",
                "provisionToolTip3": "tečaj za naknadu",
                "provisionToolTip4": "iznos terećenja računa za naknadu",
                "provisionToolTip5": "Procijenjeni su iznosi koji se mogu razlikovati ovisno o datumu izvršenja transakcije te posebnim pogodnostima odobrenim od strane banke. Točan iznos bit će obračunat na dan izvršenja transakcije."
            },
            paymentsAndTransferOverview: {
                rejectedLabel: "Poslednji neplaćeni nalozi",
                eInvoicesLabel: "Neplaćeni eRačuni",
                groupedPaymentsLabel: "Grupa naloga za potpis",
                repeatAction: "Ponovi",
                payAction: "Plati",
                signAllAction: "Potpiši sve",
                domPayToSign: "općih naloga za potpis",
                paymentsToSign: "naloga za potpis",
                "confirm": "Potvrdi"
            },
            ebook: {
                "ebookPayIn": "Uplata na eKnjižicu",
                "ebookPayOut": "Isplata sa eKnjižice"
            },
            savings: {
                "savingsPayIn": "Interni devizni prijenos"
            },
            signPayments: {
                "paymentsInvalidDueDate": "Neki od naloga imaju datum valute manji od tekućeg. Želite li ažurirati te datume na današnji?",
                "singlePaymentInvalidDueDate": "Odabrani nalog ima datum valute manji od tekućeg. Želite li ažurirati datum na današnji?",
                "updatePaymentsDueDate": "Ažuriraj krajnje datume plaćanja",
                "updatePaymentDueDate": "Ažuriraj krajnji datum plaćanja",
                "dateCreated": "Datum kreiranja",
                "totalAmount": "Ukupan iznos",
                "dueDate": "Datum izvršenja",
                "confirm": "Potvrdi",
                "challengeResponse": "Kod potvrde",
                "challenge": "Validacijski kod",
                "challengeFirstCode": "Zaporka 1 (unosi se u token)",
                "challengeSecondCodeQR": "Zaporka 2 (unosi se u token)",
                "challengeSecondCode": "Zaporka 2 (iz tokena)",
                "challengeThirdCode": "Zaporka 3 (iz tokena)",
                "challengeDescription": "Uključite token - unesite PIN i odmah na prazan ekran (piše samo CHAL na vrhu) unesite broj koji je ispisan na ekranu 'Zaporka 1', i kliknite na gumb 'OK'. Token će ispisati zaporku 2 koju je potrebno prepisati u polje 'Zaporka 2'. Kada ste završili akciju, odaberite gumb 'POTVRDI'.",
                "challengeDescriptionQR": "'U mBank aplikaciji za fizičke osobe odaberite mToken/Potpiši nalog za plaćanje funkcionalnost. Unesite Zaporku 1 (Iznos) i Zaporku 2 (Račun primatelja) u za to predviđena polja na ekranu mBank aplikacije ili navedene vrijednosti učitajte putem QR Code-a. Kada su obje vrijednosti unesene odaberite gumb 'Izračunaj Zaporku 3'. Zaporku 3 unesite u za to predviđeno polje u EBank aplikaciji te odaberite gumb 'Potvrdi.'",
                "finalStepSuccessfullySigned": "Uspješno ste potpisali nalog",
                "finalStepPaymentServices": "Za usluge platnog prometa naknada će biti obračunata u skladu sa službenim ",
                "finalStepBankTariff": "cjenikom Banke.",
                "finalStepStatus": "Status: ",
                "finalStepPaymentID": "Broj transakcije: ",
                "finalStepNumberOfPayments": "Broj naloga: ",
                "finalStepSentForProcessing": "Nalog je poslan u Banku na obradu.",
                "finalStepPaymentSloHeaderEx": "N/A",
                "finalStepPaymentSloHeaderPen": "N/A",
                "finalStepPaymentSloSubHeader": "N/A",
                "finalStepPaymentSloSubHeaderLink": "N/A",
                "finalStepPaymentSloTextEx": "N/A",
                "finalStepPaymentSloTextPen": "N/A",
                "cancel": "Odustani",
                "resendSms": "Ponovo pošalji SMS",
                "SmsCode": "SMS kod",
                "SmsDescription": "SMS poruka je poslana na vaš uređaj. Unesite kod iz SMS poruke u 'SMS kod' polje. Nakon unosa koda, odaberite gumb 'POTVRDI'.",
                "SmsSentSuccessfully": "SMS poruka uspješno poslana!",
                "paymentExecutionDateTitle": "Promjena datuma valute",
                "paymentExecutionDateBody": "Današnja obrada naloga je završena. Vaš nalog bit će izvršen {{date}} - sukladno terminskom planu Banke. Želite li nastaviti s potpisivanjem zadanog platnog naloga?"
            },
            paymentActions: {
                "repeat": "Ponovi nalog",
                "copy": "Kopiraj nalog",
                "delete": "Izbriši nalog",
                "print": "Ispiši nalog",
                "recall": "Opozovi nalog",
                "checkRecallStatus": "Upit u status Zahtjeva za povrat",
                "sign": "Potpiši nalog",
                "cancel": "Otkaži",
                "rejectRecall": "Odbij zahtjev",
                "approveRecall": "Prihvati zahtjev",
                "edit": "Promijeni nalog",
                "confirmation": "Order confirmation"
            },
            statementConfirmModalTranslations: {
                title: "Izjava",
                p1: "Sukladno Zakonu o međunarodnim mjerama ograničavanja u Banci za provedbu naloga, potrebno je popuniti ovu Izjavu. U protivnom, nalog neće biti proveden.",
                p2: "Pod kaznenom i materijalnom odgovornošću izjavljujem da je, za nalog u iznosu od ",
                p2_2: " za državu",
                p2_0: " osnova plaćanja",
                p2_1: "detaljno obrazloženje (specifikacija)*",
                p4: "Izjava je kreirana u elektroničkom obliku i važeća je bez pečata i potpisa.",
                radioMerchandise: "Roba*",
                radioPresent: "Poklon",
                radioOther: "Ostalo*",
                confirmButton: "Potvrdi",
                cancelButton: "Odustani"
            },
            "currencyCalculator": {
                "exchangeCalculator": "Tečajni kalkulator",
                "exchangeRates": "Kursna lista",
                "msg1": "Tečajna lista primjenjuje se za kupnju i prodaju deviza do iznosa protuvrijednosti od 10.000,00 EUR.",
                "msg2": "Za veće iznose molimo kontaktirajte Odjel Prodaje proizvoda Riznice na " +
                    "tel.: 01 4898 178.",
                "msg3": "Sve valute kotiraju u odnosu na euro (bazna valuta).",
                "updated":"Updated"
            },
            paymentsReturn: {
                openedTab: "Otvoreni",
                archivedTab: "Arhivirani",
                titleLabel: "Suglasnost za povrat sredstava",
                modalTitle: "Odaberite razlog za povrat sredstava",
                recallReason: "Razlog za povrat",
                additionalInfo: "Dodatne informacije",
                checkAdditionalInfo: "Ukloni dodatne informacije",
                additionalInfoError: "Ovo polje je obavezno",
                reasonTech: "Tehnički problemi koji rezultiraju pogrešnim platnim nalogom kreditnog transfera",
                reasonFrad: "Lažno iniciranje platnog naloga kreditnog transfera (required additiona info field)",
                reasonDupl: "Duplo slanje",
                reasonAM09: "Pogrešan iznos",
                reasonAC03: "Pogrešan IBAN",
                reasonCUST: "Ostali razlozi",
                reasonAC03EN: "Please provide additional information about beneficiary in case of negative response to the recall request",
                reasonAC03HR: "Molimo dodatne podatke o primatelju u slučaju negativnog odgovora na zahtjev povrata"
            },
            paymentsFinal: {
                "Rejected": "Odbijen",
                "Processed": "Obrađen",
                "InProcessing": "U obradi",
                "Signed": "Potpisan",
                "NotSigned": "Nepotpisan",
                "WaitingQueue":"Nalog je poslat na čekanje"
            },
            paymentsTypeGroup: {
                "CurrencyExchange":"CurrencyExchange",
                "LocalCurrencyTransfer":"LocalCurrencyTransfer",
            }
        };

        $translateProvider
            .translations('hr', data);
    }
}());

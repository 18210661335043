(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name payments.factory:PaymentsService
   *
   * @description
   *
   */
  angular
    .module('payments')
    .factory('PaymentsService', PaymentsService);

  function PaymentsService($state,  $filter, EndpointsService, $q, $http, $log, $uibModal, PaymentFilterService, HelperService,
                           $localStorage, $rootScope, PaymentTypesService, SessionService, NotificationService) {
    var PaymentsServiceBase = {};

    PaymentsServiceBase.getPage = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.payments, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    
    PaymentsServiceBase.getTotalAmountWithFee = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        var url = response.links['payments_getTotalAmountWithFee?amount={amount}&currencySymbol={currencySymbol}&paymentCharges={paymentCharges}'];
        url = url.replace('{amount}', params.amount).replace('{currencySymbol}', params.currencySymbol).replace('{paymentCharges}', params.paymentCharges);
        $http.get(url)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    

    PaymentsServiceBase.getAll = function (params) {
      var predefinedParams = {
        page: 0,
        pageSize: 10
      };
      var extended = _.extend(predefinedParams, params);
      return PaymentsServiceBase.getPage(extended);
    };

    PaymentsServiceBase.getRejectedPayments = function (params) {
      var predefinedParams = {
        page: 0,
        status: 'Rejected',
        orderBy: 'dueDate DESC',
        pageSize: 10
      };
      var extended = _.extend(predefinedParams, params);
      return PaymentsServiceBase.getPage(extended);
    };
    PaymentsServiceBase.getPendingPayments = function (params) {
      var predefinedParams = {
        page: 0,
        status: 'InProcessing',
        orderBy: 'dueDate DESC',
        pageSize: 10,
        purposeFTS: params.purposeFTS
      };
      var extended = _.extend(predefinedParams, params);
      return PaymentsServiceBase.getPage(extended);
    };

    function signPayment(params) {
      $log.debug("Samer: usao ovdje3");
      // Normal payment sign
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_sign, params)
            .then(function (data) {
              $rootScope.$broadcast('paymentInserted');
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PaymentsServiceBase.signPayment = function (params) {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {

        //sign changed payment that is already signed
        if (params.payment.paymentIdTemp) {
          var tempId = params.payment.paymentIdTemp;
          return _updatePayment(tempId, params.payment);
        }
        return insertPayment(params.payment);
      }

      return signPayment(params);
    };

    PaymentsServiceBase.paymentsAppeal = function (data) {

      // Normal payment sign
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_appeal, data)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.updatePaymentDueDate = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_id_updateOldPaymentDate.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.insertPayment = function (paymentObject) {
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
        return validatePayment(paymentObject);
      }

      return insertPayment(paymentObject);
    };

    PaymentsServiceBase.insertCancelationForPayment = function (cancelationObject) {
      return insertCancelationForPayment(cancelationObject);
    };

    PaymentsServiceBase.deleteCancelation = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.delete(response.links.paymentCancelations_id.replace('{id}', id))
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.updateCancelationForPayment = function (id, request, additionalParam) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.paymentCancelations_id.replace('{id}', id), request)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.getCancelationPage = function (params) {
      params.includeList = ['payment'];
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.paymentCancelations, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PaymentsServiceBase.getCancelationById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if (params) {
          $http.get(response.links.paymentCancelations_id.replace('{id}', id), {
            params: params
          })
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
                deferred.reject(msg.data);
              });
        } else {
          $http.get(response.links.paymentCancelations_id.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
                deferred.reject(msg.data);
              });
        }
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.signCancelation = function (params) {
      return signCancelation(params);
    };

    PaymentsServiceBase.downloadCancelationPdf = function (cancelationId) {
      var nonBlockingWindow = window.open('', '_self');
      PaymentsServiceBase.getCancelationPdfLink(cancelationId)
        .then(function (pdfLink) {
          //pdfLink = pdfLink.data;

          if (pdfLink) {
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;
            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    PaymentsServiceBase.getCancelationPdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.paymentCancelations_downloadLink_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    function signCancelation(params) {
      // Normal payment sign
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.paymentCancelations_sign, params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    function _updatePayment(id, paymentObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_id.replace('{id}', id), paymentObject)
            .then(function (data) {
              if (paymentObject.paymentIdTemp) {
                delete paymentObject.paymentIdTemp;
              }
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    function _updateCancelationForPayment(id, cancelationObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.paymentCancelations_id.replace('{id}', id), cancelationObject)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    function insertPayment(paymentObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks()
        .then(function (response) {
          var link = (_.has(paymentObject, 'linkList.itemList.sign')) ? paymentObject.linkList.itemList.sign : response.links.payments;
          SessionService.saveStorageObject(paymentObject, 'payment');
          $http.post(link, paymentObject)
            .then(function (data) {
              console.log('Payment Service - func insertPayment', data);
              data = data.data;
              $rootScope.$broadcast('paymentInserted');
              SessionService.removeStorageObject();
              if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl') {
                if (!data && !data.id) {
                  deferred.reject({resultList: [{description: 'Error! Something went wrong!'}]});
                } else {
                  deferred.resolve(data);
                }
              } else {
                deferred.resolve(data);
              }
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PaymentsServiceBase.uploadDocument = function (documentObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks()
        .then(function (response) {
          $http({
           method: 'POST',
           url: response.links.paymentDocumentations_upload,
           headers: {'Content-Type': undefined},
           data: documentObject
         }).then(function (data) {
            data = data.data;
                //$rootScope.$broadcast("documentInserted");
            deferred.resolve(data);
          }).catch(function (msg) {
                deferred.reject(msg.data);
              });
        },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    PaymentsServiceBase.getUploadedDocuments = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks()
        .then(function (response) {
          $http({
              method: 'GET',
              params: params,
              url: response.links.paymentDocumentations
            }).then(function (data) {
              data = data.data;
              //$rootScope.$broadcast("documentInserted");
              deferred.resolve(data);
            }).catch(function (msg) {
              deferred.reject(msg.data);
            });
        },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    PaymentsServiceBase.downloadDocument = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks()
        .then(function (response) {
          $http({
              method: 'GET',
              url: response.links.paymentDocumentations_downloadPaymentDocumentationLink_id.replace('{id}', id.toString())
            }).then(function (data) {
              data = data.data;
              deferred.resolve(data);
            }).catch(function (msg) {
              deferred.reject(msg.data);
            });
        },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };

    PaymentsServiceBase.deleteDocument = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks()
        .then(function (response) {
          console.log(response.links.paymentDocumentations_id.replace('{id}', id.toString()));
          $http({
              method: 'DELETE',
              url: response.links.paymentDocumentations_id.replace('{id}', id.toString())
            }).then(function (data) {
              data = data.data;
              deferred.resolve(data);
            }).catch(function (msg) {
              deferred.reject(msg.data);
            });
        },
          function (error) {
            deferred.reject(error.data);
          });
      return deferred.promise;
    };


    function insertCancelationForPayment(cancelationObject) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        var link = response.links.paymentCancelations;
        $http.post(link, cancelationObject)
            .then(function (data) {
              $rootScope.$broadcast('cancelationInserted');
              SessionService.removeStorageObject();
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PaymentsServiceBase.getCancelationCodes = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        //$log.debug("Params:", params);
        $http.get(response.links.paymentCancelCodes, {
          params: params
        })
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PaymentsServiceBase.paymentOrderConfirm = function (data) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.paymentConfirmOrders, data)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.updatePayment = function (id, request, additionalParam) {

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl' && additionalParam !== 'changeProcessed') {
        return validatePayment(request);
      }

      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_id.replace('{id}', id), request)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.validatePayment = function (request) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_validate, request)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    function validatePayment(request) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_validate, request)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    }

    PaymentsServiceBase.deletePayment = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.delete(response.links.payments_id.replace('{id}', id))
            .then(function (data) {
              $rootScope.$broadcast('paymentInserted');
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.recallPayment = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.put(response.links.payments_recall, params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.openSignModal = function (paymentId) {
      var modalInstance = $uibModal.open({
        templateUrl: 'payments/payment-sign-modal.tpl.html',
        controller: 'PaymentSignModalCtrl',
        controllerAs: 'paymentSignModal',
        resolve: {
          paymentId: function () {
            return paymentId;
          }
        },
        backdrop: 'static',
        size: 'sm'
      });
      return modalInstance.result;
    };

    PaymentsServiceBase.openStatementConfirmModal = function (selectedCountry) {
      return $uibModal.open({
        templateUrl: 'payments/statement-confirm-modal.tpl.html',
        controller: 'StatementConfirmModalCtrl',
        controllerAs: 'statementConfirmModal',
        resolve: {
          data: function () {
            return selectedCountry;
          }
        }
      });
    };

    PaymentsServiceBase.getById = function (id, params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
          if (params) {
            $http.get(response.links.payments_id.replace('{id}', id), {
              params: params
            })
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          } else {
            $http.get(response.links.payments_id.replace('{id}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
          }
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.showChallengeFields = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
            $http.get(response.links['payments_additionalChallangeRequired?PaymentId={PaymentId}'].replace('{PaymentId}', id))
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
        },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.getPaymentModes = function () {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.paymentModes)
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        //   deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PaymentsServiceBase.getBicBanks = function (id, params) {
      return $q(function (resolve, reject) {
        if (id) {
          EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.bicBanks.replace('{id}', id))
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (msg) {
                reject(msg.data);
              });
          });
        } else {
          EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.bicBanks, {params: params})
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (msg) {
                reject(msg.data);
              });
          });
        }
      });
    };

    PaymentsServiceBase.getCountries = function (id, params) {
      return $q(function (resolve, reject) {
        if (id) {
          EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.countries_id.replace('{id}', id))
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (msg) {
                reject(msg.data);
              });
          });
        } else {
          EndpointsService.getLinks().then(function (response) {
            $http.get(response.links.countries, {params: params})
              .then(function (data) {
                resolve(data.data);
              })
              .catch(function (msg) {
                reject(msg.data);
              });
          });
        }
      });
    };

    PaymentsServiceBase.loadDomesticPaymentData = function (id, paymentObject, params) {
      var countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;

      function commonMapping(response) {
        paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, response.accountId);
        paymentObject.purposeOfPaymentInput = response.purpose;
        paymentObject.amountInput = response.amount;
        paymentObject.receiversAddressInput = response.receiverAddress1;
        paymentObject.toAccountInput = response.toAccountNumber;
        paymentObject.dueDateTemp = new Date(response.dueDate);
        paymentObject.referenceToTemp = response.referenceTo;
        params.payeeSearchFilter = response.receiverName;
        response.urgency === 1 ? params.urgency = true : params.urgency = false;
        response.paymentSubTypeGroup ? params.isInternal = true : params.isInternal = false;

        if (countryCode === 'sl')
          paymentObject.beneficiarySWIFTCode = response.beneficiarySWIFTCode;

      }

      if (countryCode === 'bh') {
        return this.getById(id, {includeList: ['RevenuePaymentDetails']})
          .then(function (response) {
            // mapping related to public revenue
            if (response.revenuePaymentDetails) {
              paymentObject.budgetOrganization = response.revenuePaymentDetails.budgetOrganization;
              paymentObject.municipality = response.revenuePaymentDetails.municipality;
              paymentObject.priority = response.revenuePaymentDetails.priority;
              paymentObject.revenueCode = response.revenuePaymentDetails.revenueCode;
              paymentObject.taxPayerId = response.revenuePaymentDetails.taxPayerId;
              paymentObject.taxPeriodFrom = new Date(response.revenuePaymentDetails.taxPeriodFrom);
              paymentObject.taxPeriodTo = new Date(response.revenuePaymentDetails.taxPeriodTo);
            }
            commonMapping(response);
            if (response.purposeCodeDPS) {
              paymentObject.purposeCodeSelect = PaymentFilterService.filterPurposeCodeSelect(params.purposeCodeList, response.purposeCodeDPS);
            }
            if (response.revenuePaymentDetails)
              paymentObject.referenceNumberInput = response.revenuePaymentDetails.reference;

          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      } else {
        return this.getById(id)
          .then(function (response) {
            //filterFromAccountSelect(response.fromAccountNumber);
            commonMapping(response);
            paymentObject.receiversPostalCodeAndCityInput = response.receiverCity;
            if (response.referenceTo) {
              var model = response.referenceTo.substring(0, 2);
              var refNumber = response.referenceTo.substring(2);
              paymentObject.referenceNumberInput = refNumber || '';
              paymentObject.referenceModelSelect = PaymentFilterService.filterReferenceModelSelect(params.referenceModelList, model);
            }
            if (response.purposeCodeDPS) {
              if ($localStorage.code === 'sr') { ///jer se na Srbiji šalje 2 ili 9 ispred šifre
                var tempPurpose = response.purposeCodeDPS.substr(1);
              } else {
                tempPurpose = response.purposeCodeDPS;
              }
              paymentObject.purposeCodeSelect = PaymentFilterService.filterPurposeCodeSelect(params.purposeCodeList, tempPurpose);
            }
            if (countryCode === 'hr')
            {
              paymentObject.isUrgentPaymentCheckbox = response.urgency === '1' ? true : false;
              paymentObject.isInstantPaymentCheckBox = response.urgency === '2' ? true : false;
            }
            //if (response.referenceTo) {
            //  paymentObject.referenceModelSelect = PaymentFilterService.filterReferenceModelSelect(params.referenceModelList, response.referenceTo);
            //}
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    PaymentsServiceBase.loadCurrencyConversionData = function (id, paymentObject, params) {
      //using master account
      function loadAccountsLists(accountId) {
        return PaymentTypesService.getByGroup({
          paymentTypeGroup: 'CurrencyExchange',
          masterAccountId: accountId
        })
          .then(function (response) {
            params.fromAccountsList = response.result[0].debitAccountList;
            params.toAccountsList = response.result[0].creditAccountList;
            _.forEach(params.fromAccountsList, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.iban + ' - ' + item.currencySymbol;
              }
            });

            _.forEach(params.toAccountsList, function (item) {
              if (item.accountDescription) {
                item.optionValue = item.accountDescription + ' - ' + item.iban;
              }
            });
          })
          .catch(function (error) {
            $log.debug('Error loading accounts: ' + error);
          });
      }

      return this.getById(id)
        .then(function (response) {
          return loadAccountsLists(response.accountId)
            .then(function () {
              if (response.amount) {
                paymentObject.fromAccountAmountInput = response.amount;
                params.exchangeType = {
                  from: true,
                  to: false
                };
              } else if (response.transactionAmount2) {
                paymentObject.toAccountAmountInput = response.transactionAmount2;
                params.exchangeType = {
                  from: false,
                  to: true
                };
              }

              //filterFromAccountSelect(response.fromAccountNumber);
              paymentObject.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountsList, response.accountId);
              paymentObject.sellCurrencyInput = paymentObject.fromAccountSelect.currencySymbol;
              //filterToAccountSelect(response.toAccountNumber.split(" ")[0]);
              if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sl')
                paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelectByAccountAndCurrency(params.toAccountsList, response.toAccountNumber.replace(/\s/g, ''), response.destinationCurrencySymbol);
              else
                paymentObject.toAccountSelect = PaymentFilterService.filterToAccountSelect(params.toAccountsList, response.toAccountNumber.replace(/\s/g, ''));

              var tempObj = {
                sellCurrency: paymentObject.sellCurrencyInput,
                localCurrency: params.localCurrency,
                currencyList: params.currencyList,
                destinationCurrency: response.destinationCurrencySymbol
              };
              paymentObject.buyCurrencyInput = PaymentFilterService.filterBuyCurrencyInput(tempObj);
            })
            .catch(function (error) {
              $log.debug(error);
            });
        })
        .catch(function (error) {

        });
    };


    PaymentsServiceBase.openCurrencyCalculatorModal = function () {
      var modalScope = $rootScope.$new();
      modalScope.modalInstance = $uibModal.open({
        templateUrl: 'payments/currency-calculator-modal.tpl.html',
        controller: 'CurrencyCalculatorModalCtrl',
        controllerAs: 'currencyCalculator',
        scope: modalScope,
        size: 'lg',
        backdrop: 'static'
      });
    };

    PaymentsServiceBase.getPaymentPdfLink = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.payments_downloadLink_id.replace('{id}', id))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      }, function (error) {
        deferred.reject(error.data);
      });
      return deferred.promise;
    };

    PaymentsServiceBase.downloadPaymentPdf = function (paymentId) {
      var nonBlockingWindow = window.open('', '_self');
      PaymentsServiceBase.getPaymentPdfLink(paymentId)
        .then(function (pdfLink) {

          if (pdfLink) {
            // If we want to open link in same tab (immediate download)
            // window.location.href = pdfLink.url;

            // Opening in new empty tab
            // flagToMarkDownload
            sessionStorage.setItem('downloadInProgress', 'download');
            nonBlockingWindow.location.href = HelperService.prepareDownloadLinkSlo(pdfLink.url);
          }
        })
        .catch(function (error) {
          nonBlockingWindow.close();
          NotificationService.showMessage(error, 'error');
          $log.debug(error);
        });
    };

    PaymentsServiceBase.openRecallReasonModal = function (data) {
      var options = {
        templateUrl: 'payments/payments-recall-modal.tpl.html',
        controller: 'PaymentsRecallModalCtrl',
        controllerAs: 'paymentsRecallModal',
        backdrop: 'static',
        resolve: {
          data: data
        }
      };
      // For Slovenia
      if (data) options.size = 'lg';
      return $uibModal.open(options);
    };

    PaymentsServiceBase.requestSMSCode = function (url) {

      var deferred = $q.defer();
      $http.get(url)
        .then(function (data) {

          deferred.resolve(data.data);
        }).catch(function (msg, code) {
          deferred.reject(msg.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.isVerificationRequiredForSign = function (payment) {
      // Internal transfer in Srb/Mne does not need SMS verification
      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'hr' &&
        ((payment.paymentTypeGroup === 'LocalCurrencyTransfer' && payment.paymentSubTypeGroup !== 'DomesticPayment') || payment.paymentTypeGroup === 'CurrencyExchange' )) {
        return false;
      }
      return true;
    };

    PaymentsServiceBase.openUpdatePaymentDueDateModal = function () {
      return $uibModal.open({
        templateUrl: 'payments/payment-update-date-modal.tpl.html',
        backdrop: 'static',
        size: 'lg'
      });
    };

    PaymentsServiceBase.openUpdateCancelationDueDateModal = function () {
      return $uibModal.open({
        templateUrl: 'payments/cancelation-update-date-modal.tpl.html',
        backdrop: 'static',
        size: 'lg'
      });
    };

    // Message - Error message from API that will be displayed. If no message is sent, text from localization will be shown
    PaymentsServiceBase.openUpdateCurrencyDateModal = function (nextWorkingDate, message) {
      return $uibModal.open({
        templateUrl: 'payments/payment-update-currency-date-modal.tpl.html',
        backdrop: 'static',
        size: 'lg',
        controller : function ($scope) {
          $scope.nextWorkingDate = $filter('date')(nextWorkingDate, 'dd.MM.yyyy.');
          $scope.errorMessage = message;
        }
      });
    };

    PaymentsServiceBase.getSignatureList = function (id) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.get(response.links.payments_id.replace('{id}', id + '?includeList=Signatures'))
          .then(function (data) {
            deferred.resolve(data.data);
          }).catch(function (msg, code) {
            deferred.reject(msg.data);
          });
      },
    function (error) {
      deferred.reject(error.data);
    });
      return deferred.promise;
    };

    //endpoint specific to BiH
    PaymentsServiceBase.getBankCharges = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        if (response.links.bankCharges) {
          $http.get(response.links.bankCharges, {
            params: params
          })
              .then(function (data) {
                deferred.resolve(data.data);
              }).catch(function (msg, code) {
                deferred.reject(msg.data);
              });
        } else {
          deferred.resolve(null);
        }
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };

    PaymentsServiceBase.setFromAccountsToDefaulCurrency = function (accountsList) {
      if (_.isArray(accountsList) && accountsList.length > 0) {
        return _.find(accountsList, function (item) {
          return item.currencySymbol === $rootScope.APP_GLOBAL_SETTINGS.defaultData.DefaultCurrency;
        });
      }
    };

    PaymentsServiceBase.showModalWithActions = function (response, paymentObject, isDomestic, isSepaAndUrgent) {
      var obj = {
        msg: response.resultList[0].description,
        type: response.resultList[0].key
      };
      var modalInstance = $uibModal.open({
        templateUrl: 'payments/change-payment-date-modal.tpl.html',
        backdrop: 'static',
        controller: 'ChangePaymentDateModalCtrl',
        controllerAs: 'changePaymentDateModal',
        size: 'lg',
        resolve: {
          obj: function () {
            obj.domestic = isDomestic;
            obj.isSepaAndUrgent = parseInt(isSepaAndUrgent);
            return obj;
          }
        }
      })
        .result
        .then(function (result) {
          console.log(result);
          if (result === 'yes' && obj.type === 'ToAccountNumber' ) {

            if (paymentObject.paymentTypeGroup === 'ForeignPayment')
              $state.go('payments.domesticPayment.fromInternational', {transactionDetails: paymentObject});

            if (paymentObject.paymentTypeGroup === 'DomesticPayment')
              $state.go('payments.internationalPayment.fromDomestic', {transactionDetails: paymentObject});
          }
          // if(result == "yes" && obj.type=='changeDate'){
          //   //set nonWorkingDate
          //   NonWorkingDays.nextWorkingDate(paymentObject.dueDate)
          //     .then(function (response) {
          //       vm.paymentResponse.dueDate = new Date(response);
          //       vm.steps = {
          //         step1: false,
          //         step2: true,
          //         step3: false
          //       };
          //     })
          //     .catch(function (response) {
          //       NotificationService.showMessage(response, 'error');
          //     })
          // }
        });
    };

    PaymentsServiceBase.sendRefusalOrderMail = function (params) {
      var deferred = $q.defer();
      EndpointsService.getLinks().then(function (response) {
        $http.post(response.links.payments_sendRefusalOrderMail, params)
            .then(function (data) {
              deferred.resolve(data.data);
            }).catch(function (msg, code) {
              deferred.reject(msg.data);
            });
      },
        function (error) {
          deferred.reject(error.data);
        });
      return deferred.promise;
    };



    return PaymentsServiceBase;
  }
}());

(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sepa.controller:SepaRefusalCroCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaRefusalCroCtrl', SepaRefusalCroCtrl);

  function SepaRefusalCroCtrl($q, AccountsService, SepaService, HelperService, $state, $scope, AlertService, NotificationService) {
    var vm = this;
    vm.pageLoaded = true;
    vm.step = "start";

    vm.model = {
      fromAccountSelect: {},
      executionDate: new Date(),
      amount: "",
      identifierApproval: "", // Identifikator suglasnosti
      receiverId: "", // Identifikator primatelja
      receiverName: "",  // Naziv i sjedište primatelja plaćanja
      receiverAccount: "", // Račun primatelja plaćanja - IBAN
      receiverNumber: "" // Detalji plaćanja (poziv na broj primatelja)
    }

    init();
    function init() {
      loadData();
    }

    function loadData() {
      var promises = [];
      promises.push(SepaService.getSepaCreditorsList({pageSize: 10000}));
      promises.push(AccountsService.getPage({
        accountTypeGroupList: ["CurrentAccount", "GiroAccount"],
        pageSize: 10000,
        currencySymbol: 'EUR'
      }));

      $q.all(promises)
        .then(function (response) {
          vm.sepaCreditorList = response[0].result;

          vm.currentAccounts = response[1].result;
          vm.model.fromAccountSelect = response[1].result[0];
        })
    }

    function prepareDataForInsert(input) {
      var data = {};
      data.accountId = input.fromAccountSelect.accountId;
      data.inputValueDate = input.executionDate;
      data.amount = input.amount;
      data.mandateReference = input.identifierApproval;
      data.receiverIdentificator = input.receiverId;
      data.receiverName = input.receiverName;
      data.receiverAccount = input.receiverAccount;
      data.receiverReference = input.receiverNumber;
      return data;
    }

    vm.setAccountFrom = function (account) {
      vm.model.fromAccountSelect = account;
      vm.fromAccountDropdown = false;
    };

    vm.datesDisabled = function (date, mode) {
      // return (mode === 'day' && (date.getTime() < tomorrow));
    };

    vm.findIdInCreditorList = function (creditorId) {
      var creditor = _.find(vm.sepaCreditorList, {creditorId: creditorId});
      if (creditor) {
        vm.creditorInListExist = true;
        vm.model.receiverName = creditor.name;
      } else {
        vm.creditorInListExist = false;
        vm.model.receiverName = "";
      }
    }

    vm.submit = function (form) {
      vm.formSubmitted = true;
      if (form.$invalid) return;
      vm.submitButtonDisabled = true;
      SepaService.addSepaDDComplaint(prepareDataForInsert(vm.model))
        .then(function (response) {
          vm.step = "resume";
          vm.responseModel = response;
          HelperService.initializeMTokenSignature.call(vm, $scope, vm.confirmSign);
          HelperService.loadChallengeResponse(response, vm);
          vm.submitButtonDisabled = false;
        }, function (error) {
          vm.submitButtonDisabled = false;
          NotificationService.showMessage(error, 'error');
        })
    }

    vm.confirmSign = function () {
      if (vm.confirmSignBtnDisabled) return;
      vm.confirmSignBtnDisabled = true;
      SepaService.signSepaComplaint({
        "id": vm.responseModel.id,
        "signData": vm.challengeResponse,
        "additionalSignData": vm.challengeResponse2
      })
        .then(function (response) {
          vm.signInProgress = false;

          vm.confirmSignBtnDisabled = false;
          AlertService.infoAlert({
            text: 'sepa.signSuccessfull'
          }).then(function () {
            $state.go("sepa.transactions");
          });
        }, function (error) {
          vm.signInProgress = false;
          NotificationService.showMessage(error, 'error');
          vm.confirmSignBtnDisabled = false;
        })
    }
  }
}());

<section id="main">

    <div class="container-fluid">
        <div class="domp-header-wrapper" ng-show="vm.step === 'pay' ||  vm.step === 'verify'">
            <div class="domp-header-row">
                <h2 class="f-28" translate="eInvoicesModal.headerLabel">E-Invoice Payment</h2>
            </div>
        </div>

        <!-- PAYMENT STEP -->
        <div class="row domp-body-wrapper p-10" ng-if="vm.step === 'pay'">
            <form novalidate name="vm.paymentForm">
                  <div class="col-sm-12 domp-from-section">
                    <div class="col-sm-12">

                            <div class="row">
                                <!-- CUSTOM ACCOUNTS DROPDOWN -->
                                <div class="col-sm-6 domp-acc-dropdown">
                                    <label class="domp-form-label" for="issuer" translate="payments.fromAccount"></label>
                                    <div class="accounts-dropdown">
                                        <div class="debit-account-widget dashboard-widget-item domp-acc-widget" data-ng-click="vm.fromAccountDropdown = !vm.fromAccountDropdown" ng-style="vm.fromAccountSelect.linkList.itemList.imageUrl ?{'background-image': 'url(' + vm.fromAccountSelect.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                            <h4 class="no-wrap-text bold-font m-b-0">
                      <b class="col-xs-10 p-0 m-t-10  no-wrap-text">  {{vm.fromAccountSelect.friendlyName}} </b>
                    <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':account.isFavorite,'zmdi-star-outline':!account.isFavorite}"></i>
                    </span>
                  </h4>
                                            <p class="m-b-0 m-t-0 iban-style">{{vm.fromAccountSelect.iban | formatIban}}</p>
                                            <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                {{vm.fromAccountSelect.availableBalance| number:2}} {{vm.fromAccountSelect.currencySymbol}}
                                            </p>
                                            <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                            <span class="caret-icon"><i class="zmdi zmdi-chevron-down zmdi-hc-2x"></i></span>
                                        </div>
                                        <div class="from-accounts-list m-l-0" ng-show="vm.fromAccountDropdown" scrollable>
                                            <div class="accounts-list-item p-0" data-ng-repeat="item in vm.paymentTypeGroupDomestic.result[0].debitAccountList" data-ng-click="vm.setAccountFrom(item);">

                                                <div class="debit-account-widget inverse dashboard-widget-item domp-acc-widget" ng-style="item.linkList.itemList.imageUrl ?{'background-image': 'url(' + item.linkList.itemList.imageUrl + ')'} : {'background-image': 'url(img/hypo/current_account_h200.png)'}">
                                                    <h4 class="no-wrap-text bold-font m-b-0">
                                            <b class="col-xs-10 p-0 m-t-10  no-wrap-text">{{item.friendlyName}}</b>
                                            <span class="col-xs-2 p-0 t-a-r c-white favorite-star-container"><i class="zmdi" ng-class="{'zmdi-star favorite-account':item.isFavorite,'zmdi-star-outline':!item.isFavorite}"></i></span>
                                        </h4>
                                                    <p class="m-b-0 m-t-0 iban-style">{{item.iban | formatIban}}</p>
                                                    <p class="m-b-0 bold-font f-size-26 domp-available-balance no-wrap-text">
                                                        {{item.availableBalance | number:2}} {{item.currencySymbol}}
                                                    </p>
                                                    <span class="d-block m-t-5 m-b-10" translate="currencyConversion.availableBalance"></span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="col-sm-6">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="form-group">
                                                <label class="domp-form-label" for="referenceNumber" translate="domesticPayment.amountInputLabel"></label>
                                                <div class="input-group">
                                                    <input type="text" class="form-control input-field t-a-r" name="amountInput" id="amountInput" 
                                                            placeholder="{{'domesticPayment.amountPlaceholder'| translate}}" amount-input-mask maxlength="12"
                                                            ng-model="vm.amountInput" required 
                                                            ng-change="vm.instantPaymentValidation()"
                                                            ng-class="{'domp-error': vm.paymentForm.amountInput.$invalid && vm.formSubmitted}" readonly />
                                                    <span class="input-group-btn">
                        <button type="button" class="btn btn-default input-group-app-btn domp-btn-height disabled z-zero" ng-if="vm.countryCode != 'sr'" style="border:1px solid #062a42;">
                          {{vm.defaultCurrency}}
                        </button>
                      </span>
                                                </div>
                                                <span class="c-red" ng-show="vm.paymentForm.amountInput.$error.required && vm.formSubmitted" translate="internalTransfer.amountInputError"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-sm-6">
                                            <div class="form-group">
                                                <label class="domp-form-label" for="dateInput" translate="domesticPayment.dateInputLabel">Date:</label>
                                                <div class="input-group ">
                                                    <input type="text" class="form-control input-field" name="dateInput" id="dateInput" date-disabled="vm.disabled(date, mode)"
                                                           uib-datepicker-popup="shortDate" data-ng-model="vm.dateInput"
                                                           show-weeks="false" is-open="vm.datePickerOpened"
                                                           ng-class="{'domp-error': vm.instantDateCheck()}"
                                                           current-text="{{'core.period.today' | translate}}"
                                                           ng-change="vm.instantPaymentValidation()"
                                                           clear-text="{{'core.datePicker.clearBtn' | translate}}"
                                                           close-text="{{'core.datePicker.doneBtn' | translate}}"
                                                           datepicker-options="vm.dateOptions" readonly>
                                                    <span class="input-group-btn">
                        <button data-ng-click="vm.datePickerOpened = !vm.datePickerOpened" type="button" class="btn btn-default input-group-app-btn domp-btn-height z-zero" style="border:1px solid #062a42;">
                          <i class="icon icon-calendar" style="font-size: 21px;"></i>
                        </button>
                      </span>
                                                </div>
                                              <div class="c-red"
                                                   ng-show="vm.instantDateCheck()"
                                                   translate="eInvoicePayment.instantDateError"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                    </div>
                </div>

                 <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 receiver-section">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicePayment.accNumber">Account number</b></span>
                                        <b><span ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'hr'">
                                            <span ng-if="vm.invoice.iban">{{vm.invoice.iban}}</span>
                                            <span ng-if="!vm.invoice.iban">{{vm.invoice.creditorBank}} - {{vm.invoice.creditorAccount}}</span>
                                          </span></b>
                                        <b><span ng-show="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">{{vm.invoice.creditorAccount}}</span></b>
                                    </p>
                                </div>
                                <div class="col-sm-6" ng-if="vm.invoice.creditorName">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicePayment.toPayee">To payee</b></span>
                                        <b>{{vm.invoice.creditorName}}</b>
                                    </p>

                                </div>
                            </div>
                            <div class="row" ng-if="vm.invoice.creditorAddress">

                                <div class="col-sm-6"></div>
                                <div class="col-sm-6">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicePayment.recAddress">Receiver's address</b></span>
                                        <b>{{vm.invoice.creditorAddress}}</b>
                                    </p>

                                </div>
                            </div>
                            <div class="row" ng-if="vm.invoice.creditorCity">
                                <div class="col-sm-6"></div>
                                <div class="col-sm-6">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicePayment.recPostalCity">Receiver's postal code and city</b></span>
                                        <b>{{vm.invoice.creditorCity}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 receiver-section">
                            <div class="row">
                                <div class="col-sm-6">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicePayment.purpose">Purpose</b></span>
                                        <input type="text" class="form-control"
                                               data-ng-model="vm.invoice.purpose" maxlength="140">
                                    </p>
                                </div>
                                <div class="col-sm-6">
                                    <p>
                                        <span class="invoice-inline-title text-muted"><b translate="eInvoicesModal.refNumberLabel"></b></span>
                                        <b>{{vm.invoice.paymentReferenceNumber}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="col-sm-12 receiver-section">
                          <div class="row">
                              <div class="col-sm-6" ng-if="vm.invoice.status === 'Archived' || vm.invoice.status === 'Rejected'">
                                    <p>
                                      <span class="invoice-inline-title text-muted">
                                        <b translate="eInvoiceDetail.noteArchive" ng-if="vm.invoice.status === 'Archived'"></b>
                                        <b translate="eInvoiceDetail.noteRejected" ng-if="vm.invoice.status === 'Rejected'"></b>
                                      </span>
                                      <b>{{vm.invoice.userComment}}</b>
                                    </p>
                                </div>
                            </div>


                            <div ng-if="vm.countryCode != 'sl'" class="row">
                                <div class="col-sm-12 pull-right text-right charges-label">
                                    <a ng-href="{{vm.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges" class="pull-right">Check the bank
                    charges</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <section ng-if="vm.countryCode == 'hr'">
                <div class="col-xs-4">
                    <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                      <input ng-readonly="vm.disableAllFields" type="checkbox"
                        ng-model="vm.isInstantPaymentCheckBox"
                        ng-disabled="!vm.instantPaymentEnabled"
                        ng-change="vm.toggleUrgencyField('isInstantPaymentCheckBox')">
                      <i class="input-helper"></i>
                      <span uib-tooltip="{{'domesticPayment.instantMsg'| translate}}" tooltip-append-to-body="true"
                      tooltip-trigger="'mouseenter'"
                      tooltip-placement="top" ng-class="{'disabled-label': !vm.instantPaymentEnabled}">{{'domesticPayment.selectInstantLabel'| translate}}
                      </span>
                    </label>
                  </div>
                  <div class="col-xs-8">
                    <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value" ng-if="vm.hideUrgency === false">
                        <input type="checkbox" ng-model="vm.isUrgentPaymentCheckbox"
                               ng-change="vm.toggleUrgencyField('isUrgentPaymentCheckbox')">
                      <i class="input-helper"></i>
                      <span uib-tooltip="{{'domesticPayment.urgentMsg'| translate}}"
                            tooltip-append-to-body="true" tooltip-trigger="'mouseenter'"
                            tooltip-placement="top">{{'domesticPayment.selectUrgentLabel'| translate}}
                      </span>
                    </label>
                  </div>
            </section>
              <section ng-if="vm.countryCode == 'sl'" class="row p-t-30 flex-row flex-column-xs">

                <div ng-if="APP_GLOBAL_SETTINGS.defaultData.InstantPayment === 'Enabled' && vm.amountInput < 15000"
                     class="p-l-30" >
                  <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value">
                    <input type="checkbox"
                           ng-change="vm.isUrgentPaymentCheckbox = false;"
                           ng-model="vm.isInstantPaymentCheckbox">
                    <i class="input-helper"></i>
                    <span>{{'domesticPayment.selectInstantLabelSLO'| translate}}
                    </span>
                  </label>
                </div>

                <div class="p-l-30">
                  <label class="checkbox checkbox-inline-inline m-r-20 checkbox-value" ng-if="vm.hideUrgency === false">
                    <input type="checkbox"
                           ng-change="vm.showInfo(); vm.isInstantPaymentCheckbox = false;"
                           ng-model="vm.isUrgentPaymentCheckbox">
                    <i class="input-helper"></i>
                    <span uib-tooltip="{{'domesticPayment.urgentPaymentInfo'| translate}}"
                          tooltip-append-to-body="true" tooltip-trigger="'mouseenter'"
                          tooltip-placement="top">{{'domesticPayment.selectUrgentLabelSLO'| translate}}
                    </span>
                  </label>
                </div>
                <div class="p-l-30" style="line-height: 39px;">
                  <a class="underline" ng-href="{{vm.defaultData.ProvisionLink}}" target="_blank" translate="domesticPayment.timetableForProvisionLink">
                    Check the bank charges
                  </a>
                </div>
                <div class="p-l-30 " style="line-height: 39px;">
                  <a class="underline" ng-href="{{vm.defaultData.SepaCodesLink}}" target="_blank" translate="domesticPayment.SEPAcodesLink">
                  </a>
                </div>
                <div class="p-l-30 " style="line-height: 39px;">
                  <a class="underline" ng-href="{{vm.defaultData.BankChargesLink}}" target="_blank" translate="domesticPayment.checkBankCharges">
                  </a>
                </div>
              </section>
              <div class="row m-t-10">
                <div class="col-xs-6 t-a-l">
                  <button class="btn btn-primary" translate="payments.cancel"
                          ng-click="vm.goBack()">
                  </button>
                </div>
                <div class="col-xs-6 t-a-r">
                  <button ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'" class="btn btn-default app-btn-blue pull-right domp-review-and-submit-btn"
                          ng-click="vm.confirmPayment()" translate="domesticPayment.confirmBtn"></button>
                  <button ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="btn btn-default app-btn-blue pull-right domp-review-and-submit-btn"
                          ng-click="vm.confirmPayment()" translate="domesticPayment.confirmBtnSlo" ng-disabled="vm.validateInProgress || vm.secondValidationInProgress"></button>
                </div>
              </div>

            </form>
        </div>

        <!-- VERIFY PAYMENT STEP -->
        <div ng-if="vm.step === 'verify'">
            <div data-ng-include="'e-invoices/einvoices-payment-resume.tpl.html'" />
        </div>

        <!-- FINAL STEP -->
        <div class="row domp-body-wrapper p-10" ng-if="vm.step === 'final'">

            <div class="container status-container status-border">
                <div class="row vertical-align">
                    <div class="col-sm-1 text-right">
                        <span class="glyphicon glyphicon-ok"></span>
                    </div>

                    <div class="col-sm-11 ">
                        <h2>E-invoice successfully paid</h2> You have successfully payed the E-invoice.
                    </div>
                </div>

            </div>
            <br/>
            <br/>
            <a href="#!e-invoices/overview">Go to e-invoice list</a>

        </div>
    </div>
</section>

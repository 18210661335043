(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            // http://www.xe.com/ibancalculator/sample/?ibancountry=croatia
            session: {
              inactiveUser: 'Zbog neaktivnosti isteklo Vam je vrijeme za rad u aplikaciji. Molimo prijavite se ponovo.'
            },
            common: {
	            "fileUploadLimit": "Datoteka {{fileName}} je veća od maksimalne dozvoljene veličine {{maxSize}}",
              loadMore: 'Učitaj još',
              loadLess: 'Smanji'
            },
            core: {
              'addDocument': 'Dodajte dokument',
              "filter": {
                maxDateRange: "Maksimalni raspon datuma je jedna godina."
              },
                "inputFields": {
                    IBAN: "9999 9999 9999 9999 9"
                },
                "moneyFormat": {
                    mask: "#.###.##0,00",
                    placeholder: "eg: 000.000,00"
                },
                "period": {
                    today: "Danas",
                    yesterday: "Jučer",
                    thisWeek: "Sedam dana",
                    thisMonth: "Mjesec dana",
                    thisYear: "Godina dana",
                    all: "Sve",
                    allMessages: "Sve poruke"
                },
                "paymentOrder": "Nalog broj",
                "investOrder": "Investicija broj",
                "ebookOrder": "eKnjižica broj",
                "successDelete": "je uspješno izbrisan.",
                "investSuccessDelete": "je uspješno izbrisana.",
                "termDepositPayoutType": {
                    "2": "Pripis kamate po dospijeću",
                    "2e": "(kamata i glavnica zajedno)",
                    "16": "Mjesečna isplata kamate na dan",
                    "16e": "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakog 15 u mjesecu)",
                    "17": "Tromjesečna isplata kamate na dan",
                    "17e": "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svaka tri mjeseca 15 u mjesecu)",
                    "18": "Šestomjesečna isplata kamate na dan",
                    "18e": "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakih šest mjeseci 15 u mjesecu)",
                    "19": "Godišnja isplata kamate na dan",
                    "19e": "(pojašnjenje: ako se oročenje ugovori npr. na 15, isplata kamate je svakih godinu dana 15 u mjesecu)",
                    "3": "Isplata kamate po dospijeću",
                    "3e": "(samo kod automatskog produženja)"

                },
              "termDepositStatus": {
                "realised": "Realiziran",
                "unrealised": "Neostvaren",
                "cancelled": "Otkazan",
                "receivednotexecuted":"U obradi"
              },
              "confirmation": "Reklamacija na transakciju je uspješno zaprimljena u bazu podataka.",
              "error": "Greška",
              sweetAlertTranslation: {
                yes: "Da",
                no: "Ne",
                areYouSure: "Jeste li sigurni?"
              },
              logoutModalTranslation: {
                logoutBtn: "Da",
                logoutMsg: "Jeste li sigurni da se želite odjaviti ?",
                "cancel":"Ne"
              },
              "sameAccounts": "Greška: Isti računi!",
              paymentSignedStatus:{
                  "InProcessing":"U obradi"
              },
              "today":"Danas",
              "noAccounts": "Nema dostupnih računa",
              datePicker: {
                "doneBtn":"Uredu",
                "clearBtn":"Obriši"
              }
            }
        };

        $translateProvider
            .translations('hr', data);

    }
}());

(function () {
    'use strict';

    /**
     * @ngdoc service
     * @name core.factory:HelperService
     *
     * @description
     *
     */
    angular
        .module('core')
        .factory('HelperService', HelperService);

    function HelperService($rootScope, cfpLoadingBar, $localStorage, $location, $filter, $translate, 
        $window, $state) {
        var HelperServiceBase = {};

        HelperServiceBase.isPasswordChangeRequested = false;

        HelperServiceBase.extractMerchantData = function(transaction) {
            if (transaction.merchant) {
                var merchant = transaction.merchant;
                var merchantLogos = merchant.merchantLogos;
                if (merchantLogos) {
                    var merchantLogo = merchantLogos.find(function(logo) {return logo.type == 'MERCHANT'} );
                    transaction.transactionLogo = merchantLogo ? merchantLogo.url : merchantLogos[0].url;
                }
      
                transaction.merchantName = merchant.merchantName;
                if (merchant.addressLine1) {
                    transaction.merchantAddress = merchant.addressLine1;
                }
      
                if (merchant.phone) {
                    transaction.merchantContact = merchant.phone;
                }
                if (merchant.websiteUrl) {
                    transaction.websiteUrl = merchant.websiteUrl;
                }
                if (merchant.merchantLocationLatitude && merchant.merchantLocationLongitude) {
                    transaction.merchantCoordinates = { lat: +merchant.merchantLocationLatitude, lng: +merchant.merchantLocationLongitude };
                }
            }
            // Declare holder var
            var iconName = '';
            // If logo assigned, skip the switch, its the custom image
            if (transaction.transactionLogo) {
                iconName = 'custom';
            } else if($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation === 'sr' && transaction.paymentType === '2') {
                iconName = 'posips';
            } else {
                // Icons are assigned according to the transactionSource field
                switch (transaction.transactionSourceEnum) {
                    case 'POS':
                        iconName = 'pos';
                        break;
                    case 'ATM':
                        iconName = 'atm';
                        break;
                    case 'StandingOrder':
                        iconName = 'standing-order';
                        break;
                    case 'SDD':
                        iconName = 'sdd';
                        break;
                    case 'FeesAndInterests':
                        iconName = 'fees';
                        break;
                    default:
                        iconName = '';
                }
                // If icon contains any value, add dash
                if (iconName.length) {
                    iconName += '-';
                }
                // Append credit/debit sufix 
                if (transaction.credit != null) {
                    iconName += 'priliv';
                } else {
                    iconName += 'odliv';
                }
            }
      
            transaction.icon = iconName;
        }

        HelperServiceBase.getAmountClean = function (amountString) {
            if (amountString != undefined) {
                if (amountString.indexOf(",") != -1) {
                    var amount = (amountString.replace('.', '').replace(',', '')) / 100;
                    //$log.debug(amountString, amount);
                    return amount;
                } else {
                    //$log.debug(amountString);
                    return amountString;
                }
            }
        };

        HelperServiceBase.initializeMTokenSignature = function(scope, signCallback, cancelCallback) {
            var controllerScope = this;
            
            $rootScope.$on('mtoken.confirm', function(event, data) {
                if(controllerScope.signInProgress) {
                    return;
                }
                controllerScope.signInProgress = true;
                controllerScope.challengeResponse = data.firstCode;
                controllerScope.challengeResponse2 = data.secondCode;
                if (signCallback) {
                    signCallback();
                }
            });
            if (cancelCallback) {
                $rootScope.$on('mtoken.cancel', cancelCallback);
            }
            scope.$on("$destroy", function () {
              $rootScope.$$listeners['mtoken.confirm'] = [];
              if (cancelCallback) {
                    $rootScope.$$listeners['mtoken.cancel'] = [];
              }
            });
        
        }

        HelperServiceBase.loadChallengeResponse = function (responseObj, context) {
            var challengeData = responseObj.challenge;
            if (challengeData.includes('challange1') || challengeData.includes('challenge1')) {
                var challengeResponse = JSON.parse(challengeData);
                context.challengeFirstCode = challengeResponse.challange1;
                context.challengeSecondCode = challengeResponse.challange2;
          
                if (responseObj.qrChallenge) {
                    context.qrChallenge = responseObj.qrChallenge;
                    context.showChallenge = false; // NOTE: This is canceled in order to prevent additionalData being sent on signSinglePayment() method
                } else {
                    context.qrChallenge = null;
                    context.showChallenge = true;
                }
            } else {
                context.challengeFirstCode = challengeData;
                context.qrChallenge = null;
                context.showChallenge = true;
            }
           
          }

        HelperServiceBase.validateFileMaxSize = function (files, maxSize) {
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                if (file.size > maxSize) {
                    return file;
                }
            }

            return null;
        }

        HelperServiceBase.isNuN = function(item) {
            return item !== null && item !== undefined;
        }

        HelperServiceBase.getToday = function () {
            //var firstday = new Date(new Date().toJSON().slice(0, 10));
            var firstday = new Date();
            firstday.setHours(0, 0, 0, 0);
            var lastday = new Date();
            //var timeOffset = lastDay.getTimezoneOffset() * 60000;
            //if (timeOffset < 0) {
            //  var lastDayWithOffset = new Date(lastDay.getTime() - timeOffset);
            //} else if (timeOffset > 0) {
            //  var lastDayWithOffset = new Date(lastDay.getTime() + timeOffset);
            //}
            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.getTodayWithoutOffset = function () {
            var firstday = new Date(),
                lastDay = new Date;
            firstday.setHours(0, 0, 0, 0);
            return {
                firstDay: firstday,
                lastDay: lastDay
            }
        };

        HelperServiceBase.getYesterdayWithoutOffset = function () {

            var curr = new Date();
            var firstday = new Date(curr.setDate(curr.getDate() - 1));
            firstday.setHours(0, 0, 0, 0);
            var lastday = new Date(firstday);
            lastday.setHours(23, 59, 59, 999);

            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.getYesterday = function () {
            var curr1 = new Date();
            var curr2 = new Date();
            //var lastDayWithOffset, firstDayOffset;
            //var firstDayWithOffset;
            var firstDay = new Date(curr1.setDate(new Date().getDate() - 1));
            firstDay.setHours(0, 0, 0, 0);
            var lastDay = new Date(curr2.setDate(new Date().getDate() - 1));
            lastDay.setHours(23, 59, 59, 999);
            //firstDayOffset = firstDay.getTimezoneOffset() * 60000;

            //if(firstDayOffset < 0){
            //  firstDayWithOffset = new Date(firstDay.getTime() - firstDayOffset);
            //} else if (firstDayOffset > 0){
            //  firstDayWithOffset = new Date(firstDay.getTime() + firstDayOffset)
            //}
            //
            //var timeOffset = lastDay.getTimezoneOffset() * 60000;
            //if (timeOffset < 0) {
            //  lastDayWithOffset = new Date(lastDay.getTime() - timeOffset);
            //} else if (timeOffset > 0) {
            //  lastDayWithOffset = new Date(lastDay.getTime() + timeOffset);
            //}
            return {
                firstDay: firstDay,
                lastDay: lastDay
            }
        };

        HelperServiceBase.getThisWeek = function () {
            var curr = new Date();
            /*var firstday = new Date(curr.setDate(curr.getDate() - curr.getDay()));
            var lastday = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));*/

            var firstday = new Date(curr.setDate(curr.getDate() - 7));
            firstday.setHours(0, 0, 0, 0, 0);
            var lastday = new Date();
            lastday.setHours(23, 59, 59, 999);

            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.checkModul97IBAN = function (iban) {
            if (!iban) {
                return false;
            }

            if (iban.length < 5) {
                return false;
            }

            if (!HelperServiceBase.isLetter(iban[0]) || !HelperServiceBase.isLetter(iban[1])) {
                return false;
            }

            if (!HelperServiceBase.isNum(iban[2]) || !HelperServiceBase.isNum(iban[3])) {
                return false;
            }

            iban = iban.toUpperCase(); // we only process big letters
            var code = iban.substr(0, 2); // javascript original include char[0],char[1] -> iban.substring(0, 2);
            var checksum = iban.substr(2, 2); // javascript original include char[2],char[3] -> iban.substring(2, 4);
            var bban = iban.substr(4); // javascript original include from char[4] afterwards -> iban.substring(4);

            var digits = '';
            for (var i = 0; i < bban.length; i++) {
                var ch = bban[i];
                if ('0' <= ch && ch <= '9') {
                    digits = digits + ch;
                } else {
                    digits = digits + HelperServiceBase.capital2Digits(ch);
                }
            }

            for (var i = 0; i < code.length; i++) {
                var ch = code[i];
                digits = digits + HelperServiceBase.capital2Digits(ch);
            }

            digits = "" + digits + checksum;

            var checksum1 = (98 - HelperServiceBase.mod97(digits));
            var control = checksum1 % 97;
            return control === 0;
        };

        HelperServiceBase.isLetter = function (str) {
            return str.length === 1 && str.match(/[a-zA-Z]/i);
        };

        HelperServiceBase.isNum = function (str) {
            return str.length === 1 && str.match(/[0-9]/i);
        };

        HelperServiceBase.capital2Digits = function (ch) {
            var capitals = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var value = 0;
            for (var i = 0; i < capitals.length; i++) {
                value = i;
                if (ch === capitals[i]) {
                    break;
                }
            }
            value = value + 10;
            return value;
        };

        HelperServiceBase.mod97 = function (digits) {
            // console.log('digits', digits);
            var m = 0;
            for (var i = 0; i < digits.length; i++) {
                var toParse = '' + digits[i];
                m = (m * 10 + Number.parseInt(toParse)) % 97;
            }
            return m;
        };

        HelperServiceBase.getThisMonth = function () {
            var curr = new Date();
            curr.setHours(0, 0, 0, 0);

            var firstday = new Date(curr.setDate(curr.getDate() - 30));
            firstday.setHours(0, 0, 0, 0);
            var lastday = new Date();
            lastday.setHours(23, 59, 59, 999);

            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.isValidAccordingToPasswordConfig = function () {
            if (this.isPasswordChangeRequested && $state.$current.url.pattern.includes('settings')) {
                return false;
            }
            return true;
        };


        // This month will return last 30 days
        HelperServiceBase.getLast30Days = function () {
            var curr = new Date();
            var firstDay = new Date(curr.setDate(curr.getDate() - 30))
            var lastDay = new Date();
            return {
                firstDay: firstDay,
                lastDay: lastDay
            }
        };

        HelperServiceBase.getThisYear = function () {
            var date = new Date();
            date.setDate(new Date().getDate() - 365);
            // var firstday = new Date(date.getFullYear(), 0, 1);
            // var lastday = new Date(date.getFullYear(), 11, 31);
            var firstday = new Date(date);
            var lastday = new Date();

            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.getAllFrom2LastYears = function () {
            var date = new Date();
            var firstday = new Date(date.getFullYear() - 2, date.getMonth(), date.getDay());
            var lastday = new Date();
            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.get6Months = function () {
            var date = new Date();
            var firstday = new Date(date.getFullYear(), date.getMonth() - 6, date.getDay());
            var lastday = new Date();
            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.getAllFrom3LastYears = function () {
            var date = new Date();
            var firstday = new Date(date.getFullYear() - 3, date.getMonth(), date.getDay());
            var lastday = new Date();
            return {
                firstDay: firstday,
                lastDay: lastday
            }
        };

        HelperServiceBase.setCustomMonthPeriodByDays = function (date, days) {
            date.setDate(date.getDate() + days);
            return date;
        };

        HelperServiceBase.setCustomMonthPeriod = function (date, months) {
            date.setMonth(date.getMonth() + months);
            return date;
        };

        HelperServiceBase.setCustomNegativeMonthPeriod = function (date, months) {
            date.setMonth(date.getMonth() - months);
            return date;
        };

        HelperServiceBase.parseAmountInput = function (amount) {
            if (typeof amount === 'number') {
                amount = amount.toString();
            }
            var lng = $translate.use();
            if (amount) {
                if (lng === 'hr' || lng === 'sr' || lng === 'mn' || lng === 'mne') {
                    return parseFloat(amount.replace('.', '').replace(',', '.'));
                }
                return parseFloat(amount.replace(',', ''));
            }
        };

        HelperServiceBase.mergeRowsForTimeline = function (params) {

            var existingData, newData, groupFunction, virtualRowFunction, reverseGroupation;
            existingData = params.existingData;
            newData = params.newData;
            groupFunction = params.groupFunction;
            virtualRowFunction = params.virtualRowFunction;
            if (params.reverse === undefined) {
                reverseGroupation = true;
            } else {
                reverseGroupation = params.reverse;
            }


            if (existingData == undefined || params.reset) {
                existingData = {
                    hasMore: false,
                    result: []
                };
            }

            var all = [];
            var index = 0;

            if (existingData != undefined && existingData.result != undefined && existingData.result.length > 0) {
                index = existingData.result[existingData.result.length - 1].virtualIndex;
            }
            $(newData.result).each(function (i, e) {
                index++;
                e.virtualIndex = index;
            });
            var groupedData = _.groupBy(newData.result, function (item) {
                var groupationKey = groupFunction(item);
                return groupationKey;
            });

            var keys = _.keys(groupedData); //.reverse();
            if (params.reverse !== undefined && params.reverse === true) {
                keys = _.keys(groupedData).reverse();
            }

            //$log.debug("Grouped data: ", groupedData, "keys:", keys);

            $(keys).each(function (i, e) {
                var items = groupedData[e];
                //$log.debug("children: ", e, items);
                var newItem = virtualRowFunction(e);
                newItem.virtualGroupationKey = e;
                all.push(newItem);
                $(items).each(function (j, je) {
                    je.virtualGroupationKey = e;
                    all.push(je);
                });
            });

            //$log.debug("all: ", all);

            if (existingData != undefined) {
                if (existingData.result != undefined && existingData.result.length > 0 && all.length > 0) {
                    var virtualGroupationKeyFromLastExistingElement = existingData.result[existingData.result.length - 1].virtualGroupationKey;
                    var virtualGroupationKeyFromFirstNewElement = all[0];

                    if (virtualGroupationKeyFromLastExistingElement == virtualGroupationKeyFromFirstNewElement) {
                        //add items to existing group
                    }
                }
            }
            var concatenatedData = [];
            if (existingData != undefined && existingData.result != undefined) {
                concatenatedData = existingData.result.concat(all);
            } else {
                concatenatedData = concatenatedData.concat(all);
            }


            //$log.debug("result: ", newData, "all: ", all, "concatenated: ", concatenatedData);
            return {
                hasMore: newData.hasMore,
                result: concatenatedData
            };
        };

        HelperServiceBase.getDateTimeAsString = function (dateTime) {
            var isoDate = dateTime.toISOString();
            var time = isoDate.split("T");
            return {
                date: time[0],
                time: time[1]
            };
        };

        HelperServiceBase.getDateRange = function (timePeriod) {
            var range = null;
            switch (timePeriod) {
                case "today":
                    range = HelperServiceBase.getToday();
                    break;
                case "yesterday":
                    range = HelperServiceBase.getYesterday();
                    break;
                case "thisWeek":
                    range = HelperServiceBase.getThisWeek();
                    break;
                case "thisMonth":
                    range = HelperServiceBase.getThisMonth();
                    break;
                case "thisYear":
                    range = HelperServiceBase.getThisYear();
                    break;
                case "all2y":
                    range = HelperServiceBase.getAllFrom2LastYears();
                    break;
                case "all":
                    // if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl') {
                    //   range = HelperServiceBase.getAllFrom3LastYears();
                    // }
                    break;
                case "6months":
                    range = HelperServiceBase.get6Months();
                    break;
            }

            if (range) {
                return {
                    valueDateGTE: range.firstDay,
                    valueDateLTE: range.lastDay
                }
            } else {
                return {
                    valueDateGTE: null,
                    valueDateLTE: null
                }
            }
        };

        HelperServiceBase.validatePaymentDate = function (date) {
            var t = new Date();
            t.setHours(0);
            t.setMinutes(0);
            t.setSeconds(0);
            if (date < t) {
                return false;
            } else {
                return true;
            }
        };

        //HelperServiceBase.isEndpointAvailable = function (endpoint) {
        //  if (_.isArray(ConfigService.disabledEndpoints) && ConfigService.disabledEndpoints.length > 0) {
        //    var element = _.find(ConfigService.disabledEndpoints, function (item){
        //      return item === endpoint;
        //    });
        //    if(element){
        //      return false;
        //    }
        //    return true;
        //  } else {
        //    return true;
        //  }
        //
        //};

        HelperServiceBase.groupAccounts = function (accounts) {
            var accounts = accounts;
            var groupAccountsByIban = _.groupBy(accounts, function (account) {
                return account.iban;
            });
            //$log.debug('Grouped account', groupAccountsByIban);
            var multiAccounts = [];
            var singleAccounts = [];
            for (var key in groupAccountsByIban) {
                // if iban is empty string - add to singleAccounts!
                if (groupAccountsByIban[key].length > 1 && key !== "") {
                    var tempObj = {
                        iban: key,
                        accounts: groupAccountsByIban[key]
                    };
                    multiAccounts.push(tempObj);
                } else {
                    _.each(groupAccountsByIban[key], function (account) {
                        singleAccounts.push(account);
                    })
                }
            }

            return {
                multiAccounts: multiAccounts,
                singleAccounts: singleAccounts
            };
        };

        HelperServiceBase.parseAccountNumber = function (accountNumber) {
            if (accountNumber) {
                return accountNumber.split(" ")[0];
            }
            return '';
        };

        HelperServiceBase.separateDecimals = function (amount) {
            if (amount) {
                var lng = $translate.use();
                if (typeof amount === 'number') {
                    amount = amount.toString();
                }
                var numbers = [];
                if (lng == 'en') {
                    numbers = amount.split('.');
                    numbers.push('.');
                } else {
                    numbers = amount.split(',');
                    numbers.push(',');
                }
                return numbers;
            }
        };

        HelperServiceBase.deleteInformationMsg = function (translationStrings, paymentId) {
            $translate(translationStrings).then(function (translations) {
                //var txt = translations['core.paymentOrder'] + ' @' + paymentId + ' ' + translations['core.successDelete'];
                if (_.isArray(translationStrings) && translationStrings.length > 1) {
                    var txt = translations[translationStrings[0]] + ' "' + paymentId + '" ' + translations[translationStrings[1]];
                    swal(txt);
                }
            });

        };

        HelperServiceBase.confirmationReclamationMsg = function (translationString) {
            $translate(translationString).then(function (tranlate) {
                if (tranlate)
                    swal(tranlate);
            });

        };

        HelperServiceBase.splitReferenceNumber = function (input) {
            console.log("SPLITIN'", input);
            var refModel = '';
            var refNum = '';
            if (input) {
                if (input.length > 2) {
                    refModel = input.substr(0, 2);
                    refNum = input.substr(2);
                } else {
                    refModel = input.substr(0, 2);
                }
                if (refModel == '00')
                    refModel = '';

                return refModel + ' ' + refNum;
            }
            return input;
        };

        // TODO: Instead of using 2 replace, do it with one. Eman
        HelperServiceBase.formatResponseBody = function (body, addReplyText) {
            var regex = /<br\s*[\/]?>/gi;
            if (body && addReplyText) {
                var replyText = "\n------------------------------------------------------------------------------------";
                body = String(body).replace(regex, "\n");
                body = body ? '\n' + String(body).replace(/<[^>]+>/gm, '') : '';
                body = body.replace(/&nbsp;/g, ' ');
                return replyText + body;
            }
            if (body) {
                body = String(body).replace(regex, "\n");

                body = body ? String(body).replace(/<[^>]+>/gm, '') : '';
                body = body.replace('↵', "\n");
                body = body.replace(/&nbsp;/g, ' ');
            }
            return body;
        };

        HelperServiceBase.scrollToTop = function () {
            console.log('"Vrooom" - Scrolled to top!');
            $window.scrollTo(0, 0);
        };

        // Google Tag Manager tracking using url
        HelperServiceBase.processUrlForGTM = function (type) {
            var title = $location.url().substring(1, $location.url().length);
            var urlParts = title.split("/");
            for (var i = 0; i < urlParts.length; i++) {
                var match = urlParts[i].match(/\d+/g);
                if (match) {
                    urlParts.splice(i, 1);
                }
            }
            //$log.debug("UrlParts: ", urlParts);
            var url = "";
            for (var i = 0; i < urlParts.length; i++) {
                url = url + "/" + urlParts[i];
            }
            url = url.replace("/", "");
            if (type === "payment") {
                url = url + '/toconfirm';
            } else if (type === "pageView") {
                url = $location.url();
            }
            var urlWithoutId = "";
            var titleWithoutId = "";
            var splitUrl = url.split("/");
            var lastPart = splitUrl[splitUrl.length - 1];
            if (!isNaN(lastPart)) {
                var negSlice = lastPart.length - (lastPart.length * 2);
                negSlice = negSlice - 1;
                urlWithoutId = url.slice(0, negSlice);
            } else {
                urlWithoutId = url;
            }
            //$log.debug("Url:", url);
            //$log.debug("urlWithoutId:", urlWithoutId);
            title = url.replace(/\//g, " ");
            titleWithoutId = urlWithoutId.replace(/\//g, " ");

            //$log.debug("Title:", title);
            //$log.debug("titleWithoutId:", titleWithoutId);



            return {
                titleWithoutId: titleWithoutId,
                title: title,
                urlWithoutId: urlWithoutId,
                url: url
            };
        };

        HelperServiceBase.formatIban = function (iban) {
            return iban.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()
        };

        HelperServiceBase.sortAccounts = function (accountList) { //only for SLO, sort by isFavourite DESC, isOwn DESC, EUR, nonEUR

            // START Add property for "eur" first filtering
            _.each(accountList, function (account) {
                // if (account.isParentAccount) {
                account.sortOrderId = (account.currencySymbol == "EUR") ? 1 : 2;
                // }
            });
            // END Add property for "eur" first filtering
            accountList = $filter('orderBy')(accountList, ['-isFavorite', 'accountType.group', '-isOwn', 'sortOrderId', 'currencySymbol']);

            return accountList;
        };
        //
        HelperServiceBase.prepareDownloadLinkSlo = function (url) {
            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation !== 'sl') return url;
            return [url, 'headerAcceptLanguage=' + $localStorage.appLanguage].join('&');
        };

        HelperServiceBase.detectIEEdge = function () {
            var ua = window.navigator.userAgent;

            var msie = ua.indexOf('MSIE ');
            if (msie > 0) {
                // IE 10 or older => return version number
                return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            var trident = ua.indexOf('Trident/');
            if (trident > 0) {
                // IE 11 => return version number
                var rv = ua.indexOf('rv:');
                return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }

            var edge = ua.indexOf('Edge/');
            if (edge > 0) {
                // Edge => return version number
                return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
            }

            // other browser
            return false;
        }

        function executeSafeCallback(callback, error) {
            if (!callback) {
                return;
            }

            callback(error);
        }

        HelperServiceBase.manageDownload = function (url, filenameCustom, language, callback) {
            cfpLoadingBar.start();
            var request = new XMLHttpRequest();
            var tempToken = sessionStorage.getItem("ngStorage-ct_accessToken");
            request.open('GET', url, true);
            request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
            request.setRequestHeader('Authorization', 'Bearer ' + tempToken);
            if (language) {
                request.setRequestHeader('Accept-Language', language);
            }
            request.responseType = 'blob';
            request.onload = function (a) {
                if (request.status === 200) {
                    var filename = 'file';
                    var blob = new Blob([request.response], { type: request.response.type });
                    var link = document.createElement('a');

                    if (filenameCustom) {
                        filename = filenameCustom;
                    }

                    // download
                    if (navigator.appVersion.toString().indexOf('.NET') > 0 || navigator.userAgent.indexOf("Edge") > -1) {
                        window.navigator.msSaveOrOpenBlob(blob, filename);
                        cfpLoadingBar.complete();
                        executeSafeCallback(callback);
                    } else {
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        cfpLoadingBar.complete();
                        executeSafeCallback(callback);
                    }
                } else {
                    cfpLoadingBar.complete();
                    executeSafeCallback(callback, request.response);
                }
            };
            request.send();
        }

        return HelperServiceBase;
    }
}());



(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name payments.controller:DomesticPaymentSloCtrl
   *
   * @description
   *
   */
  angular
    .module('payments')
    .controller('DomesticPaymentSloCtrl', DomesticPaymentSloCtrl);

  function DomesticPaymentSloCtrl($rootScope, $translate, CTLocalizationService, UserSettingsService, $state, $log, HelperService,
                                  PurposeCodesService, BanksService, CountriesService,
                                  PaymentTypesService, PaymentsService, ValidationService,
                                  CreditAccountRegisterService, $q, PaymentTemplatesService,
                                  ConfigEndpointService, $timeout, ReferenceModelService, PaymentFilterService,
                                  Notification, AlertService, ImagesService, HumanitarianService, NotificationService) {
    var vm = this;

    vm.dataLoaded = false;
    vm.isFromTemplate = false;
    vm.isSecondaryTemplateResponseEmpty = false;
    vm.images = {};

    vm.currentStep = 1;

    vm.paymentObject = {};
    vm.tempDompObj = {
      fromAccountSelect: {},
      purposeCodeSelect: {},
      purposeOfPaymentInput: '',
      amountInput: '',
      dateInput: new Date(),
      bicCodeInput: '',
// n = number ~ workaround for SI init
      toAccountInput: 'SI56',
      referenceCodeInput: '',
      referenceModelSelect: {},
      referenceNumberInput: '',
      payersNameInput: '',
      payersAddressInput: '',
      payersCityInput: '',
      payersSepaInput1: '',
      receiversNameInput: '',
      receiversAddressInput: '',
      receiversPostalCodeAndCityInput: '',
      receiversCountrySelect: {},
      receiversSepaInput1: '',
      isUrgentPaymentCheckbox: false,
      isInstantPaymentCheckBox: false,
      formSubmitted: false,
      receiversCountry: "SI"
    };

    vm.paymentType = 'DomesticPayment';
    vm.toAccountDisabled = false;
    vm.defaultCurrency = '';
    vm.forUpdate = false;
    vm.actionLinks = null;

    vm.paymentTypeIsLoar = false;
    vm.disableAllFields = false;
    vm.disableAllFieldsForProcessedPayment = false;
    vm.disableAllFieldsForChange = false;
    // Disabled country field by default because default IBAN (SI56) is already set
    vm.recieversCountrySelectDisabled = true;
    vm.checkingForNaturalPerson = false;

    vm.referenceList = [
      {
        value: "SI",
        name: "SI"
      },
      {
        value: "RF",
        name: "RF"
      },
      {
        value: "NRC",
        name: "NRC"
      }
    ];

    /*    vm.smsAuthentificate = false;
     vm.smsPasswordInput = '';*/
    vm.hideUrgency = false;
    vm.naturalPerson = false;
    vm.split = function (input) {
      return HelperService.splitReferenceNumber(input);
    };

    vm.datePickerOpened = false;
    vm.dateOptions = {
      minDate: new Date(),
      maxDate: HelperService.setCustomMonthPeriod(new Date(), 6)
    };

    vm.disabledSunday = true;
    vm.disabledSaturday = true;
    vm.disabled = function (date, mode) {
      return (mode === 'day' && (date.getDay() === 0 || date.getDay() === 6));
    };

    vm.bankCharges = '';
    vm.openDatePicker = function () {
      vm.datePickerOpened = true;
    };

    vm.paymentTypeGroupDomestic = {};
    vm.paymentTypesGroupFilter = {
      paymentTypeGroup: 'DomesticPayment',
      currencySymbol: "EUR"
    };

    vm.purposeCodesList = {};
    vm.purposeCodesFilter = {
      pageSize: 1000
    };

    vm.banksList = {};
    vm.banksFilter = {
      pageSize: 1000
    };

    vm.countriesCodeList = {};
    vm.countriesCodeFilter = {
      pageSize: 1000
    };

    vm.referenceModelList = [];
    vm.referenceModelFilter = {
      pageSize: 1000
    };

    vm.payeeFilter = {
      paymentTypeGroup: 'DomesticPayment',
      orderBy: 'receiverName ASC',
      page: 0,
      pageSize: 1000
    };

    vm.templateList = {};

    vm.tmpSearchText = {
      name: ''
    };

    vm.templateName = '';

    vm.fromAccountDropdown = false;
    vm.onlyNumbers = /^[0-9 ]+$/;
    vm.payeeInputField = /[^A-Za-zČčĐđŽžŠšĆć\+]*$/;

    vm.searchFilterExpanded = false;

    vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
    vm.uiMaskOptions = ValidationService.getUiMaskOptions().international.iban;
    // vm.uiMask = ValidationService.getUiMask().sl.domesticSI;
    vm.uiMask = ValidationService.getUiMask().sl.domestic;
    vm.humaniOrgList = [
      {
        name: "Humanitarna org 1"
      },
      {
        name: "Humanitarna org 2"
      },
      {
        name: "Humanitarna org 3"
      }
    ];

    var loadAccountData = function (accountId) {
      var index = _.findIndex(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (item) {
        return item.accountId == accountId;
      });
      vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[index];
    };

    //var loadPaymentDataFromTemplate = function (templateId) {
    //  if (templateId) {
    //    PaymentTemplatesService.getById(templateId)
    //      .then(function (response) {
    //        vm.tempDompObj.amountInput = $filter('number')(response.amount, 2);
    //        vm.tempDompObj.toAccountInput = response.toAccountNumber;
    //        vm.tempDompObj.purposeOfPaymentInput = response.purpose;
    //        vm.tempDompObj.receiversNameInput = response.receiverName || '';
    //        vm.tempDompObj.receiversAddressInput = response.receiverAddress1 || '';
    //        vm.tempDompObj.receiversPostalCodeAndCityInput = response.receiverCity || '';
    //        loadAccountData(response.accountId);
    //      })
    //      .catch(function (error) {
    //        NotificationService.showMessage(error, 'error');
    //      });
    //  }
    //};

    function validateIBANLength() {
      var ibanSI56 = vm.tempDompObj.toAccountInput.substring(0, 4);
      var ibanLength = vm.tempDompObj.toAccountInput.length;
      //var spaceNumber = Math.floor(ibanLength / 4);
      if (ibanSI56 && ibanSI56.toUpperCase() == "SI56") {

        vm.uiMask = ValidationService.getUiMask().sl.domesticSI;
        if (ibanLength < 19) {
          vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", false);
        } else {
          vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", true);
        }
        //vm.ibanMaxlength = 19 + spaceNumber;
      } else {
        vm.uiMask = ValidationService.getUiMask().sl.domestic;
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", true);
        //vm.ibanMaxlength = 34 + spaceNumber;

      }
    }

    vm.getPaymentTypeGroup = function () {
      return PaymentTypesService.getByGroup(vm.paymentTypesGroupFilter)
        .then(function (response) {
          vm.paymentTypeGroupDomestic = response;

          _.forEach(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (value) {
            value.optionValue = value.accountDescription + ' - ' + value.iban;
          });

          $log.debug("paymentTypeGroupDomestic", vm.paymentTypeGroupDomestic);
          if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
            vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading paymentTypeGroupDomestic!');
        });
    };

    vm.getPurposeCodesPage = function () {
      return PurposeCodesService.getPage(vm.purposeCodesFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            value.optionValue = value.id + ' - ' + value.description;
            //  value.optionValue = value.id;
            // vm.tempDompObj.purposeCodeSelect = value.id;
            // vm.tempDompObj.purposeOfPaymentInput = value.description
          });
          vm.purposeCodesList = response;
          if (vm.purposeCodesList.result.length > 0) {
            vm.tempDompObj.purposeCodeSelect = _.find(vm.purposeCodesList.result, {
              id: 'OTHR'
            });
          }
          $log.debug("purposeCodesList", vm.purposeCodesList);
        })
        .catch(function () {
          $log.catch('Error loading purpose codes!');
        });
    };

    vm.getBanksPage = function () {
      return BanksService.getPage(vm.banksFilter)
        .then(function (response) {
          vm.banksList = response;
          $log.debug("banksList", vm.banksList);
        })
        .catch(function () {
          $log.catch('Error loading banks codes!');
        });
    };


    vm.getCountriesPage = function () {
      return CountriesService.getPage(vm.countriesCodeFilter)
        .then(function (response) {

          vm.countriesCodeList = response;
          // vm.countriesCodeList.result.unshift({
          //   code: " ",
          //   countryCode: " ",
          //   countryCodeShort: " ",
          //   description: "internationalPayment.chooseCountry"
          // });

          $log.debug("countriesCodeList", vm.countriesCodeList);
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.getUserSettingsPage = function () {
      UserSettingsService.getPage(vm.userSettingsFilter)
        .then(function (response) {
          var userSettings = response.result[0];
          vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
          vm.tempDompObj.payersAddressInput = userSettings.address1;
          vm.tempDompObj.payersCityInput = userSettings.city;
        })
        .catch(function (error) {
          $log.catch(error);
        });
    };

    vm.insertedPaymentObjectDomp = {};
    var createPaymentObject = function () {

      var paymentObject = {};
      paymentObject.domesticPayment = {};
      paymentObject.sepaUpnIdentificators = {};
      paymentObject.accountId = vm.tempDompObj.fromAccountSelect.accountId;
      paymentObject.fromAccountNumber = vm.tempDompObj.fromAccountSelect.iban;
      paymentObject.toAccountNumber = (vm.tempDompObj.toAccountInput) ? vm.tempDompObj.toAccountInput.replace(/\s/g, '').toUpperCase() : "";
      paymentObject.currencySymbol = vm.tempDompObj.fromAccountSelect.currencySymbol;
      paymentObject.purpose = vm.tempDompObj.purposeOfPaymentInput;
      paymentObject.dueDate = vm.tempDompObj.dateInput;
      paymentObject.amount = vm.tempDompObj.amountInput;
      //paymentObject.paymentTypeGroup = 'DomesticPayment';
      paymentObject.paymentTypeGroup = vm.paymentType;
      paymentObject.purposeCodeDPS = vm.tempDompObj.purposeCodeSelect.id;
      paymentObject.domesticPayment.receiverName = vm.tempDompObj.receiversNameInput;
      paymentObject.domesticPayment.receiverAddress1 = vm.tempDompObj.receiversAddressInput;
      paymentObject.domesticPayment.receiverCity = vm.tempDompObj.receiversPostalCodeAndCityInput;
      paymentObject.domesticPayment.receiverCountry = vm.tempDompObj.receiversCountry;
      paymentObject.domesticPayment.urgency = vm.tempDompObj.isUrgentPaymentCheckbox ? '1' : '0';
      paymentObject.domesticPayment.instant = vm.tempDompObj.isInstantPaymentCheckBox ? '1' : '0';
      paymentObject.domesticPayment.referenceFrom = 'NRC';

      paymentObject.sepaUpnIdentificators.debtorName = vm.tempDompObj.payersSepaInput1;
      //paymentObject.sepaUpnIdentificators.debtorName2 = vm.tempDompObj.payersSepaInput2;
      paymentObject.sepaUpnIdentificators.creditorName = vm.tempDompObj.receiversSepaInput1;
      //paymentObject.sepaUpnIdentificators.creditorName2 = vm.tempDompObj.receiversSepaInput2;

      //paymentObject.paymentSubTypeGroup = "DomesticPayment";

      var referenceNumber = vm.referenceHR + vm.tempDompObj.referenceModelSelect;

      if (vm.tempDompObj.referenceModelSelect) {
        if (vm.tempDompObj.referenceModelSelect === "  " && vm.referenceHR != 'NRC') {
          paymentObject.domesticPayment.referenceTo = vm.referenceHR + '00' + vm.tempDompObj.referenceNumberInput;
        } else
          paymentObject.domesticPayment.referenceTo = referenceNumber.concat(vm.tempDompObj.referenceNumberInput);
      } else if ((!referenceNumber) && (vm.tempDompObj.referenceNumberInput)) {
        referenceNumber = '00';
        paymentObject.domesticPayment.referenceTo = referenceNumber.concat(vm.tempDompObj.referenceNumberInput);
      } else {
        paymentObject.domesticPayment.referenceTo = vm.referenceHR + '00';
      }

      if (vm.referenceHR == 'RF')
        paymentObject.domesticPayment.referenceTo = vm.referenceHR + vm.tempDompObj.referenceNumberInput;

      if (vm.referenceHR == 'NRC')
        paymentObject.domesticPayment.referenceTo = vm.referenceHR;

      paymentObject.beneficiarySWIFTCode = vm.tempDompObj.beneficiarySWIFTCode;


      if ($state.params.myParam == 'changeProcessed') {
        paymentObject.relatedSourceType = 'ChangePayment';
      }

      return paymentObject;

    };

    vm.insertedPaymentObject = {};


    function postPaymentAction(response) {
      vm.insertedPaymentObject = response;
      vm.insertedPaymentObjectDomp.referenceTo = vm.insertedPaymentObject.referenceTo;
      Notification.clearAll();
      vm.currentStep = 2;
    }

    vm.submitPayment = function () {
      //workaround for situation when ng-blur is triggered by submitPayment
      //requests collision happens
      if (vm.checkingForNaturalPerson) {//wait for checkIfNaturalPerson to finish - then vm.checkingForNaturalPerson will be false
        setTimeout(vm.submitPayment, 50);//wait 50 millisecnds then recheck
        return;
      }

      if (vm.nextButtonPressed || vm.checkingForNaturalPerson) {
        return;
      }
      vm.tempDompObj.formSubmitted = true;
      validateIBANLength();
      if (!vm.domesticPaymentForm.$valid) {
        return;
      } else {
        if (vm.instantDateCheck()) {
          return;
        }
      }
      // If not natural person (not mini form) - don't check
      if (!vm.naturalPerson && (vm.referenceHR == "SI" && vm.tempDompObj.referenceModelSelect.split(' ').join('') === '')) {
        return;
      }

      if (!vm.formSubmitted) {
        vm.formSubmitted = true;
        var objectForInsert = createPaymentObject();
        sessionStorage.setItem('paymentSubTypeGroup', 'DomesticPayment');

        if (!$state.params.myParam || $state.params.myParam !== 'changeProcessed')
          var insertedDueDate = objectForInsert.dueDate;

        vm.nextButtonPressed = true;
        PaymentsService.insertPayment(objectForInsert)
          .then(function (response) {
            vm.tempDompObj.isUrgentPaymentCheckbox = response.domesticPayment.urgency === '1';
            vm.tempDompObj.isInstantPaymentCheckBox = response.domesticPayment.instant === '1';
            if (response.dueDateChanged) {
              AlertService.confirmationAlert({
                text: "payments.paymentDueDateChangedMsg"
              })
                .then(function (accept) {
                  if (!accept) return;
                  secondValidate(response);
                })
            } else {
              postPaymentAction(response);
            }
          })
          .catch(function (response, error) {
            if (response && response.hasErrors) {
              NotificationService.showMessage(response, 'error');
            }
            else if (response && response.hasWarnings) {
              //show action message modal
              var isAmountWarning = response.resultList[0].key == 'Amount';
              if (isAmountWarning) {
                NotificationService.showMessage(response, 'error');
              } else {
                PaymentsService.showModalWithActions(response, objectForInsert, true, objectForInsert.domesticPayment.urgency);
              }
            } else {
              NotificationService.showMessage(response, 'error');
            }
          })
          .finally(function () {
            vm.formSubmitted = false;
            vm.forUpdate = false;
            vm.nextButtonPressed = false;
          });
      }
    };

    function secondValidate(validatedUPOrder) {
      if (vm.secondValidateInProgress) return;
      vm.secondValidateInProgress = true;
      PaymentsService.insertPayment(validatedUPOrder)
        .then(function (response) {
          postPaymentAction(response);
        })
        .catch(function (error) {
          if (error && error.hasWarnings) {
            //show action message modal
            var isAmountWarning = response.resultList[0].key == 'Amount';
            if (isAmountWarning) {
              NotificationService.showMessage(response, 'error');
            } else {
              PaymentsService.showModalWithActions(error, validatedUPOrder, true, validatedUPOrder.domesticPayment.urgency);
            }
          } else {
            NotificationService.showMessage(error, 'error');
          }
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.nextButtonPressed = false;
          vm.secondValidateInProgress = false;
        });
    }


    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePayment = function () {
      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
            PaymentsService.deletePayment(vm.insertedPaymentObject.id)
              .then(function (response) {
                AlertService.deletedPaymentAlert({
                  paymentName: vm.insertedPaymentObject.id,
                  type: 'payment'
                })
                  .then(function () {
                    $state.go('payments.paymentsOverview');
                  });
              })
              .catch(function (error) {
                NotificationService.showMessage(error, 'error');
              });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.currentStep = 1;
      //      mapWithPaymentResponse();
    };

    var fillReceiversInput = function (response) {
      if (response.result.length > 0) {
        var rName = response.result[0].receiverName;
        var rAddressInput = response.result[0].receiverAddress1;
        var rPostalCodeAndCityInput = response.result[0].receiverCity;
        vm.tempDompObj.receiversNameInput = rName.substring(0, 70);
        vm.tempDompObj.receiversAddressInput = rAddressInput.substring(0, 35);
        vm.tempDompObj.receiversPostalCodeAndCityInput = rPostalCodeAndCityInput.substring(0, 35);
      } else {
        vm.tempDompObj.receiversNameInput = '';
        vm.tempDompObj.receiversAddressInput = '';
        vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      }
    };

    vm.clearSepaReceiver = function () {
      vm.tempDompObj.receiversSepaInput1 = '';
    };

    vm.instantDateCheck = function () {
      return (vm.tempDompObj.dateInput.toDateString() !== new Date().toDateString() && vm.tempDompObj.isInstantPaymentCheckBox);
    };

    vm.clearSepaPayer = function () {
      vm.tempDompObj.payersSepaInput1 = '';
    };

    /////////////////////////////CUSTOM SELECT////////////////////////////

    vm.toggleFilterSelection = function () {
      vm.searchFilterExpanded = !vm.searchFilterExpanded;
    };

    vm.searchPayee = function () {
      vm.searchFilterExpanded = true;
    };

    vm.getPayees = function () {
      PaymentTemplatesService.getPage(vm.payeeFilter)
        .then(function (response) {
          vm.payeeList = response.result;
        }).catch(function (error) {
        $log.catch(error);
      });
    };

    vm.selectPayee = function (id) {
      var payee = _.find(vm.payeeList, {
        id: id
      });

      PaymentTemplatesService.getById(id)
        .then(function (response) {

          var item = response;

          vm.searchFilterExpanded = !vm.searchFilterExpanded;

          populateCommonFields(item);
          populatePurpose(item);
          populateRefNumber(item.referenceTo);

          // vm.tempDompObj.toAccountInput = payee.toAccountNumber;
          // vm.tempDompObj.receiversAddressInput = payee.receiverAddress1;
          // vm.tempDompObj.receiversNameInput = payee.receiverName;
          // vm.tempDompObj.receiversPostalCodeAndCityInput = payee.receiverCity;
          vm.isFromTemplate = true;

          if (vm.tempDompObj.toAccountInput) {
            //dodano zbog disable-anja hitnog plaćanja
            checkForUrgency();

            vm.toAccountDisabled = true;

            vm.checkIfNaturalPersonWithoutFieldsInit(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
            // vm.getBicByIban(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));

            selectCountryByAccount(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
          }

          // vm.getBicByIban(vm.tempDompObj.toAccountInput);

          vm.checkAccountsEquality();

        }, function (error) {
          console.log(error);
        });


    };

    vm.removeSearch = function () {
      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.beneficiarySWIFTCode = '';
      vm.tempDompObj.toAccountInput = '';
      vm.naturalPerson = false;
      vm.isFromTemplate = false;
    };

    vm.searchPayeeBy = function (searchrow) {
      return (angular.$$lowercase(searchrow.receiverName).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1 ||
        angular.$$lowercase(searchrow.toAccountNumber).indexOf(angular.$$lowercase(vm.tempDompObj.receiversNameInput) || '') !== -1);
    };

    ////////////////select template component//////////////////////
    vm.searchTemplate = function () {
      vm.searchTemplateFilterExpanded = true;
      if (vm.searchTemplateFilterExpanded === true) {
        $timeout(function () {
          var searchTemplatesInput = angular.element('#searchTemplatesInput');
          searchTemplatesInput.focus();
        }, 500);
      }
    };

    vm.toggleTemplateFilterSelection = function () {
      vm.searchTemplateFilterExpanded = !vm.searchTemplateFilterExpanded;
      // if (vm.searchTemplateFilterExpanded && (!vm.templateList.result || vm.templateList.result.length === 0)) {
      //     vm.getPaymentTemplatesPage();
      // }
    };

    vm.getPaymentTemplatesPage = function () {
      return PaymentTemplatesService.getPage({
        paymentTypeGroup: 'DomesticPayment',
        page: 0,
        pageSize: 1000
      }).then(function (response) {
        vm.templateList = response;
        if (vm.templateList)
          populateTemplatesWithImages();
        $log.debug(vm.templateList);
      }).catch(function (error) {
        $log.catch(error);
      });
    };

    vm.selectedTemplate = {};

    function checkForUrgency() {
      var temp = vm.tempDompObj.toAccountInput.substring(4, 11);
      if (temp === '2500009') {
        vm.hideUrgency = true;
        vm.disabledSunday = false;
      } else {
        vm.hideUrgency = false;
        vm.disabledSunday = true;
        vm.disabled(vm.tempDompObj.dateInput, 'day');
        if (vm.tempDompObj.dateInput.getDay() === 0) {
          vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
        }
      }
    }

    vm.selectTemplate = function (id) {
      clearForm();
      PaymentTemplatesService.getById(id)
        .then(function (response) {

          vm.searchFilterExpanded = !vm.searchFilterExpanded;
          vm.searchFilterExpanded = false;
          vm.searchTemplateFilterExpanded = false;

          var item = response;

          vm.templateName = item.name;

          // filter reference model list first
          filterReferenceModelList(item.toAccountNumber);

          populateCommonFields(item);
          populatePurpose(item);
          populateRefNumber(item.referenceTo);

          if (vm.tempDompObj.toAccountInput) {
            //dodano zbog disable-anja hitnog plaćanja
            checkForUrgency();

            vm.toAccountDisabled = true;

            vm.checkIfNaturalPersonWithoutFieldsInit(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
            // vm.getBicByIban(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));

            selectCountryByAccount(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
          }


          // vm.checkIban(vm.tempDompObj.toAccountInput);

          vm.selectedTemplate.imageId = item.imageId;
          setImage(vm.selectedTemplate);

          vm.isFromTemplate = true;
          vm.checkAccountsEquality();


        }, function (error) {
          console.log(error);
        });
    };

    function populatePurpose(item) {
      if (item.purposeCode && vm.purposeCodesList.result) {
        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          return purpose.id == item.purposeCode;
        });
        if (purpose) {
          vm.tempDompObj.purposeCodeSelect = purpose;
          vm.tempDompObj.purposeOfPaymentInput = item.purpose || purpose.description;
        }
      }
    }

    function populatePurposeDPS(item) { //humanitarian payment contains purposeCodeDPS instead of purposeCode
      if (item.purposeCodeDPS && vm.purposeCodesList.result) {
        var purpose = _.find(vm.purposeCodesList.result, function (purpose) {
          return purpose.id == item.purposeCodeDPS;
        });
        if (purpose) {
          vm.tempDompObj.purposeCodeSelect = purpose;
          vm.tempDompObj.purposeOfPaymentInput = item.purpose || purpose.description;
        }
      }
    }

    function populateCommonFields(item) {
      vm.tempDompObj.toAccountInput = item.toAccountNumber;
      // vm.tempDompObj.amountInput = item.amount;

      vm.setAccountFrom(_.find(vm.paymentTypeGroupDomestic.result[0].debitAccountList, {
        accountId: item.accountId
      }));

      if (item.foreignPayment) {
        vm.tempDompObj.receiversAddressInput = item.foreignPayment.receiverAddress1;
        vm.tempDompObj.receiversNameInput = item.foreignPayment.receiverName;
        vm.tempDompObj.receiversPostalCodeAndCityInput = item.foreignPayment.receiverCity;
        vm.tempDompObj.payersSepaInput1 = item.foreignPayment.debtorName;
        //vm.tempDompObj.payersSepaInput2 = item.foreignPayment.debtorName2;
        vm.tempDompObj.receiversSepaInput1 = item.foreignPayment.creditorName;
        //vm.tempDompObj.receiversSepaInput2 = item.foreignPayment.creditorName2;
      } else {
        vm.tempDompObj.receiversAddressInput = item.receiverAddress1;
        vm.tempDompObj.receiversNameInput = item.receiverName;
        vm.tempDompObj.receiversPostalCodeAndCityInput = item.receiverCity;
        vm.tempDompObj.payersSepaInput1 = item.sepaUpnIdentificators.debtorName;
        //vm.tempDompObj.payersSepaInput2 = item.debtorName2;
        vm.tempDompObj.receiversSepaInput1 = item.sepaUpnIdentificators.creditorName;
        //vm.tempDompObj.receiversSepaInput2 = item.creditorName2;
        var receiversCountry = _.find(vm.countriesCodeList.result, {code: item.receiverCountryCode});
        if (receiversCountry) vm.tempDompObj.receiversCountry = receiversCountry.countryCode;
      }
      if (item.paymentTypeGroup == "LocalCurrencyTransfer") {
        vm.tempDompObj.purposeOfPaymentInput = item.purpose;
      }

      validateIBANLength();
    }

    var populatePaymentFields = function (payment) {

      var item = payment;

      populateCommonFields(item);
      if (item.foreignPayment) {
        populateRefNumber(item.foreignPayment.referenceTo);
      }
      populatePurpose(item);
      vm.checkIban(vm.tempDompObj.toAccountInput);

      // vm.tempDompObj.purposeOfPaymentInput = item.purpose;

      var fromAcc = _.find(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (account) {
        return account.accountId == item.accountId;
      });

      if (fromAcc)
        vm.tempDompObj.fromAccountSelect = fromAcc;


      if (vm.tempDompObj.toAccountInput) {
        //dodano zbog disable-anja hitnog plaćanja
        var temp = vm.tempDompObj.toAccountInput.substring(4, 11);
        if (temp === '2500009') {
          vm.hideUrgency = true;
          vm.disabledSunday = false;
        } else {
          vm.hideUrgency = false;
          vm.disabledSunday = true;
          vm.disabled(vm.tempDompObj.dateInput, 'day');
          if (vm.tempDompObj.dateInput.getDay() === 0) {
            vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
          }
        }

        vm.getBicByIban(vm.tempDompObj.toAccountInput);

      }


    };

    vm.humanitarianName = '';
    vm.selectedHumanitarian = {};
    vm.selectHumanitarian = function (selectedItem) {
      clearForm();

      HumanitarianService.getById(selectedItem.id)
        .then(function (response) {
          vm.donationTemplateListExpanded = false;
          vm.humanitarianName = selectedItem.name;

          var item = response;

          populateCommonFields(item);
          populateRefNumber(item.referenceTo);
          populatePurposeDPS(item);

          if (vm.tempDompObj.toAccountInput) {
            //dodano zbog disable-anja hitnog plaćanja
            var temp = vm.tempDompObj.toAccountInput.substring(4, 11);
            if (temp === '2500009') {
              vm.hideUrgency = true;
              vm.disabledSunday = false;
            } else {
              vm.hideUrgency = false;
              vm.disabledSunday = true;
              vm.disabled(vm.tempDompObj.dateInput, 'day');
              if (vm.tempDompObj.dateInput.getDay() === 0) {
                vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
              }
            }
            vm.toAccountDisabled = true;
            //vm.getBicByIban(vm.tempDompObj.toAccountInput);
            vm.checkIfNaturalPersonWithoutFieldsInit(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
            selectCountryByAccount(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
          }


          if (!vm.hideUrgency) {
            vm.tempDompObj.isUrgentPaymentCheckbox = (item.urgency === "0") ? false : true;
          }

          vm.isFromTemplate = true;
          vm.checkAccountsEquality();

        }, function (error) {
          console.log(error);
        });


    };
    // var populateRefNumber = function (number) {
    //   if (!number) return;
    //   var prefix3letters = number.substring(0, 3);
    //   var temp = vm.referenceList.find(function (item) {
    //     return item.name == prefix3letters;
    //   });
    //
    //   if (temp) {
    //     vm.referenceHR = temp.name;
    //     vm.referenceModelSelect = number.substring(3, 5);
    //     vm.referenceNumberInput = number.substring(5);
    //   } else {
    //     vm.referenceHR = number.substring(0, 2);
    //     vm.referenceModelSelect = number.substring(2, 4);
    //     vm.referenceNumberInput = number.substring(4);
    //   }
    // };

    var populateRefNumber = function (number) {
      if (!number) return;
      if (number.substring(0, 3) == "NRC") {
        vm.referenceHR = number.substring(0, 3);
        vm.tempDompObj.referenceModelSelect = number.substring(3, 5);
        vm.tempDompObj.referenceNumberInput = number.substring(5);
      } else if (number.substring(0, 2) == "SI") {
        vm.referenceHR = number.substring(0, 2);
        vm.tempDompObj.referenceModelSelect = number.substring(2, 4);
        vm.tempDompObj.referenceNumberInput = number.substring(4);
      } else if (number.substring(0, 2) == "RF") {
        vm.referenceHR = number.substring(0, 2);
        vm.tempDompObj.referenceModelSelect = "";
        vm.tempDompObj.referenceNumberInput = number.substring(2);
      }
    };

    function clearForm() {
      vm.donationTemplateListExpanded = false;
      vm.humanitarianName = '';
      vm.searchTemplateFilterExpanded = false;
      vm.templateName = '';
      vm.toAccountDisabled = false;
      vm.selectedHumanitarian = {};
      vm.selectedTemplate = {};

      vm.tempDompObj.receiversNameInput = '';
      vm.tempDompObj.toAccountInput = 'SI56';
      vm.tempDompObj.receiversAddressInput = '';
      vm.tempDompObj.receiversPostalCodeAndCityInput = '';
      vm.tempDompObj.amountInput = '';
      vm.tempDompObj.purposeCodeSelect = _.find(vm.purposeCodesList.result, {
        id: 'OTHR'
      });
      vm.tempDompObj.purposeOfPaymentInput = "";
      vm.tempDompObj.referenceNumberInput = '';
      vm.tempDompObj.beneficiarySWIFTCode = '';

      vm.isFromTemplate = false;
      vm.checkIban(vm.tempDompObj.toAccountInput);
    }

    vm.removeHumanitarian = function () {
      vm.naturalPerson = false;
      clearForm();
    };

    vm.removeTemplate = function () {
      vm.naturalPerson = false;
      clearForm();
    };

    var setImage = function (selectedTemplate) {
      if (vm.images && vm.images.length > 0) {
        if (selectedTemplate.imageId) {
          var imageObject = _.find(vm.images, function (image) {
            return image.id === selectedTemplate.imageId;
          });
          if (imageObject)
            selectedTemplate.imageDownloadLink = imageObject.downloadLink;
        }

        if (!selectedTemplate.imageId || !selectedTemplate.imageDownloadLink) {
          selectedTemplate.imageId = vm.images[0].id;
          selectedTemplate.imageDownloadLink = vm.images[0].downloadLink;
        }
      }
    };

    /////////////////from account component///////////////////////////
    vm.toggleFromAccount = function () {
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.setAccountFrom = function (item) {
      vm.tempDompObj.fromAccountSelect = (item) ? item : vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
      vm.fromAccountDropdown = false;
      if (vm.tempDompObj.fromAccountSelect && vm.tempDompObj.toAccountInput) {
        if (vm.tempDompObj.fromAccountSelect.iban && vm.tempDompObj.fromAccountSelect.iban.trim() == vm.tempDompObj.toAccountInput.trim()) {
          vm.tempDompObj.toAccountInput = "SI56";
        }
      }
      vm.checkAccountsEquality();
    };

    /*
     vm.showSMSAuthentification = function () {
     vm.smsAuthentificate = !vm.smsAuthentificate;
     };
     */

    vm.getReferenceModels = function () {
      return ReferenceModelService.getPage(vm.referenceModelFilter)
        .then(function (response) {
          _.forEach(response.result, function (value) {
            vm.referenceModelList.push(value.model);
          });
          var index = _.findIndex(vm.referenceModelList, function (item) {
            return item.model === '99';
          });

          if (index) {
            vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];
          } else {
            vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
          }
        })
        .catch(function () {
          $log.catch('Error loading bic banks codes!');
        });
    };

    vm.newPayment = function () {
      $state.go($state.current, {}, {
        reload: true
      })
    };

    vm.getDefaultDetails = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
          if (response) {
            vm.defaultCurrency = response.DefaultCurrency;
            vm.bankCharges = response.BankCharges;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.init = function () {

      /* defaultno omogucena nedjelja (disable-a se ako nalog nema 2500009)
       if (vm.tempDompObj.dateInput.getDay() == 0) {
       vm.tempDompObj.dateInput = new Date(vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1));
       }*/

      //vm.naturalPerson = true;

      if ($state.params.editable) {
        vm.insertedPaymentObject.id = $state.params.paymentId;
        vm.forUpdate = true;
      }

      vm.getPayees();
      // vm.getUserSettingsPage();

      if ($rootScope.APP_GLOBAL_SETTINGS.defaultData) {
        vm.defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
        vm.defaultCurrency = vm.defaultData.DefaultCurrency;
        vm.bankCharges = vm.defaultData.BankCharges;
      }

      if ($rootScope.APP_GLOBAL_SETTINGS.userSettings) {
        var userSettings = $rootScope.APP_GLOBAL_SETTINGS.userSettings;
        vm.tempDompObj.payersNameInput = userSettings.firstName + ' ' + userSettings.lastName;
        vm.tempDompObj.payersAddressInput = userSettings.address1;
        vm.tempDompObj.payersCityInput = userSettings.city;
      }

      // , vm.getDefaultDetails()
      var promises = [loadImages(), vm.getPurposeCodesPage(), vm.getBanksPage(), vm.getCountriesPage(), vm.getPaymentTypeGroup(), vm.getReferenceModels(), vm.getHumanitarian(), vm.getPaymentTemplatesPage()];
      $q.all(promises)
        .then(function () {

          //set default form params
          var params = {
            fromAccountList: vm.paymentTypeGroupDomestic.result[0].debitAccountList,
            purposeCodeList: vm.purposeCodesList.result,
            referenceModelList: vm.referenceModelList
          };

          switch ($state.current && $state.current.name) {
            case 'payments.domesticPayment.fromTemplate':
              if ($state.params.templateId) {
                vm.selectTemplate($state.params.templateId);
              }

              // PaymentTemplatesService.loadDomesticPaymentData($state.params.templateId, vm.tempDompObj, params)
              //     .then(function () {
              //         vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
              //
              //     })
              //     .catch(function () {
              //         $log.catch('loadDomesticPaymentData', error);
              //     });
              break;
            case 'payments.domesticPayment.fromPayment':
              PaymentsService.loadDomesticPaymentData($state.params.paymentId, vm.tempDompObj, params)
                .then(function () {
                  vm.tempDompObj.receiversNameInput = params.payeeSearchFilter;
                  if ($state.params.myParam == 'repeatCancelled') {
                    vm.tempDompObj.dateInput = new Date(vm.tempDompObj.dueDateTemp);
                    vm.disableAllFields = true;
                    vm.disableAllFieldsForProcessedPayment = true;
                    vm.isFromTemplate = true;
                  }
                  if ($state.params.myParam === 'changeProcessed') {
                    vm.tempDompObj.dateInput = new Date(vm.tempDompObj.dueDateTemp);

                    vm.disableAllFields = true;
                    vm.disableAllFieldsForProcessedPayment = false;
                    // vm.isFromTemplate = true;


                    if (vm.tempDompObj.purposeCodeSelect.id === 'LOAR') {
                      vm.paymentTypeIsLoar = true;
                      vm.disableAllFieldsForChange = true;
                    }
                  }

                  if (vm.tempDompObj.purposeCodeSelect.id === 'LOAR') {
                    vm.checkIfNaturalPerson(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
                  } else {
                    vm.checkIfNaturalPersonWithoutFieldsInit(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
                  }

                  filterReferenceModelList(vm.tempDompObj.toAccountInput);
                  populateRefNumber(vm.tempDompObj.referenceToTemp);
                  selectCountryByAccount(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));

                })
                .catch(function (error) {
                  $log.catch('loadDomesticPaymentData()', error);
                });
              break;
            case 'payments.domesticPayment':
              if (vm.paymentTypeGroupDomestic.result[0].debitAccountList.length > 0) {
                vm.tempDompObj.fromAccountSelect = vm.paymentTypeGroupDomestic.result[0].debitAccountList[0];
              }
              if (vm.purposeCodesList.result.length > 0) {
                //vm.tempDompObj.purposeCodeSelect = vm.purposeCodesList.result[0];
                //vm.tempDompObj.purposeOfPaymentInput = vm.purposeCodesList.result[0].description;
                vm.tempDompObj.purposeCodeSelect = _.find(vm.purposeCodesList.result, {
                  id: 'OTHR'
                });
                vm.tempDompObj.purposeOfPaymentInput = '';
              }
              if (vm.referenceModelList.length > 0) {
                // var index = _.findIndex(vm.referenceModelList, function (o) {
                //   return o === '99';
                // });
                // vm.tempDompObj.referenceModelSelect = vm.referenceModelList[index];

                //default value should be empty
                vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
              }
              break;
            case 'payments.domesticPayment.fromAccount':
              if ($state.params.accountId) {
                vm.tempDompObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(params.fromAccountList, $state.params.accountId);
              }
              break;
            case 'payments.domesticPayment.fromTemplatesToPay':
              if ($state.params.templateId) {
                vm.selectTemplate($state.params.templateId);
              }
              break;
            case 'payments.domesticPayment.fromInternational':
              populatePaymentFieldFromInternational($state.params.transactionDetails);
              break;
          }

          vm.dataLoaded = true; // Enable submit button when all requests finish loading
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    var isFromInternational;
    var populatePaymentFieldFromInternational = function (payment) {
      var item = payment;
      isFromInternational = true;
      populateCommonFields(item);
      if (payment.currencySymbol == "EUR") {
        vm.tempDompObj.amountInput = payment.amount;
      }
      vm.tempDompObj.purposeOfPaymentInput = payment.purpose;
      if (item.foreignPayment) {
        populateRefNumber(item.foreignPayment.referenceTo);
      }
      populatePurpose(item);
      vm.checkIban(vm.tempDompObj.toAccountInput);

      var fromAcc = _.find(vm.paymentTypeGroupDomestic.result[0].debitAccountList, function (account) {
        return account.accountId == item.accountId;
      });
      if (fromAcc)
        vm.tempDompObj.fromAccountSelect = fromAcc;
      var receiversCountry = _.find(vm.countriesCodeList.result, {code: payment.foreignPayment.receiverCountryCode});
      if (receiversCountry) vm.tempDompObj.receiversCountry = receiversCountry.countryCode;
      if (vm.tempDompObj.toAccountInput) {
        //dodano zbog disable-anja hitnog plaćanja
        var temp = vm.tempDompObj.toAccountInput.substring(4, 11);
        if (temp === '2500009') {
          vm.hideUrgency = true;
          vm.disabledSunday = false;
        } else {
          vm.hideUrgency = false;
          vm.disabledSunday = true;
          vm.disabled(vm.tempDompObj.dateInput, 'day');
          if (vm.tempDompObj.dateInput.getDay() === 0) {
            vm.tempDompObj.dateInput.setDate(vm.tempDompObj.dateInput.getDate() + 1);
          }
        }

        // vm.getBicByIban(vm.tempDompObj.toAccountInput);
        selectCountryByAccount(vm.tempDompObj.toAccountInput.replace(/\s/g, ''));
      }
    };

    // vm.checkPurposeOfPaymentLength = function () {
    //     if (vm.referenceHR == "RF") {
    //         vm.tempDompObj.referenceModelSelect = '';
    //     }
    //     if (vm.referenceHR != "NRC") {
    //         vm.tempDompObj.purposeOfPaymentInput = vm.tempDompObj.purposeOfPaymentInput.substring(0, 34)
    //     } else {
    //         vm.tempDompObj.referenceModelSelect = '';
    //         vm.tempDompObj.referenceNumberInput = '';
    //     }
    // };

    vm.setPurposeMaxlengthValue = function () {
      if (vm.referenceHR == "NRC") {
        vm.purposeMaxlengthValue = 140;
      }
      if (vm.referenceHR == 'SI' && vm.tempDompObj.referenceModelSelect == '99') {
        vm.purposeMaxlengthValue = 140;
      }
      if (vm.referenceHR != "NRC" && vm.tempDompObj.referenceModelSelect != "99") {
        vm.purposeMaxlengthValue = 140;
      }
    };

    vm.clearReferenceNumber = function () {
      if (vm.tempDompObj.referenceModelSelect == '99') {
        vm.tempDompObj.referenceNumberInput = '';
      }
    };

    vm.setRefModelToDefault = function () {
      vm.tempDompObj.referenceModelSelect = vm.referenceModelList[0];
    };


    vm.templateImagesFilter = {
      category: "Templates"
    };
    var loadImages = function () {
      return ImagesService.getImages(vm.templateImagesFilter).then(function (images) {
        if (images)
          vm.images = images.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };


    // vm.referenceHR = vm.referenceList[0].name;

    var populateTemplatesWithImages = function () {
      if (vm.images && vm.images.length > 0) {
        _.forEach(vm.templateList.result, function (template) {

          if (template.imageId) {
            var imageObject = _.find(vm.images, function (image) {
              return image.id === template.imageId;
            });
            if (imageObject)
              template.imageDownloadLink = imageObject.downloadLink;
          }

          if (!template.imageId || !template.imageDownloadLink) {
            template.imageId = vm.images[0].id;
            template.imageDownloadLink = vm.images[0].downloadLink;
          }

        });
      }
    };

    vm.getHumanitarian = function () {
      return HumanitarianService.getAll().then(function (response) {
        vm.humaniOrgList = response.result;
      }).catch(function (err) {
        $log.catch(err);
      });
    };

    function filterReferenceModelList(accountTo) {
      if (!accountTo) return;

      // If iban starts with SI56 - remove "SI" option from reference number!
      if (accountTo.toUpperCase().substring(0, 2) !== "SI") {
        vm.referenceList = [
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        // vm.referenceHR = "NRC";
      } else {
        vm.referenceList = [
          {
            value: "SI",
            name: "SI"
          },
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        // vm.referenceHR = "SI";
      }

      // if (!accountTo) {
      //   vm.referenceHR = 'NRC';
      //   return;
      // }
      // vm.tempDompObj.referenceModelSelect = vm.tempDompObj.referenceModelSelect || "";
      // vm.tempDompObj.referenceNumberInput= vm.tempDompObj.referenceNumberInput || "";
    }

    vm.checkIban = function (accountNumber) {

      //prevent ng-blur functions with disabled and populated fields
      //mainly, API requests
      if (vm.disableAllFields)
        return;

      if (!accountNumber) return;
      // If iban starts with SI56 - remove "SI" option from reference number!
      if (vm.tempDompObj.toAccountInput.toUpperCase().substring(0, 2) != "SI") {
        vm.referenceList = [
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        vm.referenceHR = "NRC";
      } else {
        vm.referenceList = [
          {
            value: "SI",
            name: "SI"
          },
          {
            value: "RF",
            name: "RF"
          },
          {
            value: "NRC",
            name: "NRC"
          }
        ];
        vm.referenceHR = "SI";
      }

      if (!accountNumber) {
        vm.referenceHR = 'NRC';
        return;
      }
      vm.tempDompObj.referenceModelSelect = vm.tempDompObj.referenceModelSelect || "";
      vm.tempDompObj.referenceNumberInput = vm.tempDompObj.referenceNumberInput || "";
    };
    vm.checkIban(vm.tempDompObj.toAccountInput);


    function selectCountryByAccount(iban) {
      var countryCode = iban.substring(0, 2).toUpperCase();
      var country;
      if (countryCode == "SI") {
        vm.tempDompObj.receiversCountry = "SI";
        //vm.recieversCountrySelectDisabled = true;
      } else {
        country = _.find(vm.countriesCodeList.result, {countryCode: countryCode});
        vm.tempDompObj.receiversCountry = (country) ? country.countryCode : "";
        //vm.recieversCountrySelectDisabled = (country) ? true : false;
      }
    }

    vm.getBicByIban = function (iban) {
      if (!iban) {
        vm.tempDompObj.beneficiarySWIFTCode = "";
        return;
      }
      // Check if IBAN to is == iban From
      if (vm.tempDompObj.fromAccountSelect.iban) {
        if (vm.tempDompObj.fromAccountSelect.iban.trim() == vm.tempDompObj.toAccountInput.trim()) {
          vm.tempDompObj.toAccountInput = "SI56";
          return;
        }
      }

      //prevent ng-blur functions with disabled and populated fields
      //mainly, API requests
      if (vm.disableAllFields)
        return;

      selectCountryByAccount(iban);

      iban = iban.replace(/\s/g, '');
      vm.checkIfNaturalPerson(iban);
      PaymentsService.getBicBanks(undefined, {
        iban: iban
      })
        .then(function (response) {
          var bicBank = response.result[0];
          if (bicBank) {
            vm.tempDompObj.beneficiarySWIFTCode = bicBank.bicCode;
          } else {
            vm.tempDompObj.beneficiarySWIFTCode = "";
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.checkIfNaturalPersonWithoutFieldsInit = function (iban) {
      CreditAccountRegisterService.getById(iban)
        .then(function (response) {
          if (_.isEmpty(response) && (iban && iban.substring(0, 6) == 'SI5633')) {
            vm.paymentType = 'LocalCurrencyTransfer';
            //vm.tempDompObj.dateInput = new Date();
            vm.disabledSunday = false;
            vm.naturalPerson = true;
          } else if (response.iban && (iban && iban.substring(0, 6) == 'SI5633')) {
            vm.paymentType = 'DomesticPayment';
            vm.disabledSunday = true;
            vm.naturalPerson = false;
          } else if (response.iban && (iban && iban.substring(0, 4) == 'SI56')) {
            vm.paymentType = 'DomesticPayment';
            vm.disabledSunday = true;
            vm.naturalPerson = false;
          } else if (_.isEmpty(response) && isFromInternational) {
            return;
          }
          else {
            vm.paymentType = 'DomesticPayment';
            //vm.tempDompObj.dateInput = new Date();
            vm.disabledSunday = false;
            vm.naturalPerson = false;
          }
          // if($state.params.myParam == 'changeProcessed' && vm.tempDompObj.purposeCodeSelect.id == 'LOAR'){
          //   vm.naturalPerson = false;
          // }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.checkIfNaturalPerson = function (iban) {
      // ako je si56 33.. i ne vrati podatke onda je fizicko lice, - i nalog je  local  internal transfer
      // ako je si56 33.. i vrati podatke onda je pravno lice  i nalog je DPS
      // ako je si56 .... i vrati podatke onda je pravno lice
      // (moze biti i fizicko), ali su nazivi primatelji obavezni, jer je to nalog u drugu banku
      // i taj nalog je dps
      vm.checkingForNaturalPerson = true;
      CreditAccountRegisterService.getById(iban)
        .then(function (response) {
          vm.checkingForNaturalPerson = false;
          if (_.isEmpty(response) && (iban && iban.substring(0, 6) == 'SI5633')) {
            vm.tempDompObj.receiversNameInput = vm.tempDompObj.receiversNameInput || '';
            vm.tempDompObj.receiversAddressInput = vm.tempDompObj.receiversAddressInput || '';
            vm.tempDompObj.receiversPostalCodeAndCityInput = vm.tempDompObj.receiversPostalCodeAndCityInput || '';
            vm.paymentType = 'LocalCurrencyTransfer';
            //vm.tempDompObj.dateInput = new Date();
            vm.disabledSunday = false;
            vm.naturalPerson = true;
          } else if (response.iban && (iban && iban.substring(0, 6) == 'SI5633')) {
            vm.paymentType = 'DomesticPayment';
            vm.tempDompObj.receiversNameInput = response.receiverName || '';
            vm.tempDompObj.receiversAddressInput = response.receiverAddress1 || '';
            vm.tempDompObj.receiversPostalCodeAndCityInput = response.receiverCity || '';
            vm.disabledSunday = true;

            vm.naturalPerson = false;
          } else if (response.iban && (iban && iban.substring(0, 4) == 'SI56')) {
            vm.tempDompObj.receiversNameInput = response.receiverName || '';
            vm.tempDompObj.receiversAddressInput = response.receiverAddress1 || '';
            vm.tempDompObj.receiversPostalCodeAndCityInput = response.receiverCity || '';
            vm.paymentType = 'DomesticPayment';
            vm.disabledSunday = true;
            vm.naturalPerson = false;
          } else if (_.isEmpty(response) && isFromInternational) {
            return;
          } else {
            vm.tempDompObj.receiversNameInput = vm.tempDompObj.receiversNameInput || '';
            vm.tempDompObj.receiversAddressInput = vm.tempDompObj.receiversAddressInput || '';
            vm.tempDompObj.receiversPostalCodeAndCityInput = vm.tempDompObj.receiversPostalCodeAndCityInput || '';
            vm.paymentType = 'DomesticPayment';
            //vm.tempDompObj.dateInput = new Date();
            vm.disabledSunday = false;
            vm.naturalPerson = false;
          }


          if (vm.isFromTemplate && _.isEmpty(response)) {
            //vm.isFromTemplate = false;
            vm.isSecondaryTemplateResponseEmpty = true;
          } else {
            vm.isSecondaryTemplateResponseEmpty = false;
          }
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.signOrFinishPayment = function () {

      // Returns true if any of the values in the list pass the predicate truth test.
      var ibanInTemplates = _.some(vm.templateList.result, function (template) {
        return template.toAccountNumber.replace(/ /g, '') == vm.tempDompObj.toAccountInput.replace(/ /g, '')
      });

      //when Changing/updating: PaymentType LOAN doesnt have otp and goes directly to final-step
      if ($state.params.myParam === 'changeProcessed' && vm.paymentTypeIsLoar) {
        //check if fromTemplate
        updatePayment();
        return;
      }

      //if route has this param it should request for PUT method
      if ($state.params.myParam == 'changeProcessed' && (ibanInTemplates || vm.isFromTemplate)) {
        //check if fromTemplate
        updatePayment();
        return;
      }

      // When I make UPN from template, the additional page should not be displayed. (Sign page should not be there)
      // When I make UPN to account, not saved in templates and is outsite Addiko bank, the additional page for OTP confirmation should appear.
      // When I make UPN to internal (SI5633) Addiko bank account of the company, the additional page for OTP confirmation should appear.
      // _.every Returns true if all of the values in the list pass the predicate truth test. Short-circuits and stops traversing the list if a false element is found.
      if (_.has(vm.insertedPaymentObject, 'linkList.itemList.requestOTP')) {

        //TSK-114327
        if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
          vm.insertedPaymentObject.paymentIdTemp = $state.params.paymentId;

        $state.go("payments.sign", {
          payment: vm.insertedPaymentObject,
          paymentIdTemp: vm.insertedPaymentObject.id,
          paymentId: vm.insertedPaymentObject.id,
          type: 'domestic'
        });

      } else {
        signPayment();
      }
    };

    function signPayment() {
      if (vm.confirmButtonPressed) return;
      var params = {
        id: vm.insertedPaymentObject.id,
        payment: vm.insertedPaymentObject
      };
      //TSK-114327
      if ($state.params.paymentId && $state.params.myParam && $state.params.myParam === 'changeProcessed')
        vm.insertedPaymentObject.paymentIdTemp = $state.params.paymentId;

      // Returns true if any of the values in the list pass the predicate truth test.
      var ibanInTemplates = _.some(vm.templateList.result, function (template) {
        return template.toAccountNumber.replace(/ /g, '') == vm.tempDompObj.toAccountInput.replace(/ /g, '')
      });
      if (ibanInTemplates) {
        vm.isFromTemplate = true;
      }

      params.payment.signData = "";
      vm.confirmButtonPressed = true;
      PaymentsService.signPayment(params).then(function (signedPayment) {
        $state.go('payments.finalStep', {
          paymentObject: signedPayment.payment || signedPayment,
          paymentId: signedPayment.id,
          type: "domestic",
          isFromTemplate: vm.isFromTemplate
        });
      }).catch(function (error) {
        $log.error(error);
        if (error && error.resultList[0] && error.resultList[0].key === 'CUTOFF') {
          signLastPayment(error.resultList[0].description, params);
        } else if (error && error.resultList[0] && error.resultList[0].key === 'UpdateOldDate') {
          openUpdateSinglePaymentDueDateModal(params);
        } else {
          NotificationService.showMessage(error, 'error');
        }
      }).finally(function () {
        vm.confirmButtonPressed = false;
      });
    }

    function updatePayment() {
      if (vm.confirmButtonPressed) return;
      var params = {
        id: $state.params.paymentId,
        payment: vm.insertedPaymentObject
      };
      vm.confirmButtonPressed = true;
      PaymentsService.updatePayment(params.id, params.payment, 'changeProcessed')
        .then(function (signedPayment) {
          $state.go('payments.finalStep', {
            paymentObject: signedPayment.payment || signedPayment,
            paymentId: signedPayment.id,
            type: "domestic"
          });
        })
        .catch(function (response, error) {
          if (response && response.hasWarnings) {
            var isAmountWarning = response.resultList[0].key == 'Amount';
            if (isAmountWarning) {
              NotificationService.showMessage(response, 'error');
            } else {
              PaymentsService.showModalWithActions(response, objectForInsert, true, params.payment.domesticPayment.urgency);
            }
            //show action message modal
          } else {
            NotificationService.showMessage(response, 'error');
          }
        })
        .finally(function () {
          vm.formSubmitted = false;
          vm.forUpdate = false;
          vm.confirmButtonPressed = false;
        });


    }

    vm.addSpaceForIban = function (toAccountInput) {

      if (!vm.tempDompObj.toAccountInput) {
        vm.uiMask = ValidationService.getUiMask().sl.domestic;
        vm.domesticPaymentForm.toAccountInput.$setValidity("minlength", true);
        return;
      }
      // vm.tempDompObj.toAccountInput = vm.tempDompObj.toAccountInput.toUpperCase();
      //   vm.tempDompObj.toAccountInput = toAccountInput.replace(/[^\dA-Za-z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      validateIBANLength();
    };


    vm.checkAccountsEquality = function () {
      var equal = false;
      var toAccountVal = (vm.tempDompObj.toAccountInput) ? vm.tempDompObj.toAccountInput.replace(/\s/g, '') : "";
      if (vm.tempDompObj.fromAccountSelect.iban == toAccountVal) {
        equal = true;
      }
      vm.domesticPaymentForm.toAccountInput.$setValidity('accountEquality', !equal);
    };

    vm.purposeMaxlengthValue = 34;

    vm.showInfo = function () {
      var message = $translate.instant('domesticPayment.urgentInfo');
      var temp = {"resultList": [{"key": "Info", "description": message}]};
      if (vm.tempDompObj.isUrgentPaymentCheckbox) {
        //NotificationService.showMessage(temp, 'info');
        PaymentsService.showModalWithActions(temp, null, null, null);
      }

    };

    vm.formatReferenceNumber = function (referenceNumber) {
      var referenceNumberFormatted;
      var referenceNumberP1 = referenceNumber.substring(0, 2);
      var referenceNumberP2;
      var referenceNumberP3;

      if (referenceNumberP1 == 'SI') {
        referenceNumberP2 = referenceNumber.substring(2, 4);
        referenceNumberP3 = referenceNumber.substring(4);
        referenceNumberFormatted = referenceNumberP1 + " " + referenceNumberP2 + " " + referenceNumberP3;
      } else if (referenceNumberP1 == "RF") {
        referenceNumberP2 = referenceNumber.substring(2);
        referenceNumberFormatted = referenceNumberP1 + " " + referenceNumberP2;
      } else {
        referenceNumberFormatted = referenceNumber.substring(0, 3);
      }

      return referenceNumberFormatted;
    };

    vm.init();
  }
}());

(function () {
  'use strict';

  angular
      .module('localization')
      .config(config);

  function config($translateProvider) {

    var data = {
      alert: {
        information: "Information",
        closeModalInfo:"Close",
        areYouSure: "Are you sure?",
        blockCardInfo: "Do you really want to block your card ?",
        blockCardCost: "It will cost you",
        blockCard: "Block card",
        payment: "Payment order",
        template: "Template order",
        termDeposit: "Term deposit",
        investment: "Investment order",
        subscriptionRequest: "You have successfully entered a request for subscription",
        successSign: "You have successfully signed payment",
        successSignSlo: "Your order has been submitted successfully.",
        closeEBook: "You have successfully closed e-book",
        cancelRegistration: "You have successfully canceled registration",
        ebookOrder: "eSaving book order",
        archiveEInvoice: "You have successfully archived e-invoice",
        saveTemplate: "You have successfully saved template",
        updateTemplate: "You have successfully updated template",
        deleteAction: "Deletion",
        cancel: "Close",
        cancelActivation: "Cancel activation",
        yes: "Yes",
        no: "No",
        success: "Success!",
        qrCode: "Generated QRCode",
        paymentsReturnText: 'There are unanswered massages in the "Payments - Payments return".',
        acceptEBook: 'Accept',
        rejectEBook: 'Reject',
        eBookWarningMessage: 'Uključite token - unesite PIN i odmah na prazan ekran (piše samo CHAL na vrhu) unesite broj koji je ispisan na ekranu' +
        ' \'Zaporka 1\', i kliknite na gumb \'OK\'. Token će ispisati zaporku 2 koju je potrebno prepisati u polje \'Zaporka 2\'.' +
        ' Kada ste završili akciju, odaberite gumb \'Prihvaćam ili Ne Prihvaćam\'.',
        cancelation: "Payment Cancelation",
        eBookWarningMessageText1: 'Ja kao Vlasnik e-knjižice sklopio sam s Bankom ugovor o otvaranju i vođenju e-knjižice, kojim je Banka na temelju zahtjeva za otvaranje i prihvata odredaba Općih uvjeta otvaranja i vođenja e-knjižice za potrošače otvorila e-knjižicu koja služi kao štedni ulog po viđenju, a na koju se obračunava promjenjiva kamatna stopa.',
        eBookWarningMessageText2: 'Dodatno, pisanim putem sam obaviješten o ponudi izmjene ugovornih uvjeta, a koja izmjena se odnosi na promjenu vrste i visine kamatne stope po mojoj e-knjižici. Upoznat sam da u slučaju prihvata izmjene ugovornih uvjeta, nova kamatna stopa je fiksna te iznosi 0,05% godišnje za novčani polog u domaćoj valuti ili 0,01% godišnje za novčani polog u stranoj valuti EUR, te se ista obračunava počevši od 17.12.2019. godine.',
        eBookWarningMessageText31: 'Ja, kao Vlasnik e-knjižice, ovim putem prihvaćam promjene ugovornih uvjeta e-knjižice koje se odnose na uvođenje fiksne kamatne stope i utvrđivanje nove visine iste, te potvrđujem da sam pročitao i da prihvaćam da se na ovaj ugovor o otvaranju i vođenju e-knjižice primijene ',
        eBookWarningMessageText32: 'Opći uvjeti otvaranja i vođenja e-knjižice za potrošače važeće za ugovore sklopljene od 01.12.2018. godine',
        eBookWarningMessageText33: ', te odredbe ',
        eBookWarningMessageText34: 'Odluke o visini pasivnih kamatnih stopa za fizičke osobe (u primjeni  od 17.10.2019. godine).',
        eBookWarningMessageText4: 'Ujedno potvrđujem da sam sukladno relevantnim zakonskim odredbama, a prije prihvaćanja dobio/la sve potrebne informacije potrebne za donošenje odluke o sklapanju aneksa ugovora o otvaranju i vođenju e-knjižice.',
        virtualizationRestriction150K: 'Sukladno Općim uvjetima za transakcijski račun sklopljen putem udaljenog pristupa, ' +
          'ukupni priljev po Vašem računu je ograničen na 150.000 HRK do dolaska u poslovnicu. ' +
          'Vaš priljev je trenutno je veći od 150.000 HRK te Vam je raspolaganje sredstvima po računu onemogućeno do dolaska u poslovnicu. ' +
          'Molimo posjetite što prije najbližu Addiko poslovnicu radi provođenja ' +
          'zakonskih odredbi o uspostavi i nastavku poslovne suradnje, a kako biste mogli koristiti svoj račun bez ograničenja',
        virtualizationRestriction100K: 'Vaš trenutni priljev po računu je veći od 100.000 HRK. Sukladno Općim uvjetima za ' +
          'transakcijski račun sklopljen putem udaljenog pristupa, ukupni priljev po Vašem računu je ograničen na 150.000 HRK ' +
          'do dolaska u poslovnicu. U trenutku kada na Vaš račun bude uplaćeno ukupno 150.000 HRK, raspolaganje sredstvima na ' +
          'Vašem računu bit će onemogućeno. Molimo posjetite što prije najbližu Addiko poslovnicu radi provođenja zakonskih ' +
          'odredbi o uspostavi i nastavku poslovne suradnje, a kako biste mogli koristiti svoj račun bez ograničenja',
        securityMessageHeading: 'Dear clients',
        securityMessageBody: 'new functionality has been implemented in Addiko EBank enabling you to have better ' +
        'control of your internet payments and a better protection against unwanted frauds. ' +
        'Payment order limitations can be set-up within the \'Settings\' menu, ' + 
        '\'Security\' submenu.',

        cardActivationInfo: "Please insert last 4 card number digits",
        cardActivationPlaceholder: "XXXX",
        confirm: "Confirm"
      },
      statementDeliveryModal: {
        modalTitle: 'Primajte izvatke digitalno!',
        message1: 'Promijenite način dostave izvadaka po Addiko Mastercard kreditnoj kartici tako da ih od sada primate digitalno umjesto poštom!',
        message2: 'Na taj način informacije o kreditnoj kartici primat ćete brže i jednostavnije, a ujedno i doprinijeti zaštiti okoliša.',
        message3: 'Izvatke po Addiko Mastercard kreditnoj kartici možete primati putem Addiko Mobile mobilnog bankarstva i/ili Addiko EBank internet bankarstva ili e-mailom.',
        message5: 'Dovoljno je odabrati kanal dostave izvadaka na kraju ove poruke kako biste ubuduće izvadak primali odabranim kanalom umjesto poštom.',
        message4: 'Odabirom opcije „PRIHVAĆAM“ dajem suglasnost za primanje izvadaka putem gore odabranog kanala dostave za svoj ugovoreni aktivni tekući/žiro račun građana, umjesto dosadašnjeg primanja izvadaka poštom. ',
        refuse: 'NE PRIHVAĆAM',
        confirm: 'PRIHVAĆAM',
        HYPOnet: 'Izvadak na mobilno/internet bankarstvo',
        chooseChannel: 'Kanal dostave izvadaka',
        eMail: 'Izvadak na e-mail'
      }
    };

    $translateProvider
        .translations('en', data);

  }
}());

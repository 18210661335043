(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name accounts.controller:NewEBookCtrl
   *
   * @description
   *
   */
  angular
    .module('accounts')
    .controller('NewEBookCtrl', NewEBookCtrl);

  function NewEBookCtrl($rootScope, CTLocalizationService, AccountsService, $state, $log, ESavingBookRequestsService,
                        $localStorage, $translate, $scope, ValidationService, HelperService, PaymentFilterService, Notification, ConfigEndpointService, AlertService,GoogleTagManagerHelperService,$location, NotificationService) {
    var vm = this;
    vm.ctrlName = 'NewEBookCtrl';
    vm.forUpdate = false;
    vm.currentStep = 1;
    vm.challengeResponse = '';
    vm.documents = [];
    vm.countryCode = $localStorage.code;
    vm.generalTerms = $state.params.myParam;
    var defaultData = $rootScope.APP_GLOBAL_SETTINGS.defaultData;
    vm.show_eSavingsDocuments = (defaultData.EsavingsDocumentsFrom && new Date() > new Date(defaultData.EsavingsDocumentsFrom));
    vm.additionalLinks = {
      link1: defaultData.GeneralTermsAndConditions,
      link2: defaultData.Interests_rates,
      link3: defaultData.Fees_info,
      link4: defaultData.eSavings_generalTerms,
      link5: defaultData.Deposit_basicInfo,
    };
    vm.generalTerms = '';
    vm.eBookRequests = true;
    vm.tempObj = {
      fromAccountSelect: {},
      nameInput: '',
      formSubmitted: false,
      checked: false,
      checked2: false,
      checked3: false,
      checked4: false,
      checked5: false

    };

    vm.eSavingBookRequestsFilter = {};
    vm.allRequests = false;
    vm.fromAccountDropdown = false;
    vm.accountsFrom = [];
    vm.defaultCurrency = defaultData.DefaultCurrency;

    vm.currentAccountFilter = {
      page: 0,
      pageSize: 1000,
      accountTypeGroup: 'CurrentAccount',
      currencySymbol: ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "hr") ? vm.defaultCurrency: null
    };


    vm.toggleFromAccount = function () {
      vm.fromAccountDropdown = !vm.fromAccountDropdown;
    };

    vm.getCurrentAccountPage = function () {
      AccountsService.getPage(vm.currentAccountFilter)
        .then(function (response) {
          vm.accountsFrom = response.result;
          if (vm.accountsFrom.length > 0) {
            vm.tempObj.fromAccountSelect = PaymentFilterService.filterFromAccountSelect(vm.accountsFrom, $state.params.accountId);
            if (vm.allRequests === false) {
              vm.eSavingBookRequestsFilter = {
                page: 0,
                pageSize: 10,
                accountId: vm.tempObj.fromAccountSelect.accountId,
                orderBy: 'dateOfCreation desc'
              };
            } else {
              vm.eSavingBookRequestsFilter = {
                page: 0,
                pageSize: 10,
                orderBy: 'dateOfCreation desc'
              };
            }
          }
        })
        .catch(function (error) {
          $log.error(error);
        });
    };

    var createPaymentObject = function () {
      return {
        eSavingBookName: vm.tempObj.nameInput,
        mainAccountId: vm.tempObj.fromAccountSelect.accountId
      };
    };

    var sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    $rootScope.$on('appLanguageChangedHeader', function () {
      sweetAlertOptions = CTLocalizationService.getSweetAlertOptions();
    });

    vm.deletePayment = function () {


      AlertService.confirmationAlert()
        .then(function (result) {
          if (result) {
                ESavingBookRequestsService.deleteESavingBookRequest(vm.insertedPaymentObject.id)
                  .then(function (response) {
                    AlertService.deletedPaymentAlert({paymentName: vm.insertedPaymentObject.id, type: 'eSavingBook'})
                      .then(function () {
                        $state.go('dashboard');
                      });
                  })
                  .catch(function (error) {
                    NotificationService.showMessage(error, 'error');
                  });
          }
        })
        .catch(function () {
          $log.debug('Example failed!');
        });

    };

    var mapWithPaymentResponse = function () {
      vm.tempObj.nameInput = vm.insertedPaymentObject.eSavingBookName;
      vm.tempObj.checked = false;
    };

    vm.editPayment = function () {
      vm.forUpdate = true;
      vm.currentStep = 1;
      mapWithPaymentResponse();
    };

    vm.insertedPaymentObject = {};
    var postPaymentAction = function (response) {

      vm.insertedPaymentObject = response;
      HelperService.loadChallengeResponse(response, vm);
      vm.insertedPaymentObject.createdBy = response.createdBy;
      vm.insertedPaymentObject.dateOfCreation = response.dateOfCreation;
      vm.insertedPaymentObject.id = response.id;
      vm.insertedPaymentObject.mainAccountNumber = response.mainAccountNumber;
      vm.insertedPaymentObject.eSavingBookName = response.eSavingBookName;
      Notification.clearAll();

    };

    vm.newRequestSubmit = function () {
      vm.tempObj.formSubmitted = true;
      if (!vm.newEBookForm.$valid) {
        return;
      }
      var tempEbookObj = createPaymentObject();

      if (vm.forUpdate) {
        tempEbookObj.id = vm.insertedPaymentObject.id;
        ESavingBookRequestsService.updateESavingBookRequest(vm.insertedPaymentObject.id, tempEbookObj)
          .then(function (response) {
            postPaymentAction(response);
            vm.currentStep = 2;
            vm.forUpdate = false;
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
            vm.forUpdate = false;
          });
      } else {
        ESavingBookRequestsService.insertEbook(tempEbookObj)
          .then(function (response) {
            postPaymentAction(response);
            HelperService.initializeMTokenSignature.call(vm, $scope, vm.signConfirmAction);
            vm.currentStep = 2;
            var gtmObject = HelperService.processUrlForGTM('payment');
            GoogleTagManagerHelperService.pushVirtualPageView(gtmObject.url, gtmObject.title, $location.host() + gtmObject.url);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      }
    };

    vm.setAccountFrom = function (item) {
      vm.tempObj.fromAccountSelect = item;
      vm.fromAccountDropdown = false;
      vm.loadRequestsOnCheckbox();
    };

    vm.sign = function () {
      ESavingBookRequestsService.signESavingBookRequest(vm.insertedPaymentObject.id)
        .then(function (response) {
          vm.currentStep = 3;
          GoogleTagManagerHelperService.pushEvent('NewEbook', 'NewEbookRequestSubmited', 'New Ebook request submited successfully');
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.goToSignStep = function () {

      vm.currentStep = 4;
    };

    vm.signConfirmAction = function () {
      ESavingBookRequestsService.signESavingBookSign(vm.insertedPaymentObject.id, vm.challengeResponse, vm.challengeResponse2)
          .then(function (response) {
          GoogleTagManagerHelperService.pushEvent('NewTermDeposit', 'NewTermDepositRequestSubmited', 'New term deposit request submited successfully');
            vm.currentStep = 3;
            vm.signInProgress = false;
          })
          .catch(function (error) {
              NotificationService.showMessage(error, 'error');
              vm.signInProgress = false;
            });
    };

    vm.manageDocumentDownload = function (documentType, id, accountId) {
      var tempParamId = id ? id : (vm.insertedPaymentObject && vm.insertedPaymentObject.id) ? vm.insertedPaymentObject.id : '';
      var tempParamAccountId = accountId? accountId : (vm.insertedPaymentObject && vm.insertedPaymentObject.accountId) ? vm.insertedPaymentObject.accountId : (vm.tempObj && vm.tempObj.fromAccountSelect && vm.tempObj.fromAccountSelect.accountId) ? vm.tempObj.fromAccountSelect.accountId : '';
      ESavingBookRequestsService.getESavingBookRequests_downloadByFilter(tempParamId, documentType, tempParamAccountId)
          .then(function (response) {})
          .catch(function (error) {
              NotificationService.showMessage(error, 'error');
          });
    };

    var filterEBookSavingRequest = function (reset) {
      if (vm.eBookSavingsRequests.result && vm.eBookSavingsRequests.result.length > 0) {

        if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation == "hr" && vm.show_eSavingsDocuments) {
          vm.eBookSavingsRequests.result.forEach(function(item) {

            if ($rootScope.APP_GLOBAL_SETTINGS.defaultData.EsavingsDocumentsFrom &&
              new Date($rootScope.APP_GLOBAL_SETTINGS.defaultData.EsavingsDocumentsFrom) < new Date(item.dateOfCreation)) {
              item['documents'] = [];
              if (item.statusEnum && (item.statusEnum === 'Opened' || item.statusEnum === 'InProcessing' || item.statusEnum === 'Rejected' || item.statusEnum === 'Processed')) {
                item['documents'].push({ name: 'DocumentRequest' , translations: 'ebook.doc1'});
                item['documents'].push({ name: 'GeneralTermsAndCOnditions' , translations: 'ebook.termsAndConditions', link: $rootScope.APP_GLOBAL_SETTINGS.defaultData.eSavings_generalTerms});
                item['documents'].push({ name: 'Deposit' , translations: 'ebook.informationFormOnDeposits', link: $rootScope.APP_GLOBAL_SETTINGS.defaultData.Deposit_basicInfo});
              }
              item['documents'].push({ name: 'PreContractForm' , translations: 'ebook.doc2'});
            }

          });
        }

        vm.eBookSavingsGrouped = HelperService.mergeRowsForTimeline({
          existingData: vm.eBookSavingsGrouped,
          newData: vm.eBookSavingsRequests,
          groupFunction: function (item) {
            var date = new Date(item.dateOfCreation);
            date.setHours(0, 0, 0, 0);
            return date;
          },
          virtualRowFunction: function (e) {
            var month = new Date(e).getMonth() + 1;
            var day = new Date(e).getDate();
            var virtualRow = {
              isVirtual: true,
              date: new Date(e),
              month: month,
              day: day
            };
            $log.debug("virtualRow", e, virtualRow);
            return virtualRow;
          },
          reset: reset,
          reverse: false
        });
        $log.debug("eBookSavingsGrouped", vm.eBookSavingsGrouped);
      }
    };

    var loadeSavingBookRequests = function (reset) {
      if (reset) {
        vm.eSavingBookRequestsFilter.page = 0;
      } else {
        vm.eSavingBookRequestsFilter.page += 1;
      }

      ESavingBookRequestsService.getESavingBookRequests(vm.eSavingBookRequestsFilter)
        .then(function (response) {
          vm.eBookSavingsRequests = response;
          filterEBookSavingRequest(reset);
        })
        .catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
    };

    vm.loadMoreSavingBookRequests = function () {
      loadeSavingBookRequests(false);
    };

    vm.displayeBookSavingRequest = true;

    vm.loadRequestsOnCheckbox = function () {
      if (vm.allRequests === false) {
        vm.eSavingBookRequestsFilter = {
          page: 0,
          pageSize: 10,
          accountId: vm.tempObj.fromAccountSelect.accountId,
          orderBy: 'dateOfCreation desc'
        };
      } else {
        vm.eSavingBookRequestsFilter = {
          page: 0,
          pageSize: 10,
          orderBy: 'dateOfCreation desc'
        };
      }
      loadeSavingBookRequests(true);
    };

    var getDefaultData = function () {
      return ConfigEndpointService.getDefaultData()
        .then(function (response) {
        if (response.APILocation) {
          vm.generalTerms = response.GeneralTerms;
          vm.generalTerms2 = response.GeneralTerms;
        }
      })
        .catch(function (error) {
        NotificationService.showMessage(error, 'error');
      });
    };

    vm.init = function () {
      vm.regexPatterns = ValidationService.getRegexPatternsForValidation();
      vm.getCurrentAccountPage();
      if(!$state.params.myParam) {
        getDefaultData();
      }
      if (vm.countryCode === 'hr') {
        loadeSavingBookRequests(true);
      }
    };

    vm.pushGtmLinkEvent = function (link) {
        GoogleTagManagerHelperService.pushEvent('NewEbook', 'ExternalLink', link);
    };

    vm.init();
  }
}());

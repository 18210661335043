<div class="row p-10">
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 domp-from-section">
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="form-group">
        <label class="domp-form-label-review" translate="domesticPayment.fromAccountSelectLabel"></label>
        <label class="domp-form-label-resume">
          {{vm.fromAccountSelect.accountDescription}}
        </label>
        <label class="domp-form-label-accnumber">{{vm.paymentResponse.fromAccountNumber}}</label>
        <label class="domp-form-label-balance" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'">
          {{vm.fromAccountSelect.availableBalance |number:2}}
          {{vm.fromAccountSelect.currencySymbol}}
        </label>
      </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12 p-l-0">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-l-0">
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
            <label class="domp-form-label-review" translate="domesticPayment.amountInputLabel"></label>
            <label class="domp-form-label-resume">
              {{vm.paymentResponse.amount | currency: ""}}
              {{vm.paymentResponse.currencySymbol}}
            </label>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7">
            <label class="domp-form-label-review" translate="domesticPayment.bankCharges"></label>
            <a  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"
                ng-href="{{vm.bankCharges}}" target="_blank" translate="domesticPayment.checkBankCharges"
                class="domp-form-label-resume">Check the bank charges</a>
            <label class="domp-form-label-resume" ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'">
              {{vm.paymentResponse.paymentChargesAmount | currency: ""}}
              {{vm.paymentResponse.currencySymbol}}
            </label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-7 col-md-7 col-sm-7 col-xs-5">
          <div class="m-t-25 m-b-0">
            <label class="domp-form-label-review" translate="domesticPayment.dateInputLabel"></label>
            <label class="domp-form-label-resume">{{vm.paymentResponse.dueDate |date:'shortDate' : '+0200'}}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 receiver-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5">
        <label class="domp-form-label-review" translate="domesticPayment.toAccountInputLabel"></label>
        <label class="domp-form-label-resume">{{vm.paymentResponse.toAccountNumber}}</label>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="form-group">
          <label class="domp-form-label-review" translate="domesticPayment.toPayee">To payee</label>
          <label class="domp-form-label-resume">{{vm.paymentResponse.domesticPayment.receiverName}}</label>

        </div>
      </div>
    </div>
    <div class="row">
      <!--<div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 p-l-0"></div>-->
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 p-l-0"></div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-15 m-b-0">
          <label class="domp-form-label-review" translate="domesticPayment.receiversAddressInputLabel"></label>
          <label class="domp-form-label-resume">{{vm.paymentResponse.domesticPayment.receiverAddress1}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 p-l-0">
        <div class="m-t-25 m-b-0">
          <label class="domp-form-label-review" translate="domesticPayment.receiversPostalCodeAndCityInputLabel"></label>
          <label class="domp-form-label-resume">{{vm.paymentResponse.domesticPayment.receiverCity}}</label>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 purpose-section">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-5 no-padding-on-phone p-l-0">
        <div class="col-lg-8 col-md-8 col-sm-8">
          <div class="form-group">
            <label class="domp-form-label-review" translate="domesticPayment.purposeOfPaymentInputLabel"></label>
            <label class="domp-form-label-resume">{{vm.paymentResponse.purpose}}</label>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-7 no-padding-on-phone p-l-0">
        <div class="col-lg-6 col-md-6 col-sm-6 p-l-0">
          <label class="domp-form-label-review domp-reference-number-label"
                 translate="domesticPayment.referenceNumberLabel">Reference number</label>
          <label ng-if="vm.countryCode != 'sl'" class="domp-form-label-resume">{{vm.paymentResponse.domesticPayment.referenceTo}}</label>
          <label class="domp-form-label-resume"
                 ng-if="vm.countryCode != 'sl' && vm.paymentResponse.domesticPayment.referenceTo ==' ' || vm.paymentResponse.domesticPayment.referenceTo == undefined">
            -
          </label>
          <label ng-if="vm.countryCode == 'sl'" class="domp-form-label-resume">{{vm.invoice.paymentReferenceNumber}}</label>
        </div>
      </div>
    </div>
    <div class="row" ng-if="vm.isUrgentPaymentCheckbox == true">
      <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <i class="zmdi zmdi-check urgent-label"></i>
        <label class="urgent-label" translate="domesticPayment.urgentPayment"></label>
      </div>
    </div>

    <section class="row p-20" ng-if="vm.isInstantPaymentCheckBox === true && vm.countryCode !== 'hr'">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <i class="zmdi zmdi-check urgent-label"></i>
        <label class="urgent-label" translate="domesticPayment.instantPayment"></label>
      </div>
    </section>

  </div>

</div>
<div class="row domp-footer-wrapper p-10">
  <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 p-0">
    <button ng-if="vm.countryCode != 'sl'" class="col-lg-2 col-md-2 col-sm-2 col-xs-3 m-r-10 btn btn-primary-warning"
            translate="eInvoicesModal.delete"
            ng-click="vm.deletePayment();">
    </button>

    <button  ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation != 'sl'"  class="col-lg-2 col-md-2 col-sm-2 col-xs-3 btn btn-primary"
             translate="eInvoicesModal.edit"
             ng-click="vm.editPayment();">
    </button>
    <button ng-if="APP_GLOBAL_SETTINGS.defaultData.APILocation == 'sl'" class="col-lg-2 col-md-2 col-sm-2 col-xs-3 btn btn-primary"
            translate="domesticPayment.back"
            ng-click="vm.editPayment();">
    </button>

    <button ng-if="vm.countryCode != 'sl'" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default  pull-right"
            translate="eInvoicesModal.sign"
            data-ui-sref="payments.sign({paymentId: vm.paymentResponse.id, type: 'einvoice'})">
    </button>

    <button ng-if="vm.countryCode == 'sl'" class="col-lg-4 col-md-4 col-sm-4 col-xs-4 btn btn-default  pull-right"
            translate="domesticPayment.confirmBtn"
            ng-disabled="vm.confirmationInProgress"
            ng-click="vm.signPayment()">
    </button>

  </div>
</div>


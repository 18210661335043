(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name sepa.controller:SepaRefusalCroCtrl
   *
   * @description
   *
   */
  angular
    .module('sepa')
    .controller('SepaAcceptedRefusalCroCtrl', SepaAcceptedRefusalCroCtrl);

  function SepaAcceptedRefusalCroCtrl($q, $stateParams, $scope, SepaService, HelperService, $state, AlertService, NotificationService) {
    var vm = this;
    vm.pageLoaded = false;
    vm.step = "start";

    vm.model = {
      fromAccountSelect: {},
      executionDate: new Date(),
      amount: "",
      identifierApproval: "", // Identifikator suglasnosti
      receiverId: "", // Identifikator primatelja
      receiverName: "",  // Naziv i sjedište primatelja plaćanja
      receiverAccount: "", // Račun primatelja plaćanja - IBAN
      receiverNumber: "" // Detalji plaćanja (poziv na broj primatelja)
    };

    init();
    function init() {
      loadData();
    }

    function loadData() {
      var promises = [];
      if (!$stateParams.transactionId) {
        return;
      }
      promises.push(SepaService.getById($stateParams.transactionId));
      $q.all(promises)
        .then(function (responses) {
          var transaction = responses[0];
          if (transaction) {
            vm.model.accountId = transaction.accountId;
            vm.model.amount = transaction.amount;
            vm.model.executionDate = new Date(transaction.valuteDate);
            vm.model.receiverName = transaction.receiverName;
            vm.model.receiverAccount = transaction.receiverAccount;
            vm.model.receiverNumber = transaction.receiverReference;
            vm.model.transactionId = transaction.transactionId;
            // Račun platitelja / Payer account
            vm.model.senderAccount = transaction.senderAccount; // Samo prikaz

            vm.pageLoaded = true;
          }
        }, function (error) {
          NotificationService.showMessage(error, 'error');
          $state.go("sepa.transactions");
        });
    }

    function prepareDataForInsert(input) {
      var data = {};
      data.accountId = input.fromAccountSelect.accountId;
      data.inputValueDate = input.executionDate;
      data.amount = input.amount;
      data.receiverName = input.receiverName;
      data.receiverAccount = input.receiverAccount;
      data.receiverReference = input.receiverNumber;
      data.transactionId = input.transactionId;
      return data;
    }

    vm.submit = function (form) {
      vm.submitButtonDisabled = true;
      SepaService.addSepaDDComplaint(prepareDataForInsert(vm.model))
        .then(function (response) {
          vm.step = "sign";
          vm.responseModel = response;
          HelperService.initializeMTokenSignature.call(vm, $scope, vm.confirmSign);
          HelperService.loadChallengeResponse(response, vm);
          vm.submitButtonDisabled = false;
        }, function (error) {
          vm.submitButtonDisabled = false;
          NotificationService.showMessage(error, 'error');
        })
    };

    vm.confirmSign = function () {
      if (vm.confirmSignBtnDisabled) return;
      vm.confirmSignBtnDisabled = true;
      SepaService.signSepaComplaint({
        "id": vm.responseModel.id,
        "signData": vm.challengeResponse,
        'additionalSignData': vm.challengeResponse2
      }).then(function (response) {
          vm.signInProgress = false;

          vm.confirmSignBtnDisabled = false;
          AlertService.infoAlert({
            text: 'sepa.signSuccessfull'
          }).then(function () {
            $state.go("sepa.transactions");
          });
        }, function (error) {
          vm.signInProgress = false;
          NotificationService.showMessage(error, 'error');
          vm.confirmSignBtnDisabled = false;
        })
    }
  }
}());

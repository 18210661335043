(function () {
    'use strict';

    angular
        .module('localization')
        .config(config);

    function config($translateProvider) {

        var data = {
            // Don't know yet
          session: {
            expired: {
              title: 'Internet banking Addiko bank',
              subtitle: 'Addiko EBank',
              message: 'The session was terminated due to new login into Addiko EBank. If it wasn\'t you, please let us know immediately.',
              contact1: 'You can access Addiko EBank and ',
              contact2: 'Addiko Business EBank web pages ',
              contact3: 'as an authorised user',
              contact1SLO: 'You can access Addiko EBank only as',
              contact2SLO: 'an authorised user',
              wrongBrowser: 'The use of Addiko EBank and Addiko Business EBank is supported in web browsers, which are listed at the following link:'
            },
            inactiveUser: 'You have been logged off from the system due to inactivity. Please login again.'
          },
          common: {
            callCenter: '(call center)',
            "fileUploadLimit": "File {{fileName}} is larger than maximum allowed size {{maxSize}}",
            callCenterWorkingHours1: '(call center)',
            callCenterWorkingHours2: '',
            loadMore: 'Load more',
            loadLess: 'Load less'
          },
          retail: {
            title: 'Retail'
          },
          corporate: {
            title: 'Corporate'
          },
            core: {
 				'ok': 'U redu',
	            'addDocument': 'Add document',
	            "filter": {
                  maxDateRange: "Maximal date range is one year."
                },
                "inputFields": {
                    IBAN: "9999 9999 9999 9999 9"
                },
                "moneyFormat": {
                    mask: "#,###,##0.00",
                    placeholder: "eg: 000,000.00"
                },
                "period": {
                    today: "Today",
                    yesterday: "Yesterday",
                    thisWeek: "Week",
                    thisMonth: "Month",
                    thisYear: "Year",
                    all: "All",
                    allMessages: "All messages",
                    clear: "Clear",
                    last6Months: "Last 6 months"
                },
                "requiredField": "Field is required.",
                "ebookOrder": "eSaving book order",
                "paymentOrder": "Payment order",
                "investOrder": "Investment order",
                "successDelete": "was successfully deleted.",
                "investSuccessDelete": "was successfully deleted.",
                "termDepositPayoutType": {
                    "2": "Interest rate addition upon maturity",
                    "2e": "(interest and capital together)",
                    "16": "Monthly interest payout on certain date",
                    "16e": "(explanation: if term deposit is agreed on 15, interst payout is performed every 15th in month)",
                    "17": "Quarterly interest payout on certain date",
                    "17e": "(explanation: if term deposit is agreed on 15, interst payout is performed every 15th in month)",
                    "18": "Biannual interest payout on certain date",
                    "18e": "(explanation: if term deposit is agreed on 15, interst payout is performed every 15th in month)",
                    "19": "Annual interest payout on certain date",
                    "19e": "(explanation: if term deposit is agreed on 15, interst payout is performed every 15th in month)",
                    "3": "Payout of interst rate upon maturity",
                    "3e": "(at automatic prolongation only)"

                },
                "termDepositStatus": {
                  "entered":"Entered",
                  "realised": "Realised",
                  "unrealised": "Unrealised",
                  "cancelled": "Cancelled",
                  "receivednotexecuted":"In processing"
                },
                "confirmation": "Reclamation on transaction was successfully received in database.",
                "confirmationSlo": " Complaint was successfully submitted.",
                "error": "Error",
              sweetAlertTranslation: {
                yes: "Yes",
                no: "No",
                areYouSure: "Are you sure?"
              },
              logoutModalTranslation: {
                logoutBtn: "Yes",
                logoutMsg: "Do you really want to log out?",
                "cancel":"No"
              },
              "sameAccounts": "Error: Same accounts!",
              paymentSignedStatus:{
                  "inprocessing":"InProcessing"
              },
              "today":"Today",
              "passwordChangedMsg":"Password successfully changed!",
              "noAccounts": "No available accounts",
              "notImplemented":"Not implemented yet!",
              "additionalDataChangedMsg": "Successfully updated!",
              datePicker: {
                "doneBtn":"Done",
                "clearBtn":"Clear"
              }
            },
            activation: {
              notification: 'Uspe�no ste aktivirali mBank aplikaciju na uredaju {{value}}. Va�a prijava u mBank je onemogucena, pa vas molimo da uradite aktivaciju vase mBanke tako sto cete potvrditi klikom na dugme U redu.  U slucaju eventualnih problema nazovite Addiko Kontakt centar na broj telefona +381 11 222 6 000.',
              activationSuccess: 'Uspe�no ste aktivirali mBank aplikaciju na uredaju',
              activationCancellationSuccess: 'Uspe�no ste otkazali mBank aplikaciju'
            }
        };

        $translateProvider
            .translations('en', data);

    }
}());

(function () {
  'use strict';

  angular
    .module('payments')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('payments', {
        url: '/payments',
        abstract: true
          //templateUrl: 'payments/payments.tpl.html',
          //controller: 'PaymentsCtrl',
          //controllerAs: 'payments'
      })
      //.state('payments.exchangeRates', {
      //  url: '/payments-exchangeRates',
      //  views: {
      //    '@': {
      //      templateUrl: 'payments/exchange-rates.tpl.html',
      //      controller: 'ExchangeRatesCtrl',
      //      controllerAs: 'exchangeRates'
      //    }
      //  }
      //})

      //state relevant only for MNE environment
      .state('payments.exchangeRates', {
        url: '/exchangeRates',
        views: {
          '@': {
            templateUrl: 'payments/currency-calculator-modal.tpl.html',
            controller: 'CurrencyCalculatorModalCtrl',
            controllerAs: 'currencyCalculator'
          }
        },
        resolve: {
          validateUrl: function ($localStorage, $location, ConfigEndpointService) {
              ConfigEndpointService.getDefaultData()
                .then(function (response) {
                  if (response.APILocation !== 'mne') {
                    //redirect to dashboard
                    $location.url('/dashboard');
                  }
                })
                .catch(function (error) {
                  //redirect to dashboard page
                  $location.url('/dashboard');
                })
          }
        }
      })
      .state('payments.currencyConversion', {
        url: '/currency-conversion',
        views: {
          '@': {
            templateUrl: 'payments/currency-conversion.tpl.html',
            controller: 'CurrencyConversionCtrl',
            controllerAs: 'currencyConversion'
          }
        }
      })
      // load currency conversion from payment
      .state('payments.currencyConversion.fromPayment', {
        url: '/load/:paymentId',
        params: {
          editable: null
        },
        views: {
          '@': {
            templateUrl: 'payments/currency-conversion.tpl.html',
            controller: 'CurrencyConversionCtrl',
            controllerAs: 'currencyConversion'
          }
        }
      })
      // load currency conversion from template
      .state('payments.currencyConversion.fromTemplate', {
        url: '/template/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/currency-conversion.tpl.html',
            controller: 'CurrencyConversionCtrl',
            controllerAs: 'currencyConversion'
          }
        }
      })
      .state('payments.paymentsOverview', {
        url: '/payments-overview',
        views: {
          '@': {
            templateUrl: 'payments/payments-overview.tpl.html',
            controller: 'PaymentsOverviewCtrl',
            controllerAs: 'paymentsOverview'
          }
        },
        params: {
          selected: null,
          myParam: null
        }
      })
      .state('payments.reclamation', {
        resolve: {
          payment: function($stateParams, PaymentsService) {
            return PaymentsService.getById($stateParams.paymentId)
          },
          validate: function ($q, payment) {
            if(!payment) $q.reject();
          }
        },
        url: '/reclamation/:paymentId',
        views: {
          '@': {
            templateUrl: 'payments/payment-reclamation.tpl.html',
            controller: 'PaymentReclamationCtrl',
            controllerAs: 'vm'
          }
        }
      })
      .state('payments.paymentsAndTransferOverview', {
        url: '/payments-and-transfer-overview',
        views: {
          '@': {
            templateUrl: 'payments/payments-and-transfer-overview.tpl.html',
            controller: 'PaymentsAndTransferOverviewCtrl',
            controllerAs: 'patoCtrl'
          }
        }
      })
      //basic domestic payment
      .state('payments.domesticPayment', {
        url: '/domestic-payment',
        params: {
          myParam: null,
          transactionDetails: {}
        },
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'
            // resolve: {
            //   defaultLocationData: function ($rootScope,ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
      //load domestic payment from payment id
      .state('payments.domesticPayment.fromPayment', {
        url: '/load/:paymentId',
        params: {
          myParam: null,
          editable: null
        },
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'
            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
      //load domestic payment from template
      .state('payments.domesticPayment.fromTemplate', {
        url: '/template/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'
            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
      //load domestic payment from template - Pay - SLO
      .state('payments.domesticPayment.fromTemplatesToPay', {
        url: '/template-pay/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'
            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
    //load domestic payment from current account

      .state('payments.domesticPayment.fromAccount', {
        url: '/from-accounts/:accountId',
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'

            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
      //basic instant payment

      .state('payments.domesticPayment.fromInternational', {
        url: '/from-international',
        params: {
          transactionDetails: {}
        },
        views: {
          '@': {
            templateUrl: 'payments/domestic-payment.tpl.html',
            controller: 'DomesticPaymentCtrl',
            controllerAs: 'domesticPayment'
            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })

      .state('payments.instantPayment', {
        url: '/instant-payment',
        params: {
          myParam: null,
          transactionDetails: {}
        },
        views: {
          '@': {
            templateUrl: 'payments/instant-payment.tpl.html',
            controller: 'InstantPaymentCtrl',
            controllerAs: 'instantPayment'
          }
        }
      })
      .state('payments.instantPayment.fromPayment', {
        url: '/load/:paymentId',
        params: {
          myParam: null
        },
        views: {
          '@': {
            templateUrl: 'payments/instant-payment.tpl.html',
            controller: 'InstantPaymentCtrl',
            controllerAs: 'instantPayment'
          }
        }
      })
      .state('payments.instantPayment.edit', {
        url: '/edit/:cancelationId',
        params: {
          paymentId:null,
          accountId: null,
          cancelationId:null,
          editable: false,
          myParam: null
        },
        views: {
          '@': {
            templateUrl: 'payments/instant-payment.tpl.html',
            controller: 'InstantPaymentCtrl',
            controllerAs: 'instantPayment'
          }
        }
      })
      .state('payments.internationalPayment', {
        url: '/international-payment',
        params: {
          editable: null
        },
        views: {
          '@': {
            templateUrl: 'payments/international-payment.tpl.html',
//            controller: 'InternationalPaymentCtrl',
//            controllerAs: 'internationalPayment'
          }
        }
      })
      .state('payments.internationalPayment.fromTemplate', {
        url: '/template/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/international-payment.tpl.html',
//            controller: 'InternationalPaymentCtrl',
//            controllerAs: 'internationalPayment'
          }
        }
      })
      //load international payment from template - Pay - SLO
      .state('payments.internationalPayment.fromTemplatesToPay', {
        url: '/template-pay/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/international-payment.tpl.html',
          }
        }
      })
      .state('payments.internationalPayment.fromPayment', {
        url: '/load/:paymentId',
        params: {
          myParam: null
        },
        views: {
          '@': {
            templateUrl: 'payments/international-payment.tpl.html',
//            controller: 'InternationalPaymentCtrl',
//            controllerAs: 'internationalPayment'
          }
        }
      })
      .state('payments.internationalPayment.documentationPayment', {
        url: '/documentation/:paymentId',
        views: {
          '@': {
            templateUrl: 'payments/documentation-payment-mne.tpl.html',
            controller: 'DocumentationPaymentMneCtrl',
            controllerAs: 'documentationPaymentMne'
          }
        }
      })
      .state('payments.internationalPayment.fromDomestic', {
        url: '/from-domestic',
        params: {
          transactionDetails: {}
        },
        views: {
          '@': {
            templateUrl: 'payments/international-payment.tpl.html',
            // controller: 'DomesticPaymentCtrl',
            // controllerAs: 'domesticPayment',
            // resolve: {
            //   defaultLocationData: function (ConfigEndpointService) {
            //     return ConfigEndpointService.getDefaultData();
            //   }
            // }
          }
        }
      })
      .state('payments.internalTransfer', {
        url: '/internal-transfer',
        views: {
          '@': {
            templateUrl: 'payments/internal-transfer.tpl.html',
            controller: 'InternalTransferCtrl',
            controllerAs: 'internalTransfer'
          }
        }
      })
      .state('payments.internalTransfer.fromAccounts', { //loan internal transfer from Card account
        url: '/:accountId',
        params: {
          accountId: null
        },
        views: {
          '@': {
            templateUrl: 'payments/internal-transfer.tpl.html',
            controller: 'InternalTransferCtrl',
            controllerAs: 'internalTransfer'
          }
        }
      })
      // load internal transfer from payment
      .state('payments.internalTransfer.fromPayment', {
        url: '/load/:paymentId',
        params: {
          editable: null,
          myParam: null
        },
        views: {
          '@': {
            templateUrl: 'payments/internal-transfer.tpl.html',
            controller: 'InternalTransferCtrl',
            controllerAs: 'internalTransfer'
          }
        }
      })
      // load internal transfer from template
      .state('payments.internalTransfer.fromTemplate', {
        url: '/template/:templateId',
        views: {
          '@': {
            templateUrl: 'payments/internal-transfer.tpl.html',
            controller: 'InternalTransferCtrl',
            controllerAs: 'internalTransfer'
          }
        }
      })
      // loan payment internal transfer
      .state('payments.internalTransfer.loan', {
        url: '/loan/:loanId',
        views: {
          '@': {
            templateUrl: 'payments/internal-transfer.tpl.html',
            controller: 'InternalTransferCtrl',
            controllerAs: 'internalTransfer'
          }
        }
      })
      .state('payments.loanPayment', {
        url: '/loan-payment/:accountId',
        views: {
          '@': {
            templateUrl: 'payments/loan-payment.tpl.html',
            controller: 'LoanPaymentCtrl',
            controllerAs: 'loanPayment'
          }
        }
      })
      .state('payments.ebookPay', {
        url: '/e-book-pay',
        abstract: true,
        views: {
          '@': {
            // templateUrl: 'payments/e-book-pay.tpl.html'
            /* controller: 'EBookPayCtrl',
             controllerAs: 'eBookPay'*/
          }
        }
      })
      .state('payments.ebookPay.payOut', {
        url: '-out/:accountId',
        params: {
          myParam: null,
          paymentId: null,
          editable: false
        },
        views: {
          '@': {
            templateUrl: 'payments/e-book-pay.tpl.html',
            controller: 'EBookPayCtrl',
            controllerAs: 'eBookPay'
          }
        }
      })
      .state('payments.ebookPay.payIn', {
        url: '-in/:accountId',
        params: {
          myParam: null,
          paymentId: null,
          editable: false
        },
        views: {
          '@': {
            templateUrl: 'payments/e-book-pay.tpl.html',
            controller: 'EBookPayCtrl',
            controllerAs: 'eBookPay'
          }
        }
      })
      .state('payments.ebookPay.fromPayment', {
        url: '/:paymentId',
        params: {
          myParam: null,
          accountId: null,
          editable: false
        },
        views: {
          '@': {
            templateUrl: 'payments/e-book-pay.tpl.html',
            controller: 'EBookPayCtrl',
            controllerAs: 'eBookPay'
          }
        }
      })
      .state('payments.savingsPayIn', {
        url: '/foreign-currency-transfer/:accountId',
        params: {
          paymentId: null,
          editable: false
        },
        views: {
          '@': {
            templateUrl: 'payments/savings-pay-in.tpl.html',
            controller: 'SavingsPayInCtrl',
            controllerAs: 'savingsPayIn'
          }
        }
      })
      .state('payments.savingsPayIn.fromPayment', {
      url: '/foreign-currency-transfer/:paymentId',
      params: {
        accountId: null,
        editable: false
      },
      views: {
        '@': {
          templateUrl: 'payments/savings-pay-in.tpl.html',
          controller: 'SavingsPayInCtrl',
          controllerAs: 'savingsPayIn'
        }
      }
    })
      .state('payments.sign', {
        url: '/:paymentId/:type/sign',
        params: {
          payment: null,
          paymentId: null,
          type: 'other'
        },
        views: {
          '@': {
            templateUrl: 'payments/payment-sign.tpl.html',
            controller: 'PaymentsSignCtrl',
            controllerAs: 'paymentsSign'
          }
        }
      })
      .state('payments.sign-instant', {
        url: '/:paymentId/:cancelationId/signInstant',
        params: {
          payment: null,
          cancelation: null,
          paymentId: null,
          cancelationId: null
        },
        views: {
          '@': {
            templateUrl: 'payments/instant-payment-sign.tpl.html',
            controller: 'InstantPaymentSignCtrl',
            controllerAs: 'instantPaymentSign'
          }
        }
      })
      .state('payments.groupSign', {
        url: '/:groupId/group-sign',
        views: {
          '@': {
            templateUrl: 'payments/payments-group-sign.tpl.html',
            controller: 'PaymentsSignCtrl',
            controllerAs: 'paymentsSign'
          }
        }
      })
      .state('payments.finalStep', {
        url: '/:paymentId/:type/final-step',
        params: {
          paymentObject: null,
          type: 'other',
          isFromTemplate: null,
          isCancelation: null
        },
        views: {
          '@': {
            templateUrl: 'payments/payments-final-step.tpl.html',
            controller: 'PaymentsFinalStepCtrl',
            controllerAs: 'paymentsFinal'
          }
        }
      })
      .state('payments.cancellationsFinalStep', {
        url: '/:paymentId/:type/cancellations-final-step',
        params: {
          paymentObject: null,
          type: 'other',
          isFromTemplate: null,
          isCancelation: null
        },
        views: {
          '@': {
            templateUrl: 'payments/payments-cancellations-final-step.tpl.html',
            controller: 'PaymentsCancellationsFinalStepCtrl',
            controllerAs: 'paymentsFinal'
          }
        }
      })
      .state('payments.paymentsReturn', {
        url: '/payments-return',
        views: {
          '@': {
            templateUrl: 'payments/payments-return.tpl.html',
            controller: 'PaymentsReturnCtrl',
            controllerAs: 'paymentsReturn'
          }
        }
      });
  }
}());

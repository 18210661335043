(function () {
    'use strict';
  
    /**
     * @ngdoc object
     * @name complaints.controller:ComplaintsCtrl
     *
     * @description
     *
     */
    angular
      .module('complaints')
      .controller('ComplaintsCtrl', ComplaintsCtrl);
  
    function ComplaintsCtrl($q, $rootScope, ConfigService, HelperService, NotificationService, ComplaintsService, $state, $scope, AlertService) {
      var vm = this;
      vm.countryCode = $rootScope.APP_GLOBAL_SETTINGS.defaultData.APILocation;
      vm.complaintsList = [];
      vm.complaintCategories = [];
      vm.complaintsGrouped =  null;
      vm.hasComplaints = false;
      vm.dataLoaded = false;
      vm.hasAny = false;
      vm.hasMore = false;
      vm.queryParams = {page: 1, pageSize: 10, orderBy: 'creationDate desc'};
      var COMPLAINTS_MAX_UPLOAD_SIZE = ConfigService.complaintsFileSizeMax;
      $scope.categoryId = '';
      $scope.status = '';
      $scope.$watch('categoryId', function(newVal, oldVal) {
        if (newVal) {
          vm.queryParams.categoryId = newVal;
        } else {
         vm.queryParams.categoryId = null;
        }
        vm.loadComplaints(true);
      })
      $rootScope.$on('updateComplaint', onUpdateComplaint);
      $rootScope.$on('downloadComplaintAttachment', onDownloadAttachment);
      $rootScope.$on('onFiles', onFilesAdded);

      vm.init = function () {
        $scope.$on('$destroy', function(event, something ){
          $rootScope.$off('onFiles', onFilesAdded);
          $rootScope.$off('updateComplaint', onUpdateComplaint);
          $rootScope.$off('downloadComplaintAttachment', onUpdateComplaint);
        });
        var promises = [ComplaintsService.getFormsConfiguration()];
        $q.all(promises)
          .then(function (responses) {
            vm.complaintCategories = [{categoryName: '', categoryId: ''}].concat(responses[0].complaintCategories);
          })
          .catch(function (error) {
            NotificationService.showMessage(error, 'error');
          });
      };

      vm.createNewComplaint = function() {
        $state.go('complaints-new')
      }

      vm.toggleStatus = function(status) {
        if (status == 'Initial') {
          vm.queryParams.statusList = null;
          vm.queryParams.status = 'InProcessing';
        } else if (status === 'Processed') {
          vm.queryParams.status = '';
          vm.queryParams.statusList = ['Processed', 'Cancelled', 'Rejected'];
        } else {
          vm.queryParams.statusList = null;
          vm.queryParams.status = '';
        }
        $scope.status = status;
        vm.loadComplaints(true);
      }

      vm.selectStatus = function(newVal) {
        vm.queryParams.status = newVal;
        vm.loadComplaints(true);
      }

      vm.loadComplaints = function(isReset) {
        if (isReset) {
          vm.queryParams.page = 0;
        }
        ComplaintsService.getPage(vm.queryParams).then(function(response) {
          if (!vm.dataLoaded) {
            vm.hasAny = !!response.result.length
            vm.dataLoaded = true;
          }
          vm.hasMore = response.hasMore;
          if (isReset) {
            vm.complaintsList = response.result;
          } else {
            vm.complaintsList = vm.complaintsList.concat(response.result);
          }
        }) 
      }

      vm.updateComplaint = function(complaint) {
        var status = complaint.status;
        if (status == 'Rejected' || status == 'Cancelled' || status == 'Processed') {
          complaint.forbidReply = true;
      }

      var promises = [
        ComplaintsService.getComplaintMessages(complaint.complaintId),
        ComplaintsService.getComplaintAttachments(complaint.complaintId)
      ];
      $q.all(promises).then(function(responses) {
          if (complaint.unreadMessages) {
            ComplaintsService.setMessagesAsRead(complaint.complaintId).then(function () {
              $rootScope.$broadcast('complaints.read', '');
            });
          }
          complaint.messages = responses[0] || [];
          complaint.attachments = responses[1] || [];
          AlertService.complaintConversationModal(complaint);
        })
      }
  
      vm.toggleComplaint = function(complaintId) {
        if (vm.selectedComplaint == complaintId) {
          vm.selectedComplaint = '';
        } else {
          vm.selectedComplaint = complaintId;
        }
      }

      vm.loadMore = function() {
        vm.queryParams.page += 1;
        vm.loadComplaints();
      }

      vm.init();

      function onUpdateComplaint(event, data) {
        ComplaintsService.addMessage(data).then(function(response) {
          $rootScope.$broadcast('complaintMessage', response);
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }
      
      function onFilesAdded(event, data) {
        var invalidFile = HelperService.validateFileMaxSize(data.files, COMPLAINTS_MAX_UPLOAD_SIZE);
        if (invalidFile) {
          var translation = $translate.instant('common.fileUploadLimit', { fileName: invalidFile.name, maxSize: Math.round(COMPLAINTS_MAX_UPLOAD_SIZE / (1024 * 1024)) }) + 'MB'
          AlertService.infoAlert({
            text: translation,
          }).then();
          return;
        }
        ComplaintsService.uploadAttachment(data.files, data.id).then(function() {
          AlertService.successAlert({message: 'complaints.uploadSuccess'});
        }).catch(function (error) {
          NotificationService.showMessage(error, 'error');
        });
      }


      function onDownloadAttachment(event, attachment) {
        ComplaintsService.downloadAttachment(attachment.attachmentId, attachment.fileName, attachment.contentType);
      }
    }
  }());
  